const constants = {
    BASE_SHIPPING_COST: 24.95,
    AK_HI_SHIPPING_COST: 40,

    CROSS_SELL_IMPRESSION_IN_CART_TYPE: 'In-Cart Specials',
    CROSS_SELL_IMPRESSION_ADDON_TYPE: 'Addons'
}

export default {
    install(app, options) {
        app.config.globalProperties.$constants = constants
    }
}