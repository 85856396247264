<template>
	<div>
		<h2 id="login-register-title" class="cart-title">Checkout</h2>

		<div id="checkout-login-form" class="row">
			<div v-if="ssoButtonData.data.globalSso" class="col-md-12 desktop-sign-in text-center p-b-25">
				<div class="sg-form">
					<span class="sign-in-with">Register or sign in with ...</span>
					<div class="p-t-25">
						<a v-if="ssoButtonData.data.googleAvailable" :href="'https://accounts.google.com/o/oauth2/auth?client_id='+ssoButtonData.data.googleClientId+'&scope=profile email&redirect_uri='+ssoButtonData.data.redirectUri+'/sso/google&state='+ssoButtonData.data.pageHistory+'&response_type=code&prompt=consent'" class="google sso-btn google-sso-btn">
							<img src="/img/icons/google-icon.png" class="pull-left"><span>Sign in with Google</span>
						</a>
						<a
							v-if="ssoButtonData.data.facebookAvailable"
							:href="'https://www.facebook.com/v9.0/dialog/oauth?client_id='+ssoButtonData.data.facebookClientId+'&redirect_uri='+ssoButtonData.data.redirectUri+'/sso/facebook&scope=email public_profile&response_type=code&state='+ssoButtonData.data.pageHistory" class="fb sso-btn fb-sso-btn"
						>
							<img src="/img/icons/facebook-icon.png" width="20px" height="20px" class="pull-left"><span>Sign in with Facebook</span>
						</a>
						<a v-if="ssoButtonData.data.appleAvailable" :href="'https://appleid.apple.com/auth/authorize?client_id='+ssoButtonData.data.appleClientId+'&scope=name email&redirect_uri='+ssoButtonData.data.redirectUri+'/sso/apple&state='+ssoButtonData.data.pageHistory+'&response_type=code&response_mode=form_post'" class="apple sso-btn apple-sso-btn">
							<img src="/img/icons/apple-icon.png" width="20px" height="20px" class="pull-left"><span>Sign in with Apple</span>
						</a>
					</div>
				</div>
			</div>

			<div v-if="ssoButtonData.data.globalSso" class="col-md-12 p-b-25 desktop-sign-in">
				<div class="sign-in-with-or">OR</div>
			</div>

			<div class="col-md-6 desktop-sign-in">
				<form id="desktop-guest-data" class="sg-form">
					<h3 class="sign-in-label">As A Guest</h3>
					<p v-if="is_perpetual_subscription" class="h-text-right">Fields marked with <span class="color-primary">*</span> are required.</p>

					<div class="form-group" :class="{ 'has-error': guestValidationErrors.email }">
						<label class="control-label" for="desktop_guest_username"><span v-if="is_perpetual_subscription" class="color-red-orange">*</span> Email Address</label>
						<input id="desktop_guest_username" v-model="guestData.email" type="text" autocomplete="none" class="form-control" name="username" @keyup.enter="continueAsGuest()" @change="ltkMode = 'desktop'">
						<div v-if="guestValidationErrors.email" style="display:inline-block" class="sg-input-advice">{{ guestValidationErrors.email }}</div>
					</div>

					<div v-if="is_perpetual_subscription" id="checkout-login-pw" class="form-group" :class="{ 'has-error': guestValidationErrors.password }">
						<PasswordField
							v-model="guestData.password"
							label="Create password"
							:show-password="pwdType === 'text'"
							:input-props="{
								autocapitalize: 'none',
								name: 'new-password',
								password_creation_field: '1',
							}"
							:placeholder-text="'A password is required for Auto Delivery'"
							:show-errors="guestValidationErrors.password !== false"
							@change="ltkMode = 'desktop'"
						/>
						<div class="m-t-10 clear h-text-left">
							<a href="javascript:;" class="show-pwd" @click="showHidePwd()">
								<i class="fa fa-eye" /> {{ showHideLabel }}
							</a>
						</div>
					</div>

					<div class="form-group p-l-100">
						<div v-if="registered_guest" class="guest-error">
							This email address is already assigned to a registered user. You can <a href="javascript:;" @click="continueAsGuest()">continue to check out as a Guest</a>, or use the form to the right to log in to your account.
						</div>
						<div v-if="registered_guest_auto_delivery" class="guest-error">
							This email address is already assigned to a registered user. Please use the form to the right to log in to your account or use a different email address.
						</div>
						<a href="javascript:;" tabindex="0" class="button button-primary guest_continue" @click="continueAsGuest()">
							<span v-if="!guestCheckoutProcessing">Continue as guest</span>
							<span v-if="guestCheckoutProcessing">
								<img src="/img/spinner.png" alt="loading" class="progressSpinner">
							</span>
						</a>
					</div>
				</form>
			</div>
			<div class="col-md-6 desktop-sign-in">
				<form id="desktop-register-form" class="sg-form">
					<h3 class="sign-in-label">Sign In</h3>
					<p class="h-text-right">Fields marked with <span class="color-primary">*</span> are required.</p>

					<div class="form-group" :class="{ 'has-error': loginValidationErrors.email || loginValidationErrors.message}">
						<label class="control-label" for="desktop_login_username">
							<span class="color-red-orange">*</span> Email Address
						</label>
						<input id="desktop_login_username" v-model="loginData.email" type="email" autocomplete="username" name="desktop_login_username" class="form-control" @keyup.enter="submitLogin()" @change="ltkMode = 'desktop'">
						<div v-if="loginValidationErrors.email" class="sg-input-advice">{{ loginValidationErrors.email }}</div>
					</div>

					<div id="checkout-login-pw" class="form-group" :class="{ 'has-error': loginValidationErrors.password || loginValidationErrors.message}">
						<label class="control-label" for="desktop_login_password">
							<span class="color-red-orange">*</span> Password
						</label>
						<input id="desktop_login_password" v-model="loginData.password" :type="pwdType" autocapitalize="none" autocomplete="password" name="desktop_login_password" class="form-control" @keyup.enter="submitLogin()" @change="ltkMode = 'desktop'">
						<div v-if="loginValidationErrors.password" class="sg-input-advice">{{ loginValidationErrors.password }}</div>
						<div v-if="loginValidationErrors.message" class="sg-input-advice">{{ loginValidationErrors.message }}</div>
					</div>

					<div class="m-t-10 h-text-left">
						<a href="javascript:;" tabindex="0" class="show-pwd" @click="showHidePwd()"><i class="fa fa-eye" /> {{ showHideLabel }}</a>
						<a href="/my-account/password-reset" class="forgot">Forgot?</a>
					</div>

					<div class="form-group md-m-t-30">
						<a id="my-account-login" href="javascript:;" type="submit" tabindex="0" class="button button-primary account-login" @click="submitLogin()">
							<span v-if="!loginProcessing">Log In with Email</span>
							<span v-if="loginProcessing"><img src="/img/spinner.png" class="progressSpinner" alt="loading"></span>
						</a>
					</div>
				</form>
			</div>
			<!-- END DESKTOP VIEW -->

			<!-- MOBILE VIEW -->
			<div class="mobile-login-buttons text-center">
				<div v-if="ssoButtonData.data.globalSso" class="col-xs-12 text-center p-b-25 p-l-0">
					<span class="sign-in-with">Register or sign in with...</span>
					<br>
					<a v-if="ssoButtonData.data.googleAvailable" :href="'https://accounts.google.com/o/oauth2/auth?client_id='+ssoButtonData.data.googleClientId+'&scope=profile email&redirect_uri='+ssoButtonData.data.redirectUri+'/sso/google&state='+ssoButtonData.data.pageHistory+'&response_type=code&prompt=consent'" class="google sso-btn google-sso-btn sso-btn-mobile">
						<img src="/img/icons/google-icon.png" class="pull-left"><span>Sign in with Google</span>
					</a>
					<a v-if="ssoButtonData.data.facebookAvailable" :href="'https://www.facebook.com/v9.0/dialog/oauth?client_id='+ssoButtonData.data.facebookClientId+'&redirect_uri='+ssoButtonData.data.redirectUri+'/sso/facebook&scope=email public_profile&response_type=code&state='+ssoButtonData.data.pageHistory" class="fb sso-btn fb-sso-btn sso-btn-mobile">
						<img src="/img/icons/facebook-icon.png" width="20px" height="20px" class="pull-left"><span>Sign in with Facebook</span>
					</a>
					<a v-if="ssoButtonData.data.appleAvailable" :href="'https://appleid.apple.com/auth/authorize?client_id='+ssoButtonData.data.appleClientId+'&scope=name email&redirect_uri='+ssoButtonData.data.redirectUri+'/sso/apple&state='+ssoButtonData.data.pageHistory+'&response_type=code&response_mode=form_post'" class="apple sso-btn apple-sso-btn sso-btn-mobile">
						<img src="/img/icons/apple-icon.png" width="20px" height="20px" class="pull-left"><span>Sign in with Apple</span>
					</a>
				</div>

				<div v-if="ssoButtonData.data.globalSso" class="col-xs-12 p-b-25 p-l-0">
					<div class="sign-in-with-or">OR</div>
				</div>

				<div class="mobile-slidedown-container">
					<div v-if="mobileShowGuest" class="container ng-show-toggle-slidedown" ng-animate="{show: 'slide-down', hide: 'slide-up'}">
						<div class="col-xs-12">
							<form id="mobile-guest-data" class="sg-form">
								<h3 class="sign-in-label">As A Guest</h3>
								<p v-if="is_perpetual_subscription" class="">Fields marked with <span class="color-primary">*</span> are required.</p>
								<div class="form-group" :class="{ 'has-error': guestValidationErrors.email }">
									<label class="control-label" for="mobile_guest_username">Email Address <span v-if="is_perpetual_subscription" class="color-red-orange">*</span></label>
									<input id="mobile_guest_username" v-model="guestData.email" type="email" autocomplete="username" name="mobile_guest_username" class="form-control" @keyup.enter="continueAsGuest()" @change="ltkMode = 'mobile'">
									<div v-if="guestValidationErrors.email" class="guest-error sg-input-advice">{{ guestValidationErrors.email }}</div>
								</div>

								<div v-if="is_perpetual_subscription" id="checkout-guest-pw" class="form-group" :class="{ 'has-error': guestValidationErrors.password }">
									<label class="control-label" for="mobile_guest_password">Password</label>
									<input id="mobile_guest_password" v-model="guestData.password" :type="pwdType" autocapitalize="none" autocomplete="new-password" name="new-password" class="form-control" password_creation_field="1" pm_parser_annotation="password_element" :placeholder="is_perpetual_subscription ? 'A password is required for Auto Delivery' : 'OPTIONAL - Create a password to register'" @keyup.enter="continueAsGuest()" @change="ltkMode = 'mobile'">
									<a href="javascript:;" class="" @click="showHidePwd()"><i class="fa fa-eye m-r-5" />{{ showHideLabel }}</a>
								</div>
								<div class="md-m-t-30">
									<div v-if="registered_guest" class="guest-error">
										This email address is already assigned to a registered user.  You can <a href="javascript:;" @click="continueAsGuest()">continue to check out as a Guest</a>, or use the form to the right to log in to your account.
									</div>
									<div v-if="registered_guest_auto_delivery" class="guest-error">
										This email address is already assigned to a registered user. Please use the form to the right to log in to your account or use a different email address.
									</div>
									<div v-if="guestValidationErrors.password" class="guest-error sg-input-advice">{{ guestValidationErrors.password }}</div>
									<div v-if="guestValidationErrors.message" class="guest-error sg-input-advice">{{ guestValidationErrors.message }}</div>

									<a href="javascript:;" class="button full" @click="continueAsGuest()">Continue as guest</a>
								</div>
							</form>
						</div>
					</div>

					<a v-if="!mobileShowGuest" href="javascript:;" ng-animate="{show: 'slide-down', hide: 'slide-up'}" class="button full" :class="{'login-btn-inactive': mobileShowLogin}" @click="mobileToggleGuest()">Continue as a Guest</a>

					<div v-if="mobileShowLogin" id="register-login-container" class="container m-t-10" ng-animate="{show: 'slide-down', hide: 'slide-up'}">
						<div class="col-xs-12">
							<form id="mobile-register-form" class="sg-form" @submit.prevent="submitLogin">
								<h3 class="sign-in-label">Sign In</h3>
								<p class="">Fields marked with <span class="color-primary">*</span> are required.</p>
								<div class="form-group" :class="{'has-error': loginValidationErrors.email || loginValidationErrors.message}">
									<label class="control-label" for="username">Email Address</label>
									<input id="mobile_login_username" v-model="loginData.email" type="email" autocomplete="password" name="username" class="form-control form-control" @change="ltkMode = 'mobile'">
									<div v-if="loginValidationErrors.email" class="sg-input-advice">{{ loginValidationErrors.email }}</div>
								</div>
								<div id="checkout-login-pw" class="form-group" :class="{'has-error': loginValidationErrors.password || loginValidationErrors.message}">
									<label class="control-label" for="password">Password</label>
									<input id="mobile_login_password" v-model="loginData.password" :type="pwdType" autocapitalize="none" autocomplete="password" name="password" class="form-control" @change="ltkMode = 'mobile'">
									<div v-if="loginValidationErrors.password" class="sg-input-advice">{{ loginValidationErrors.password }}</div>
									<div v-if="loginValidationErrors.message" class="sg-input-advice">{{ loginValidationErrors.message }}</div>
								</div>

								<div class="m-t-10 m-b-10 h-text-left">
									<a href="javascript:;" tabindex="0" class="show-pwd" @click="showHidePwd()"><i class="fa fa-eye" /> {{ showHideLabel }}</a>
									<a href="/my-account/password-reset" class="pull-right forgot">Forgot?</a>
								</div>

								<div class="md-m-t-30">
									<a id="my-account-login" href="javascript:;" type="submit" class="button full" @click="submitLogin()">Log In with Email</a>
								</div>
							</form>
						</div>
					</div>

					<a v-if="!mobileShowLogin" href="javascript:;" ng-animate="{show: 'slide-down', hide: 'slide-up'}" class="button full" :class="{'login-btn-inactive': mobileShowGuest}" @click="mobileToggleLogin()">Log In with Email</a>
				</div>
			</div>
			<!-- END MOBILE VIEW -->
		</div>
		<div class="row mcafee">
			<div class="col-lg-12">
				<div class="trustedsite-trustmark" data-type="202" data-width="90" data-height="37" />
			</div>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
const axios = require('axios')
import { useUserStore } from '../../../stores/UserStore'
import { useCartStore } from '../../../stores/CartStore'
import PasswordField from '../../input/password-field.vue'

export default {
	components: {
		PasswordField
	},
	mixins: [checkoutHelpers],
	props: [],
	data() {
		return {
			loginData: {
				email: '',
				password: ''
			},
			guestData: {
				email: '',
				password: ''
			},
			ssoButtonData: {
				data: {}
			},
			is_perpetual_subscription: false,
			loginValidationErrors: {
				email: false,
				password: false,
				message: ''
			},
			guestValidationErrors: {
				email: false,
				password: false,
				message: ''
			},
			pwdType: 'password',
			ltkMode: 'desktop',
			showHideLabel: 'Show password',
			mobileShowGuest: false,
			mobileShowLogin: false,
			loginProcessing: false,
			registered_guest: false,
			registered_guest_auto_delivery: false,
			guestCheckoutProcessing: false
		}
	},
	created(){

	},
	mounted() {
		this.showShortHeader()
		this.cartStore = useCartStore()
		this.is_perpetual_subscription = this.cartStore.isAutoDelivery

		this.userStore = useUserStore()
		this.guestData.email = this.userStore.guestEmail

		axios.get('/api/checkout/server-details')
			.then(response => {
				this.ssoButtonData = response
			})

			
	},
	methods: {
		clearLoginValidationErrors() {
			this.loginValidationErrors = {
				email: false,
				password: false,
				guestEmail: false,
				guestPassword: false,
				message: ''
			}

			this.guestValidationErrors = {
				email: false,
				password: false,
				message: ''
			}
		},
		mobileToggleGuest() {
			this.mobileShowGuest = !this.mobileShowGuest
			this.mobileShowLogin = false
		},
		mobileToggleLogin() {
			this.mobileShowGuest = false
			this.mobileShowLogin = !this.mobileShowLogin
		},
		continueAsGuest() {
			this.clearLoginValidationErrors()
			// both password and email are only required if auto-delivery
			if (this.is_perpetual_subscription) {
				if (!this.guestData.email.length) {
					this.guestValidationErrors.email = "Valid email address is required"
				}

				if(!this.guestValidationErrors.email && !this.emailValidate(this.guestData.email)) {
					this.guestValidationErrors.email = "Valid email address is required"
				}

				if (!this.validatePassword(this.guestData.password)) {
					this.guestValidationErrors.password = "Password is invalid"
				}

				// Any errors yet?
				if(this.guestValidationErrors.email || this.guestValidationErrors.password) {
					this.guestCheckoutProcessing = false
					return false
				}

				var hasUpperCase = false;
				var hasLowerCase = false;
				var hasNumber = false;

				var i = 0;
				var character = '';
				while (i < this.guestData.password.length) {
					character = this.guestData.password.charAt(i);
					if (!isNaN(character * 1)) {
						hasNumber = true;
					} else {
						if (character === character.toUpperCase()) {
							hasUpperCase = true;
						}
						if (character === character.toLowerCase()) {
							hasLowerCase = true;
						}
					}
					i++;
				}

				if (hasUpperCase === false) {
					this.guestValidationErrors.guestPassword = "Your password must have at least 1 uppercase character."
					this.guestCheckoutProcessing = false
					return false
				} else if (hasLowerCase === false) {
					this.guestValidationErrors.guestPassword = "Your password must have at least 1 lowercase character."
					this.guestCheckoutProcessing = false
					return false
				} else if (hasNumber === false) {
					this.guestValidationErrors.guestPassword = "Your password must have at least 1 number character."
					this.guestCheckoutProcessing = false
					return false
				}
			} else {
				// NOT perpetual subscription.
				// Email is not required
				// BUT...if they have typed something...make sure it is a valid email
				if (this.guestData.email.length && !this.emailValidate(this.guestData.email)) {
					this.guestValidationErrors.email = "Please enter a valid email address"
					this.guestCheckoutProcessing = false
					return false
				}
			}

			var backToCart = false

			this.guestCheckoutProcessing = true;
			axios.put('/api/checkout/checkout-final-check/', {})
				.then(response => {
					this.guestCheckoutProcessing = false;
					if(response && response.data && response.data.status && response.data.status == 'failed') {
						var alert_message = "Shipments in your order do not qualify for Add-On items. ";
						alert_message += "Please click the OK button below to return to your cart and ";
						alert_message += "either update them to standard items or remove them before proceeding. ";
						alert_message += "To qualify, a shipment must be valued at $59.95 or more before shipping and taxes.\n";

						for (var sku in response.data.invalid_skus) {
							var sku_obj = response.data.invalid_skus[sku];
							alert_message += "\n"+UILogic.htmlEntities(sku_obj)+" does not qualify";
						}

						alert(alert_message);
						backToCart = true;
					}
						
					this.guestContinue(backToCart);
				})
		},
		guestContinue(backToCart) {
			this.guestCheckoutProcessing = true
			if(backToCart == 'undefined') {
				backToCart = false;
			}

			// get the form token
			this.guestData.token = window.csrfToken;

			this.clearLoginValidationErrors()

			if(this.guestData.password.length && this.is_perpetual_subscription) {
				// attempt login, if success proceed, if not register
				// - this is only done for auto-delivery orders
				axios.post('/api/checkout/login/', this.guestData)
					.then(response => {
						if(response.data.status == 'success') {
							const { customer } = response.data

							this.$sentry.setUser({
								id: customer.cust_id,
								email: customer.email,
								username: `${customer.fname} ${customer.lname}`
							})

							this.fireRecordCheckoutDecisionEvent('loginStep', 'checkout-logged-in')
							if(backToCart == true) {
								window.location.href = '/checkout/cart' 
							} else {
								window.location.href = '/checkout/shipping'
							}
						} else if(response.data.status == 'email-exists') {
							this.registered_guest_auto_delivery = true;
						} else {
							this.submitRegistration();
						}
					})
					.finally(() => {
						this.guestCheckoutProcessing = false;
						this.hideLoadingOverlay()
					})

			} else {
				if(this.guestData.email && this.guestData.email.length > 0) {
					axios.post('/api/checkout/guest-validate-email/', this.guestData)
						.then(response => {
							this.guestCheckoutProcessing = false
							if (
								response.data.error &&
								response.data.status === 'error'
								&& this.registered_guest === false
							) {
								this.registered_guest = true;
							} else {
								// Continue as guest

								this.$sentry.setUser({
									email: this.guestData.email,
								})

								// Save email in UserStore
								this.userStore.guestEmail = this.guestData.email
								this.fireRecordCheckoutDecisionEvent('loginStep', 'checkout-as-guest')
								if(backToCart === true) {
									window.location.href = '/checkout/cart'
								} else {
									window.location.href = '/checkout/shipping'
								}
							}							
						})
				} else {
					this.userStore.guestEmail = ''
					this.fireRecordCheckoutDecisionEvent('loginStep', 'checkout-as-guest')
					if(backToCart === true) {
						window.location.href = '/checkout/cart'
					} else {
						window.location.href = '/checkout/shipping'
					}
				}
			}
		},
		submitLogin() {
			this.loginProcessing = true
			this.clearLoginValidationErrors()
			if(!this.loginData.email.length) {
				this.loginValidationErrors.email = "You must enter your login email."
			}
			if(!this.loginValidationErrors.email && !this.emailValidate(this.loginData.email)) {
				this.loginValidationErrors.email = "Email address is not valid"
			}
			if(!this.loginData.password.length) {
				this.loginValidationErrors.password = "Please provide a password."
			}

			if(this.loginValidationErrors.email || this.loginValidationErrors.password) {
				this.loginProcessing = false
				return false
			}

			// get the form token
			this.loginData.token = window.csrfToken;

			// LOGIN
			let _this = this
			this.showLoadingOverlay()
			axios.post('/api/checkout/login/', this.loginData)
				.then(response1 => {
					_this.fireRecordCheckoutDecisionEvent('loginStep', 'checkout-logged-in')

					if(response1.data.status == 'success') {
						axios.put('/api/checkout/checkout-final-check/', {})
							.then(function (response2) { 
								if(response2.data.status && response2.data.invalid_skus) {
									_this.invalidSkuAlert(response.data)
								} else {
									window.location.href = '/checkout/shipping'
								}
							})
					} else if(response1.data.status == 'error' && typeof(response1.data.errors) != 'undefined' && response1.data.errors.length) {
						_this.loginProcessing = false;
						_this.loginValidationErrors.message = response1.data.errors[0].message
					}
				})
				.catch(error => {
					_this.loginProcessing = false;
					if(error.data.status == 'error' && typeof(error.data.errors) != 'undefined' && error.data.errors.length) {
						_this.loginValidationErrors.message = errors.response.data.errors[0].message
					}
				})
				.finally(() => {
					_this.hideLoadingOverlay()
				})
		},
		submitRegistration() {
			this.guestData.guest_register = true
			this.showLoadingOverlay()
			axios.put('/api/checkout/register/', this.guestData)
				.then(response1 => {
					this.fireRecordCheckoutDecisionEvent('loginStep', 'checkout-new-registration')

					if(response1.data.status == 'success') {
						axios.put('/api/checkout/checkout-final-check/', {})
							.then(response2 => { 
								if(response2.data.status && response2.data.invalid_skus) {
									this.invalidSkuAlert(response.data)
								} else {
									window.location.href = '/checkout/shipping'
								}
							})
					} else if(response1.data.status == 'error' && typeof(response1.data.errors) != 'undefined' && response1.data.errors.length) {
						this.loginProcessing = false;
						this.loginValidationErrors.message = response1.data.errors[0].message
					}
				})
				.catch(error => {
					this.loginProcessing = false;
					if(error.data.status == 'error' && typeof(error.data.errors) != 'undefined' && error.data.errors.length) {
						this.loginValidationErrors.message = errors.response.data.errors[0].message
					}
				})
				.finally(() => {
					this.hideLoadingOverlay()
				})
		},
		invalidSkuAlert(data) {
			this.loginProcessing = false;
			let take_back_to_cart = false
			let alert_message = "Shipments in your order do not qualify for Add-On items. ";
			alert_message += "Please click the OK button below to return to your cart and ";
			alert_message += "either update them to standard items or remove them before proceeding. ";
			alert_message += "To qualify, a shipment must be valued at $59.95 or more before shipping and taxes.\n";

			for (let sku in data.invalid_skus) {
				let sku_obj = data.invalid_skus[sku];
				alert_message += "\n"+this.htmlEntities(sku_obj)+" does not qualify";
			}

			if(data.status == 'failed') {
				// if it failed, give them the message and stay where we are
				alert(alert_message);
				take_back_to_cart = true;
			}

			if(take_back_to_cart) {
				window.location.href = '/checkout/cart'
			} else {
				this.cartStore.updateCartDataFromServer()
				window.location.href = '/checkout/shipping'
			}
		},
		showHidePwd() {
			if(this.pwdType == 'password') {
				this.showHideLabel = 'Hide password'
				this.pwdType = 'text'
			} else {
				this.showHideLabel = 'Show password'
				this.pwdType = 'password'
			}
		}
	}
};
</script>

<style>
	.login-btn-inactive.button.full {
		background: #dbe1e5;
		border-color: #dbe1e5;
		width: 100%;
		display: inline-block;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
	}
	.login-btn-inactive:hover {
		background: #D8D7D7;
		border-color: #D8D7D7;
	}
</style>