<template>
	<div class="row">
		<div class="cart-security col-xs-12 col-md-6 col-md-offset-6">
			<ul class="cart-security__list">
				<li class="cart-security__item">
					<a
						style="text-decoration: none;"
						target="_blank"
						href="https://www.bbb.org/us/ks/kansas-city/profile/meat-retail/kansas-city-steak-company-llc-0674-99125322?utm_campaign=bbb_seal&utm_content=Kansas%20City%20Steak%20Company%20LLC&utm_medium=website&utm_source=seal_click_99125322"
					>
						<img
							alt="Kansas City Steak Company LLC BBB accredited business profile"
							src="https://m.bbb.org/terminuscontent/dist/img/dynamic-seal/ab-seal-horizontal-blue.svg?tx=w_192"
							style="width:12rem;"
						>
						<div style="display: block;">
							<span
								lang="en"
								style="color: #015A75; font-size: 0.875rem; font-weight: bold; font-family: Arial, Helvetica, sans-serif"
							>BBB RATING: A+</span>
						</div>
					</a>
					<div id="prePassback" />
					<div id="gpt-passback" />
				</li>

				<li class="cart-security__item">
					<a href="//www.securitymetrics.com/site_certificate?id=832594&tk=1c0c44c8502081c966e1a0f2ec1f18f6" target="_blank" rel="noopener noreferrer">
						<img src="https://www.securitymetrics.com/portal/app/ngsm/assets/img/GreyContent_Credit_Card_Safe_White_Rec.png" alt="SecurityMetrics card safe certification logo">
					</a>
				</li>

				<li class="cart-security__item cart-security__item--mcafee mcAfeeTag">
					<div
						class="trustedsite-trustmark"
						data-type="204"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TrustIcons',
	data() {
		return {}
	},
	mounted() {
		let securePubAds = document.createElement('script')
		securePubAds.setAttribute('src', 'https://securepubads.g.doubleclick.net/tag/js/gpt.js')
		document.getElementById('prePassback').appendChild(securePubAds)

		let gTagData = document.createElement('script')
		document.getElementById('gpt-passback').appendChild(gTagData).innerHTML = "window.googletag = window.googletag || {cmd: []};googletag.cmd.push(function() { googletag.defineSlot('/21848388897/IABBB-Dynamic-Seal', [1, 1], 'gpt-passback').addService(googletag.pubads()); googletag.enableServices(); googletag.display('gpt-passback'); })"
	},
}

</script>