<template>
	<div>
		<CheckoutSteps page="billing" :needs-additional-shipments-step="getPerpetualSubscription" />
		<div class="master-shipping-panel">
			<div class="row">
				<div class="col-md-12 p-lr-5">
					<h3 class="m-t-25 m-b-25 text-center-mobile billing-heading">Billing Details</h3>
				</div>
			</div>

			<div class="cart-div-mobile-fix payment-page">
				<div class="row">
					<div class="col-md-8">
						<div class="form-bg">
							<div v-if="!giftCardCoversTotal" class="form-bg" style="padding: 10px">
								<h4 class="m-b-0 inline-block">Payment Method</h4>
								<ul class="nav nav-tabs checkout-payment-tabs-list" role="tablist">
									<li :class="{'active': ccTabActive}">
										<a href="javascript:;" @click="paymentMethodTabChange('cc-tab')">
											<label for="payment-method-credit-card" class="" tabindex="1">
												<span class="payment-options-text">Credit Card</span>
												<i class="fa fa-cc-discover icon-lg" aria-hidden="true" />
												<i class="fa fa-cc-mastercard icon-lg" aria-hidden="true" />
												<i class="fa fa-cc-visa icon-lg" aria-hidden="true" />
												<i class="fa fa-cc-amex icon-lg" aria-hidden="true" />
											</label>
										</a>
									</li>
									<li v-if="!hasAutoDelivery" :class="{'active': payPalTabActive}">
										<a href="javascript:;" @click="paymentMethodTabChange('paypal-tab')">
											<label class="" style="font-size: 18px;">
												Paypal <i class="fa fa-paypal" aria-hidden="true" style="font-size: 16px; padding-left:10px" />
											</label>
										</a>
									</li>
								</ul>
		
								<!-- Tab panes -->
								<div class="tab-content">
									<div id="cc-tab" class="tab-pane fade" :class="{'active in': ccTabActive}">
										<div class="p-t-25 tab-pane-bordered-box sg-form">
											<div v-if="savedPaymentMethods">
												<div class="flexbox">
													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 p-t-20" :class="{'has-error': validationErrors.selected_payment}">
														<div class="col-md-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c" for="select-card"><span class="required-asterisk color-primary">*</span>SELECT CARD</label>
														</div>
														<div class="col-md-7 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
															<div class="arrow">
																<select
																	id="select-card"
																	v-model="selectedPaymentID"
																	class="form-control"
																	autocomplete="false"
																	tabindex="1"
																	@change="chooseSavedPaymentSource()"
																>
																	<option value="--">Select a Card</option>
																	<option v-for="(data, key) in savedPaymentMethods" :key="key" :value="key">{{ data.card_info.acct_type + ' (*' + data.card_info.acct_last4 + ') - ' + data.billing_address.fname + ' ' + data.billing_address.lname +' exp. '+ data.card_info.expr }}</option>
																	<option value="new">Use a New Card</option>
																</select>
															</div>
														</div>
														<div v-if="validationErrors.selected_payment" class="col-lg-7 col-md-7 col-lg-offset-5  col-md-offset-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<span class="color-primary inline-block">{{ validationErrors.selected_payment }}</span>
														</div>
													</div>
												</div>
											</div>
											<div v-if="!savedPaymentMethods || savedPaymentMethods && selectedPaymentID != '--'" class="m-t-20">
												<div class="flexbox">
													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 " :class="{'has-error': validationErrors.cc_number}">
														<div class="col-md-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c" for="card-number"><span ng-show="true" class="required-asterisk color-primary">*</span>CARD NUMBER</label>
														</div>
														<div class="col-md-7 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<input id="card-number" v-model="paymentInfo.cc_number" type="tel" class="form-control" autocomplete="billing cc-number" maxlength="" :disabled="savedCardIsSelected" tabindex="1">
															<i class="fa form-control-feedback bootstrap-text-icon" aria-hidden="true" />
														</div>
														<div v-if="validationErrors.cc_number" class="col-lg-7 col-md-7 col-lg-offset-5  col-md-offset-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<span class="color-primary inline-block">{{ validationErrors.cc_number }}</span>
														</div>
													</div>
												</div>
												<div class="flexbox">
													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.name_on_card || validationErrors.general}">
														<div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c" for="cardholder-name"><span class="required-asterisk color-primary">*</span>CARDHOLDER NAME</label>
														</div>
														<div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
															<input id="cardholder-name" v-model="paymentInfo.name_on_card" :disabled="savedCardIsSelected" type="text" class="form-control" autocomplete="billing cc-name" tabindex="1">
															<i class="fa form-control-feedback bootstrap-text-icon" aria-hidden="true" />
														</div>
														<div v-if="validationErrors.name_on_card" class="col-lg-7 col-md-7 col-lg-offset-5 col-md-offset-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 ">
															<span class="color-primary inline-block">{{ validationErrors.name_on_card }}</span>
														</div>
													</div>
												</div>
												<div class="flexbox">
													<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
														<div class="col-md-5 col-sm-12 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label class="align-self-c">
																<span class="required-asterisk color-primary">*</span>EXPIRATION DATE
															</label>
														</div>
		
														<div class="col-md-3 col-sm-6 col-xs-6 p-lr-5 sm-p-lr-0 ">
															<div class="arrow" :class="{'has-error': validationErrors.card_exp_month || validationErrors.general }">
																<select
																	id="card_exp_month"
																	v-model="paymentInfo.card_exp_month"
																	class="form-control"
																	autocomplete="billing cc-exp-month"
																	tabindex="1"
																	@change="cvvCheck()"
																>
																	<option value="" disabled="disabled" />
																	<option v-for="month in ccMonths" :key="month.label" :value="month.value">{{ month.label }}</option>
																</select>
															</div>
		
															<div v-if="validationErrors.card_exp_month" style="">
																<span class="color-primary inline-block ng-binding">{{ validationErrors.card_exp_month }}</span>
															</div>
														</div>
		
														<div class="col-md-3 col-sm-6 col-xs-6 p-lr-5 sm-p-r-0 ">
															<div class="arrow" :class="{'has-error': validationErrors.card_exp_year || validationErrors.general }">
																<select
																	id="card_exp_year"
																	v-model="paymentInfo.card_exp_year"
																	class="form-control"
																	autocomplete="billing cc-exp-year"
																	tabindex="1"
																	@change="cvvCheck()"
																>
																	<option value="" disabled="disabled" />
																	<option v-for="year in ccYears" :key="year.label" :value="year.value">{{ year.label }}</option>
																</select>
															</div>
		
															<div v-if="validationErrors.card_exp_year" style="">
																<span class="color-primary inline-block ng-binding">{{ validationErrors.card_exp_year }}</span>
															</div>
														</div>
													</div>
												</div>
		
												<div v-if="showCvv" class="flexbox">
													<div
														class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12"
														:class="{'has-error': validationErrors.card_cvc || validationErrors.general }"
													>
														<div class="col-md-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
															<label for="card-security-code" class="align-self-c">
																<span v-if="true" class="required-asterisk color-primary">*</span>CARD SECURITY CODE
															</label>
														</div>
		
														<div class="col-md-3 col-sm-6 col-xs-6 p-lr-5 sm-p-lr-0">
															<input
																id="card-security-code"
																v-model="paymentInfo.card_cvc"
																type="tel"
																class="form-control"
																autocomplete="billing cc-csc"
																tabindex="1"
															>
															<i class="fa fa-credit-card form-control-feedback bootstrap-text-icon" aria-hidden="true" />
														</div>
		
														<div v-if="validationErrors.card_cvc" class="col-lg-7 col-md-7 col-lg-offset-5 col-md-offset-5 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
															<span class="color-primary">{{ validationErrors.card_cvc }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="paypal-tab" class="tab-pane fade" :class="{'active in': payPalTabActive}">
										<div class="p-t-10 tab-pane-bordered-box">
											<div class="flexbox">
												<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12 p-tb-20">
													NOTE: All Promo Codes, Gift Cards and discounts must be added prior to clicking the Check Out with PayPal button at the bottom of this page.
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
		
							<GiftCertificateInput />
						</div>
						<!-- END Tabbed Payment Method -->

						<!-- Start Billing Address (Credit Card & All Paid By Gift Card State) -->
						<div id="billing-info-area">
							<div class="form-bg">
								<h4 class="m-l-10 m-b-0 inline-block">Billing Address</h4>
								<div class="sg-form">
									<BillingAddressForm
										ref="billingAddressFormRef"
										:opted-in-phone-list="optedInPhoneNumbers"
										:saved-card-is-selected-prop="savedCardIsSelected"
										:saved-card-billing-address="savedCardBillingAddress"
										@qas-fingerprint="onQasFingerprintChange"
									/>
								</div>
							</div>
						</div>

						<div class="row m-b-50">
							<div class="col-xs-12 col-sm-4" />
							<div class="col-xs-12 col-sm-8 sg-form">
								<PromoCode title="promo code" type="discount_code" change-hook="regenLineitems" checkout-step="cart" :cart="cart" />
							</div>
						</div>
					</div>
					<div id="order-sum-outer">
						<div class="col-md-4 order-sum-contain">
							<CartSummary page="billing" />
						</div>
					</div>
					<br class="clear">
					<CheckoutNavigation
						page="billing"
						:order-in-flight="orderInFlight"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useCartStore } from '../../../stores/CartStore'
import { usePromoCodeStore } from '../../../stores/PromoCodeStore'
import formatDateMixin from '../../../mixins/date-formatter-mixin.js'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
import BillingAddressForm from './billing-address-form.vue'
import CartSummary from '../cart-summary.vue'
import GiftCertificateInput from '../../global/gift-certificate-input.vue'
import CheckoutNavigation from '../checkout-navigation.vue'
import CheckoutSteps from '../checkout-steps.vue'
import PromoCode from '../../global/promo-code.vue'
import { useUserStore } from '../../../stores/UserStore.js'

const axios = require('axios')

const defaultValidationErrors = () => ({
	cc_number: '',
	name_on_card: '',
	card_exp_month: '',
	card_exp_year: '',
	card_cvc: '',
	general: ''
})

const defaultPaymentInfo = () => ({
	payt_id: '--',
	cc_number: '',
	name_on_card: '',
	card_exp_month: '',
	card_exp_year: '',
	card_cvc: ''
})

export default {
	name: 'BillingPage',
	components: {
		BillingAddressForm,
		CartSummary,
		GiftCertificateInput,
		CheckoutNavigation,
		CheckoutSteps,
		PromoCode
	},
	mixins: [
		formatDateMixin,
		checkoutHelpers
	],
	props: [],
	data() {
		return {
			cart: {},
			ccTabActive: true,
			payPalTabActive: false,
			savedPaymentMethods: false,
			selectedPaymentID: '--',
			savedCardIsSelected: false,
			manualPaymentOptionErrors: {},
			showCvv: true,
			paymentInfo: defaultPaymentInfo(),
			ccMonths: [
				{ 'value': 1,  label: '01 - Jan' },
				{ 'value': 2,  label: '02 - Feb' },
				{ 'value': 3,  label: '03 - Mar' },
				{ 'value': 4,  label: '04 - Apr' },
				{ 'value': 5,  label: '05 - May' },
				{ 'value': 6,  label: '06 - Jun' },
				{ 'value': 7,  label: '07 - Jul' },
				{ 'value': 8,  label: '08 - Aug' },
				{ 'value': 9,  label: '09 - Sep' },
				{ 'value': 10, label: '10 - Oct' },
				{ 'value': 11, label: '11 - Nov' },
				{ 'value': 12, label: '12 - Dec' },
			],
			ccYears: [],
			validationErrors: defaultValidationErrors(),
			showGCBoxFlag: false,
			optedInPhoneNumbers: [],
			QASComplete: false,
			skipQAS: true,
			billingAddressSameAsShipping: false,
			getPerpetualSubscription: false,
			lastQASFingerprint: '',
			orderInFlight: false,
		}
	},
	computed: {
		giftCardCoversTotal() {
			return useCartStore().cart?.summary_section_totals?.cart_net_total == 0
		},
		hasAutoDelivery() {
			return useCartStore().isAutoDelivery
		},
		savedCardBillingAddress() {
			return this.savedPaymentMethods[this.selectedPaymentID]?.billing_address
		}
	},
	created(){},
	mounted() {
		// Show reduced header while in checkout
		this.showShortHeader()

		// Set the mini-cart count
		this.cartStore = useCartStore()
		if(!this.cartStore.cartIsValid()) {
			this.clearStoresAndBailOut()
		}

		this.cartStore.calculateTax(this.getPage())

		emitter.on('cart-tax-updated', (evt) => {
			this.hideLoadingOverlay()
		})

		this.getPerpetualSubscription = this.cartStore.getPerpetualSubscription

		this.cart = storeToRefs(this.cartStore).fullCart
		this.QASComplete = storeToRefs(this.cartStore).QASComplete

		// Is this a PayPal return?
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		if(urlParams.get('paypal_guid')) {
			this.ccTabActive = false
			this.payPalTabActive = true
			this.selectedPaymentID = null
			this.paymentInfo.type = 'paypal'
			this.paymentInfo.paypal_guid = urlParams.get('paypal_guid')
			emitter.emit('paypal-return-with-guid', {paypalGuid: urlParams.get('paypal_guid')})
		}

		// Get Saved Payment Methods
		axios.get('/api/customer/payment-methods/')
			.then(response => {
				if(response.data.payment_methods && Object.keys(response.data.payment_methods).length) {
					this.savedPaymentMethods = response.data.payment_methods

					// IF the customer has only 1 saved CC AND PAYPAL is NOT currently selected....pre-select the one saved CC
					if(this.ccTabActive && Object.keys(this.savedPaymentMethods).length == 1) {
						this.selectedPaymentID = Object.keys(this.savedPaymentMethods)[0]
						this.chooseSavedPaymentSource()
					}

					for(const paytId in this.savedPaymentMethods) {
						if(this.savedPaymentMethods.hasOwnProperty(paytId)) {
							if(this.savedPaymentMethods[paytId].billing_address.sms_opt_in) {
								this.optedInPhoneNumbers.push(this.savedPaymentMethods[paytId].billing_address.phone.replace(/\D/g,''))
							}
						}
					}						
				}
			})

		// Get list of CC Expiration years
		axios.get('/api/customer/cc-years')
			.then(response => {
				this.ccYears = response.data.ccYears
			})

		// PLACE ORDER BUTTON CLICKED
		// First this is to pre-validate payment info here
		// Second we tell the billing-address-form to check the billing address
		// Third, we submit the order
		emitter.on('place-order-button-clicked', (evt) => {
			var valid = true

			if(!this.giftCardCoversTotal && this.paymentInfo.type != 'paypal') { 
				valid = this.validateCreditCardData()
			}

			if(valid) {
				emitter.emit('payment-info-validated')
			} else {
				this.orderInFlight = false
				setTimeout(() => {
					if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
						$('html, body').animate({
							scrollTop: $('.has-error:visible:first').offset().top - 75
						}, 500)
					}
				}, 500);
			}
		})

		emitter.on('place-order-billing-address-prevalidated', (evt) => {
			var sms_info = {
				sms_opt_in: this.cartStore.getSMSOptInFlag
			};

			let paytId = this.selectedPaymentID
			if(this.payPalTabActive || (this.ccTabActive && this.selectedPaymentID == 'new')) {
				paytId = null
			}

			const incomingFingerprint = this.$utilities.generateAddressFingerprint(evt.billingAddress)

			if (incomingFingerprint !== this.lastQASFingerprint) {
				this.skipQAS = false

				if (evt.billingAddress.addr_id) {
					const savedFingerprint = this.$utilities.generateAddressFingerprint(useUserStore().getAddressById(evt.billingAddress.addr_id))

					if (incomingFingerprint === savedFingerprint) {
						this.skipQAS = true
					}
				}
			}

			this.clearValidationErrors()

			this.showLoadingOverlay()
			this.orderInFlight = true

			this.cartStore.updateBillingAddress({
				billing_info: evt.billingAddress,
				payt_id: paytId,
				skip_qas: this.skipQAS
			}).then((response) => {
				if(typeof(response.data.field_errors) != 'undefined' && !response.data.field_errors.passed) {
					if(response.data.field_errors.recipient_errors) {
						// There are recipient errors.
						// Recipient errors must be resolved on the shipping page
						// Stash the validation errors on the cartStore so we can
						// check them when we get to the shipping page
						emitter.emit('in-flight-order-reset')
						this.cartStore.validationErrors = response.data.field_errors
						window.location.href = '/checkout/shipping'
					}
					
					// CHECK FOR BILLING ADDRESS QAS ISSUES
					if(typeof(response.data.field_errors.billing_info) != 'undefined') {
						// ADDRESS HAS VALIDATION ISSUES AND REQUIRES CUSTOMER ACTION
						if(
							typeof response.data.field_errors.billing_info[0].address_1 != 'undefined'
							&& response.data.field_errors.billing_info[0].address_1[0][1] == 'ups_addr_suggest_warning'
						) {
							emitter.emit('billing-qas-prompt', {
								qasData: response.data.field_errors.billing_info[0].address_1[0][3]
							})
						} else {
							if (this.$refs.billingAddressFormRef) {
								this.$refs.billingAddressFormRef.handleValidationErrors(response.data.field_errors.billing_info)
								this.$refs.billingAddressFormRef.showStaticBillingInfoWithEdit = false
							} else {
								this.$sentry.captureException(new Error('Attempt to reference nonexistant Vue ref billingAddressFormRef'))
							}

							setTimeout(() => { 
								if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
									$('html, body').animate({
										scrollTop: $('.has-error:visible:first').offset().top - 75
									}, 500)
								}
							}, 500)
						}
					}

					this.orderInFlight = false
					this.hideLoadingOverlay()
				} else {
					// In case the address was auto-fixed
					// swap what we have with what came back from the server
					this.cart.billing_info = response.data.cart.billing_info
					emitter.emit('billing-info-auto-fixed', {autoFixAddress: response.data.cart.billing_info})

					this.lastQASFingerprint = this.$utilities.generateAddressFingerprint(response.data.cart.billing_info)

					if(this.savedCardIsSelected || (this.ccTabActive && (this.selectedPaymentID == 'new' || this.selectedPaymentID == '--'))) {
						this.paymentInfo.type = 'credit_card',
						this.paymentInfo.payt_id = this.selectedPaymentID
					} else if(urlParams.get('paypal_guid')) {
						this.paymentInfo = {
							type: 'paypal',
							paypal_guid: urlParams.get('paypal_guid')
						}
					}
					if(this.giftCardCoversTotal) {
						this.paymentInfo.type = 'giftcard'
					}

					this.cartStore.placeOrder({
						payment_info: this.paymentInfo,
						sms_info: sms_info
					}).then((response) => {
						if(typeof response.data.order_guid != 'undefined') {
							// Clear local storage and send to reciept page
							useCartStore().completeCart()
							usePromoCodeStore().clearCode()
							// Analytics tracking events
							this.fireRecordCheckoutDecisionEvent('paymentStep', this.paymentInfo.type)
							window.location.href = '/checkout/receipt/' + response.data.order_guid;
						} else {
							// Problem placing order --- handle errors
							this.handlePlaceOrderErrorResponse(response)
						}
					}).catch((error) => {
						if (error.response) {
							this.handlePlaceOrderErrorResponse(error.response)
						} else {
							this.$sentry.captureException(error)
						}

						this.orderInFlight = false
					}).finally(() => {
						this.hideLoadingOverlay()
					})
				}
			}).catch((error) => {
				this.orderInFlight = false
				this.hideLoadingOverlay()
				this.$sentry.captureException(error)
			})
			.finally(() => {
				// this.hideLoadingOverlay()
			})

		})

		emitter.on('gift-certificate-successfully-removed', (evt) => {
			this.cartStore.updateCartDataFromServer()
		})

		emitter.on('promo-code-success', evt => {
			this.cartStore.calculateTax('billing')
		})

		emitter.on('cart-not-found', (evt) => {
			this.clearStoresAndBailOut()
		})
	},
	methods: {
		validateCreditCardData() {
			let valid = true
			// Start by clearing any validation errors from a previous attempt
			this.validationErrors = defaultValidationErrors()

			var luhn10 = (cardNo) => {
				let nDigits = cardNo.length;
				if(nDigits > 16) { return false }
			
				let nSum = 0
				let isSecond = false

				// Iterate over the string of numbers starting from the end and working
				// toward the beginning.
				// For odd indexes just sum the value of the each number doubled
				// For even indexes do the same...BUT, if the number doubled is greater than 10
				// use the value of the two digets added together
				for (let i = nDigits - 1; i >= 0; i--) {
					let d = cardNo[i].charCodeAt() - '0'.charCodeAt()
					if (isSecond == true) { d = d * 2 }
			
					nSum += parseInt(d / 10, 10)
					nSum += d % 10
			
					isSecond = !isSecond
				}

				return (nSum % 10 == 0)
			}

			if(this.savedPaymentMethods && this.paymentInfo.payt_id == '--') {
				this.validationErrors.selected_payment = "Please select a payment method."
				return false
			}
			if(!this.paymentInfo.payt_id || this.paymentInfo.payt_id === '--' || this.paymentInfo.payt_id == 'new') {
				// TEST THE NAME
				var pattern = new RegExp(/[~`@!#$%\^&*+=\[\]\\;/{}|\\":<>\?]/);
				var char_test = new RegExp(/([a-zA-z]){3,}/);
				if ( this.paymentInfo.name_on_card == '' ) {
					valid = false
					this.validationErrors.name_on_card = 'Name is required';
				} else if(pattern.test(this.paymentInfo.name_on_card)) {
					valid = false
					this.validationErrors.name_on_card = 'Invalid characters in cardholder name';
				} else if(!char_test.test(this.paymentInfo.name_on_card)) {
					valid = false
					this.validationErrors.name_on_card = 'Cardholder name must contain letters';
				}

				// TEST THE CC NUMBER
				if(!this.paymentInfo.cc_number || this.paymentInfo.cc_number == '') {
					valid = false
					this.validationErrors.cc_number = 'Credit card number is required';
				} else {
					var flat_cc_num = this.paymentInfo.cc_number.toString().replace(/\D/,'');

					if ( flat_cc_num.length < 13 ) {
						valid = false
						this.validationErrors.cc_number = 'Credit card number is required';
					} else if ( !luhn10(flat_cc_num) ) {
						valid = false
						this.validationErrors.cc_number = 'Not a valid credit card number';
					} else if ( ! /^\d+$/.test(flat_cc_num) ) {
						valid = false
						this.validationErrors.cc_number = 'Credit card must only contain numbers';
					}
				}
			}

			// TEST THE EXP DATE
			if ( !this.paymentInfo.card_exp_month || this.paymentInfo.card_exp_month == '' ) {
				valid = false
				this.validationErrors.card_exp_month = 'Required';
			} else if ( ! this.paymentInfo.card_exp_month.toString().match(/^\d{1,2}$/) ) {
				valid = false
				this.validationErrors.card_exp_month = 'Card expiration month is invalid';
			}

			if ( !this.paymentInfo.card_exp_year || this.paymentInfo.card_exp_year == '' ) {
				valid = false
				this.validationErrors.card_exp_year = 'Required';
			} else if ( ! this.paymentInfo.card_exp_year.toString().match(/^(\d{1,2}|\d{4})$/) ) {
				valid = false
				this.validationErrors.card_exp_year = 'Card expiration year is invalid';
			}

			if ( this.paymentInfo.card_exp_month != '' && this.paymentInfo.card_exp_year != '' ) {
				// Test whether the date entered has passed
				var curDate = new Date();
				var curYear = parseInt(curDate.getFullYear());
				var curMonth = parseInt(curDate.getMonth()) + 1; // Select months are 1 based....js Date object months are 0 based

				if( parseInt(this.paymentInfo.card_exp_year) < curYear ) {
					// Year has passed - auto fail
					valid = false
					this.validationErrors.card_exp_month = ' ';
					this.validationErrors.card_exp_year = 'Please update your expiration date';
				} else if( parseInt(this.paymentInfo.card_exp_year) === curYear && parseInt(this.paymentInfo.card_exp_month) < curMonth ) {
					// Month has passed - auto fail
					valid = false
					this.validationErrors.card_exp_month = ' ';
					this.validationErrors.card_exp_year = 'Please update your expiration date';
				}
			}

			// TEST THE CVV
			if (this.showCvv && (!this.paymentInfo.card_cvc || this.paymentInfo.card_cvc === '')) {
				valid = false
				this.validationErrors.card_cvc = 'CVV is required';
			} else {
				let regex = new RegExp(/^[0-9]{3,4}$/)
				if (this.showCvv && !regex.test(this.paymentInfo.card_cvc) ) {
					valid = false
					this.validationErrors.card_cvc = 'Invalid CVV';
				}
			}

			return valid
		},
		paymentMethodTabChange(tab) {
			switch(tab) {
			case 'cc-tab':
				this.ccTabActive = true
				this.payPalTabActive = false
				if(this.savedCardIsSelected) {
					this.skipQAS = true
				} else {
					this.skipQAS = false
				}

				this.paymentInfo.type = 'credit-card'
				
				if (this.savedPaymentMethods) {
					this.selectedPaymentID = Object.keys(this.savedPaymentMethods).pop()
					this.chooseSavedPaymentSource()
				} else {
					this.selectedPaymentID = '--'
				}
				
				emitter.emit('credit-card-selected')
				break
			case 'paypal-tab':
				this.ccTabActive = false
				this.selectedPaymentID = null
				this.payPalTabActive = true
				this.paymentInfo.type = 'paypal'
				if(this.billingAddressSameAsShipping) {
					this.skipQAS = true
				} else {
					this.skipQAS = false
				}
				emitter.emit('paypal-selected')
				break
			}
		},
		chooseSavedPaymentSource() {				
			this.showQASOptions = false;
			if( this.selectedPaymentID == 'new' ) {
				this.paymentInfo = {
					...defaultPaymentInfo(),
					payt_id: 'new'
				}
				this.clearValidationErrors()
				this.skipQAS = false
				this.showCvv = true
				this.clickedEditStaticBillingInfo = true
				this.savedCardIsSelected = false

				emitter.emit('new-payment-selected')
			} else if(this.selectedPaymentID == '--') {
				this.paymentInfo = {
					...defaultPaymentInfo(),
					payt_id: '--'
				}
			} else {
				this.clearValidationErrors()
				this.showCvv = false;
				this.clickedEditStaticBillingInfo = false;
				this.savedCardIsSelected = true;

				let selectedCardBillingAddress = this.savedPaymentMethods[this.selectedPaymentID].billing_address
				this.paymentInfo = this.savedPaymentMethods[this.selectedPaymentID].card_info
				this.paymentInfo.payt_id = this.selectedPaymentID
				emitter.emit('saved-payment-selected', {savedCardIsSelected: this.savedCardIsSelected, billingAddress: selectedCardBillingAddress})
				this.lastQASFingerprint = this.$utilities.generateAddressFingerprint(selectedCardBillingAddress)
				this.validateCreditCardData()
			}

			this.stashedExpiration = {
				month: this.paymentInfo.card_exp_month,
				year: this.paymentInfo.card_exp_year
			};
			this.cvvCheck();
		},
		cvvCheck() {
			if(typeof(this.paymentInfo) != 'undefined' && typeof(this.paymentInfo.card_exp_month) != 'undefined' && typeof(this.stashedExpiration) != 'undefined') {
				if (this.paymentInfo.payt_id == 'new' || (this.paymentInfo.card_exp_month != this.stashedExpiration.month || this.paymentInfo.card_exp_year != this.stashedExpiration.year )) {
					this.showCvv = true;
				} else {
					this.showCvv = false;
				}
			}
		},
		showGCBox() {
			this.showGCBoxFlag = !this.showGCBoxFlag
		},
		clearValidationErrors() {
			this.validationErrors = defaultValidationErrors()
		},
		onQasFingerprintChange(fingerprint) {
			this.lastQASFingerprint = fingerprint
			this.skipQAS = true
		},
		handlePlaceOrderErrorResponse(response) {
			if(typeof(response.data.field_errors) != 'undefined' && typeof(response.data.field_errors['payment-0']) != 'undefined') {
				this.orderInFlight = false

				this.validationErrors.general = true

				if(typeof(response.data.field_errors['payment-0']['cc_number']) != 'undefined' && response.data.field_errors['payment-0']['cc_number'][0][1] == 'account_declined') {
					this.validationErrors.cc_number = "We're sorry, but there was a problem processing your order. Please check your credit card and billing information and try again."									
									

					setTimeout(() => {
						if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
							$('html, body').animate({
								scrollTop: $('.has-error:visible:first').offset().top - 75
							}, 500)
						}
					}, 500);
				}
			}
		}
	}
};
</script>
