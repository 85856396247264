import mitt from 'mitt'

export const createLoggedEmitter = () => {
    const emitter = mitt()

    if (process.env.NODE_ENV === 'production') {
        return emitter
    }

    const emit = (type, event) => {
        console.debug(`[mitt] Emitting event: ${type}`, event)
        emitter.emit(type, event)
    }

    const on = (type, handler) => {
        const wrappedHandler = (event) => {
            console.debug(`[mitt] Handling event: ${type}`, event)
            handler(event)
        }

        emitter.on(type, wrappedHandler)

        return () => emitter.off(type, wrappedHandler)
    }

    return {
        on,
        off: emitter.off,
        emit,
    }
}

export default {
    install(app, options) {
        app.config.globalProperties.$emitter = createLoggedEmitter()
    }
}