<template>
	<div id="mini-cart-header">
		<a href="/checkout/cart/" data-title="Action view Cart" class="my-cart__link color-text-light" role="link">
			<span class="relative cart-icon_container">
				<span class="svg-icon__container cart-icon">
					<svg class="svg-icon" viewBox="0 0 526 371" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path class="cls-1 svg-fill-none" d="M141.73 13.06V238.63C141.73 239.426 142.046 240.189 142.609 240.751C143.171 241.314 143.934 241.63 144.73 241.63H510.16C510.956 241.63 511.719 241.314 512.281 240.751C512.844 240.189 513.16 239.426 513.16 238.63V70.2" />
						<path class="cls-1 svg-fill-none" d="M13.16 13.06H141.27" />
						<path class="svg-cart-circle" d="M212.96 370.22C232.682 370.22 248.67 354.232 248.67 334.51C248.67 314.788 232.682 298.8 212.96 298.8C193.238 298.8 177.25 314.788 177.25 334.51C177.25 354.232 193.238 370.22 212.96 370.22Z" />
						<path class="svg-cart-circle" d="M449.22 370.22C468.942 370.22 484.93 354.232 484.93 334.51C484.93 314.788 468.942 298.8 449.22 298.8C429.498 298.8 413.51 314.788 413.51 334.51C413.51 354.232 429.498 370.22 449.22 370.22Z" />
					</svg>
				</span>
				<span id="im-vue" class="cart-count color-white">{{ getCartCount }}</span>
			</span>
			<span class="my-cart-text"> Cart</span>
		</a>
	</div>
</template>

<script>
import axios from 'axios'
import { useCartStore } from '../../stores/CartStore'
import { mapState } from 'pinia'

export default {
	name: 'MiniCart',
	data() {
		return {
			cartCount: 0
		}
	},
	computed: {
		...mapState(useCartStore, ['getCartCount','cart']),
	},
	async mounted() {
		// Get the cart count.  If we can't find a cart but there is one in the cartSore...dump it
		this.cartStore = useCartStore()
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		if(urlParams.has('reload') || !(await this.cartStore.cartIsValid())) {
			this.cartStore.updateCartDataFromServer()
		}
	}
}
</script>
<style>
.cart-icon {
	color: #C7A47F !important;
}
.cls-1 {
	fill: none;
	stroke: currentColor;
	stroke-linecap: round;
	stroke-miterlimit: 10;
	stroke-width: 25px;
}
.svg-cart-circle {
	fill: currentColor;
}
</style>