<template>
	<div class="promo-bars">
		<AdUniversalPromoBar v-if="!isMobile" />
	</div>
</template>

<script>
import AdUniversalPromoBar from './ad-universal-promo-bar.vue'

export default {
	components: {
		AdUniversalPromoBar
	},
    
	props: {
		isMobile: {
			type: Boolean,
			default: false
		}
	},
}
</script>

<style scoped>
.promo-bars {
    margin-top: -10px;
    margin-bottom: 25px;

    @media (min-width: 768px) {
        margin-top: -25px;
    }
}
</style>