<template>
	<div>
		<div v-if="loadingCart" style="height:500px;" />
		<span v-else>
			<!-- If no items in cart -->
			<div v-if="recipients.length === 0" align="center" class="login_reload">
				<div class="row p-b-100">
					<h3>Cart is Empty</h3>
					<p>We recommend checking out our best selling steaks</p>
					<a class="button button-primary" href="/steaks/filet-mignon">Shop Filet Mignon</a>
				</div>

				<RecentlyViewedItems :items="recentlyViewed" />
			</div>

			<div v-if="recipients.length > 0" class="login_reload">
				<!-- cart discount expired -->
				<div v-if="cart.discount_is_expired" class="row text-center">
					<div class="col-xs-12">
						<div class="cart-error md-m-lr-n15 xs-m-lr-3 md-m-t-5 xs-m-t-15">
							<i class="fa fa-ban" aria-hidden="true" /> We're sorry, your promo code <strong>{{ cart.discount_code }}</strong> has expired.
						</div>
					</div>
				</div>
				<!-- cart discount mil/med -->
				<div v-if="cart.discount_code == '2209MI20' || cart.discount_code == '2209FR20' || cart.discount_code == '2209NU20'" class="row text-center">
					<div class="col-xs-12 id-me-success">
						<div class="cart-success">
							<span v-if="cart.discount_code == '2209MI20'">Your military discount has been applied.  Thank you for your service!</span>
							<span v-if="cart.discount_code == '2209FR20'">Your first responder discount has been applied.  Thank you for your contributions!</span>
							<span v-if="cart.discount_code == '2209NU20'">Your nurse discount has been applied.  Thank you for your contributions!</span>
						</div>
					</div>
				</div>
				<!-- cart specials -->
				<div v-if="cartStore.doesCartAddonQualify && cart.cart_specials.skus && cart.cart_specials.skus.length && cart.disable_cart_cross_sell === false">
					<InCartSpecials :combo="combo" :skus="skus" :single-special-modal-presented="singleSpecialModalPresented" />
				</div>

				<div class="row">
					<div class="col-xs-12 col-md-4">
						<h2 class="your-cart">Your Cart</h2>
					</div>
					<CheckoutNavigation
						page="cart"
						:back-button-link="backButtonLink"
						:stock="stockError"
						additional-classes="col-xs-12 col-md-8"
					/>
					<div v-if="stockError" class="col-xs-12">
						<div class="stock-alert">Alert:  Item(s) in your cart are not currently available.  Please remove them before continuing.</div>
					</div>
				</div>

				<div v-if="cart.promo_code_alert" class="row xs-m-t-15 md-m-t-0">
					<div class="col-xs-12">
						<div class="promo_code_alert">{{ cart.promo_code_alert }}</div>
					</div>
				</div>

				<div class="col-xs-12">
					<div class="cart m-t-10">

						<!-- more than 50 items! -->
						<div v-if="recipients.length > 50" class="row">
							<div class="msg-error">
								Great!  You have more than 50 items in your cart.  That's OK!  But if you want to clear your cart, click here to
								<button type="submit" class="btn secondary-btn short" onclick="location.href='/checkout/empty-cart'">Empty your Cart</button>
							</div>
						</div>

						<template v-else-if="cart.recipients.length > 0">
							<div v-for="recip in cart.recipients" :key="recip.crec_id">
								<!-- start desktop view -->
								<div class="hidden-xs hidden-sm mc-contain p-b-20">
									<div class="row">
										<div class="col-md-5 shipto">
											<span class="ship-to m-r-10">SHIP TO</span>
											<div class="col-md-8">
												<ShipToSelector
													:crec-id="recip.crec_id"
													:addr-id="recip.addr_id"
												/>
											</div>
										</div>
										<div class="col-md-7">
											<AutoDelivery element="main-select" :recipient-id="recip.crec_id" />
										</div>
									</div>
	
									<div v-if="recip.recipientXmasMessage === false" class="row xs-m-t-15 md-m-t-0">
										<div class="col-xs-12">
											<div class="promo_code_alert ng-binding">
												Alert:
												This shipment may not arrive before Christmas.
												It contains items that are not guaranteed for Christmas Delivery.
											</div>
										</div>
									</div>
	
									<span v-if="recip.cart_type !== 'ECRT'">
										<AutoDelivery element="cart-banner" :recipient-id="recip.crec_id" />
									</span>
	
									<div v-for="(line) in recip.items" :key="line.clin_id" class="row m-t-20">
										<LineItem :line="line" :cart="cart" :recipient-id="recip.crec_id" :recipient="recip" page="cart" />
									</div>
									<LineItemFree :cart="cart" :recipient="recip" />
								</div>
								<!--end desktop view-->
								<!--start mobile view-->
								<div class="hidden-md hidden-lg p-b-10 mc-contain">
									<div class="row">
										<div class="col-xs-12 col-sm-6 shipto">
											<span class="m-r-10 ship-to">SHIP TO</span>
											<div class="arrow" style="width: 74%">
												<ShipToSelector
													:crec-id="recip.crec_id"
													:addr-id="recip.addr_id"
												/>
											</div>
										</div>
									</div>

									<div v-if="recip.recipientXmasMessage == false" class="row xs-m-t-15 md-m-t-0">
										<div class="col-xs-12">
											<div class="promo_code_alert ng-binding">
												Alert: This shipment may not arrive before Christmas.
												It contains items that are not guaranteed for Christmas Delivery.
											</div>
										</div>
									</div>

									<span v-if="recip.cart_type !== 'ECRT'">
										<AutoDelivery element="cart-banner" :recipient-id="recip.crec_id" />
									</span>
	
									<div v-for="line in recip.items" :key="line.clin_id" class="row m-t-20">
										<LineItemMobile :line="line" :cart="cart" :recipient-id="recip.crec_id" :recipient="recip" page="cart" />
									</div>
									<LineItemFreeMobile :cart="cart" :recipient="recip" />
								</div>
								<!-- end mobile view -->
								<div v-if="recip.is_in_addon_fallback" class="row">
									<div class="msg-error">
										This add-on can not ship alone, and is switched to the
										<br class="visible-sm visible-xs">
										non-add-on version.
										Now:&nbsp;{{ currency(line.price) }}
										(&nbsp;Was:&nbsp;{{ currency(line.original_price) }}&nbsp;)
										<br>
										You may adjust your shipments to undo this, or continue with this price.
									</div>
								</div>
							</div>
						</template>
					</div>

					<div v-if="cart.promo_code_alert" class="row m-t-30">
						<div class="">
							<div class="promo_code_alert ">{{ cart.promo_code_alert }}</div>
						</div>
					</div>

					<div class="row cart-total">
						<div class="col-xs-12 discount-display-box">
							<div class="row">
								<div class="col-xs-12 col-md-6">
									<div v-if="cart.allow_military_authentication" class="promo-code">
										<id-me />
									</div>
								</div>
								<div class="col-xs-12 col-md-6">
									<div class="promo-code">
										<promoCode title="promo code" type="discount_code" change-hook="regenLineitems" checkout-step="cart" :cart="cart" />
									</div>
								</div>
							</div>
						</div>
						<div class="m-t-20 cart-total-right">
							<CartSummary page="cart" />
							<CheckoutNavigation
								page="cart"
								:back-button-link="backButtonLink"
								:stock="stockError"
							/>
						</div>
					</div>

					<trust-icons />

					<RecentlyViewedItems :items="recentlyViewed" />
				</div>
			</div>

			<Modal v-if="cartSpecialModalVisible" :modal-width="250" modal-type="inCartSpecialModal" @close-modal="closeModal">
				<template #body>
					<div style="padding: 30px 20px;">
						<div class="cart-special-added-modal-header m-t-20 m-b-20">
							<i class="fa fa-check" style="color: #67ae18" /> ITEM ADDED TO CART
						</div>

						<button
							type="button"
							class="button shrink full text-center"
							@click="closeModal('inCartSpecialModal')"
						>Close</button>
					</div>
				</template>
			</Modal>
		</span>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { emitter } from '../../../main'
import { useCartStore } from '../../../stores/CartStore'
import { usePromoCodeStore } from '../../../stores/PromoCodeStore'
import InCartSpecials from './in-cart-specials.vue'
import RecentlyViewedItems from './recently-viewed-items.vue'
import ShipToSelector from '../../global/ship-to-selector.vue'
import CheckoutNavigation from '../checkout-navigation.vue'
import promoCode from '../../global/promo-code.vue'
import LineItem from './line-item.vue'
import LineItemMobile from './line-item-mobile.vue'
import IdMe from './id-me.vue'
import currencyFormatterMixin from '../../../mixins/currency-formatter-mixin'
import CartSummary from '../cart-summary.vue'
import AutoDelivery from '../../global/auto-delivery.vue'
import LineItemFree from './line-item-free.vue'
import LineItemFreeMobile from './line-item-free-mobile.vue'
import TrustIcons from '../../global/trust-icons.vue'
import Modal from '../../modals/modal.vue'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'

const axios = require('axios')

export default {
	name: 'CartPage',
	components: {
		InCartSpecials,
		RecentlyViewedItems,
		ShipToSelector,
		CheckoutNavigation,
		promoCode,
		LineItem,
		LineItemMobile,
		IdMe,
		CartSummary,
		AutoDelivery,
		LineItemFree,
		LineItemFreeMobile,
		TrustIcons,
		Modal,
	},
	mixins: [
		currencyFormatterMixin, checkoutHelpers
	],
	props: {},
	data() {
		return {
			stepRedirectError: '',
			recipients: [],
			totalItemsCount: '',
			recentlyViewed: [],
			stockError: false,
			showAutoDeliveryBanner: true,
			continueShoppingURL: '/#',
			cartSpecialModalVisible: false,
			loadingCart: true,
			myselfId: 0,
			backButtonLink: '',
			cartAddonQualify: false,
			singleSpecialModalPresented: false,
		};
	},
	computed: {
		...mapState(useCartStore, {
			cart: 'fullCart',
			outOfStockItems: 'getOutOfStockItems',
		})
	},
	created() {},
	mounted() {
		// console.log('CartPage mounted - start loading')
		// grab cart store
		this.showLoadingOverlay()
		this.cartStore = useCartStore()
		this.cartStore.updateCartDataFromServer()
			.then((response) => {
				this.promoCodeStore = usePromoCodeStore()
				if(this.cart.discount_code) {
					this.promoCodeStore.validateExistingPromoCode(this.cart.discount_code)
				} else {
					this.promoCodeStore.clearCode()
				}
				this.cartStore.updateRecentlyViewedItems()
			})

		let _this = this
		axios.get('/api/checkout/get-continue-shopping-url/')
			.then((response) => {
				_this.backButtonLink = response.data.redirect_url
			})
			.catch((error) => {
				console.log('error getting shopping url', error)
			})

		emitter.on('cart-updated-from-server', evt => {
			this.setupCart()
		})
		emitter.on('cart-updated', (type, evt) => {
			this.setupCart()
			if(type === 'singleSpecial' && !this.singleSpecialModalPresented) {
				this.singleSpecialModalPresented = true
				this.cartSpecialModalVisible = true
			}
		})

		emitter.on('single-item-added', evt => {})
		emitter.on('close-special-modal', evt => {
			this.cartSpecialModalVisible = false
			emitter.emit('single-special-added')
		})

		emitter.on('item-out-of-stock', item => {
			this.stockError = true

			this.$nextTick(() => {
				$('html, body').animate({
					scrollTop: $('.stock-alert').offset().top
				}, 500)
			})
		})
		emitter.on('item-back-in-stock', item => {
			this.stockError = false
		})
		// Listen for recipient addr_id change coming from ShipToSelector
		emitter.on('ship-to-addr-id-changed', (evt) => {
			this.showLoadingOverlay()
			this.cartStore.recipientChangeSavedAddress(evt.crec_id, evt.uuid, evt.is_new)
				.finally(this.hideLoadingOverlay)
		})
		// emitter.on('auto-delivery-frequency-change', (evt) => {
		// 	if(evt.ADSelected) {
		// 		this.showAutoDeliveryBanner = false
		// 	} else {
		// 		this.showAutoDeliveryBanner = true
		// 	}
		// })
		emitter.on('update-club-line', evt => {
			this.cartStore.updateFixedClubItem(evt.line, evt.newLineData)
		})
		emitter.on('promo-code-success', evt => {
			// update the cart page just in case
			this.cartStore.updateCartDataFromServer()
		})
		emitter.on('need-to-update-from-server', evt => {
			this.showLoadingOverlay()
			this.cartStore.updateCartDataFromServer()
		})
		emitter.on('hide-loading-overlay', (evt) => {
			this.hideLoadingOverlay()
		})
		emitter.on('set-single-special-modal-presented', (evt) => {
			this.singleSpecialModalPresented = evt
		})

		// Analytics Tracking events
		emitter.on('fire-add-cart-special-event', (evt) => {
			this.fireAddCartSpecialEvent(evt)
		})
		emitter.on('fire-change-line-sku-event', (evt) => {
			this.fireChangeLineSKUEvent(evt.currentLine, evt.newSku)
		})
		emitter.on('fire-remove-from-cart-event', (evt) => {
			this.fireRemoveFromCartEvent(evt)
		})

		// for testing:
		// console.log(this.cartStore)
		// this.recipients.length = 55 // for testing empty cart
	},
	methods: {
		setupCart(fromMounted = false) {
			if(this.outOfStockItems?.length < 1) {
				this.stockError = false
			}
			this.cartAddonQualify = this.cartStore.doesCartAddonQualify
			// set the in-cart special items
			this.combo = this.cart?.cart_specials?.combo
			this.skus = this.cart?.cart_specials?.skus
			this.abTests = this.cart?.ab_tests

			this.recipients = this.cart?.recipients
			// this.getMyselfId()
			this.totalItemsCount = this.cartStore.getMiniCartCount
			this.recentlyViewed = this.cartStore.getRecentlyViewedItem
			this.loadingCart = false
			this.hideLoadingOverlay()
		},
		getMyselfId() {
			const tempId = this.recipients.findIndex(
				(recipient => recipient.is_myself === '1')
			)
			if(tempId === 0) {
				this.myselfId = this.recipients[tempId]?.addr_id
			}
		},
		isMyselfCheck(me) {
			// is_myself is coming in as a string of "1" so we need to convert it
			if(me === "1") {
				return true
			} else {
				return false
			}
		},
		showModal() {
			this.cartSpecialModalVisible = true
		},
		closeModal(type) {
			if(type === 'inCartSpecialModal') {
				// send event to inCartSpecials
				emitter.emit('single-special-added')
			}
			emitter.emit('modal-closed', type)
			this.cartSpecialModalVisible = false
		},
		cartQuickCount() {
			axios.get("/api/index/get-quick-cart-count")
				.then((response) => {
					this.totalItemsCount = response.data.item_count
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})

		},
	}
};
</script>

<style>
	html {
		scroll-behavior: smooth;
	}
	@media (min-width:641px) {
		.cart-quantity {
			padding: 0 2px 0 14px !important;
		}
	}
</style>