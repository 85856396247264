<template>
	<div class="outer_ship_modal">
		<div
			ref="target"
			class="ship_to_modal"
			style="max-width: 60%;"
		>
			<div
				id="kcs_sku_selector"
				class="kcs_sku_selector col-xs-12"
			>
				<div v-if="clubData?.club_data?.allowed_items.length > 0">
					<div class="kcs_sku_selector_flyout">
						<h3>MONTHLY STEAK PLAN</h3>
						<p>
							Choose your items from our amazing selection below.
							Please select your monthly shipments in the order they will be received,<br>
							then click "Add" to place this steak plan in your cart.
						</p>

						<div class="container-fluid">
							<div v-if="clubType === 'choice'">
								<form class="form-horizontal">
									<div
										v-for="(items, key) in currentClubFixedOrder"
										:key="key"
									>
										<div
											class="form-group"
											:month-idx="`${ key }`"
										>
											<div class="col-sm-3">
												<img
													class="month-image month-image"
													:src="clubImage(clubData.club_data.item_detail, items.lineitems[0].item_id)"
												>
											</div>
											<div class="col-sm-9 product-card__options">
												<label class="control-label">Select {{ ordinalLabels[key] }} shipment:</label>
												<div class="arrow">
													<select v-model="items.lineitems[0].item_id" class="month-item-id form-control" :month-idx="key" @change="updateChoiceList()">
														<option v-for="clubItem in clubData.club_data.item_detail" :key="clubItem.sku.sku_id" :value="`${clubItem.sku.item_id}`">
															{{ clubItem.sku.sku_name }}
														</option>
													</select>
												</div>
												<p
													class="product-short-description product-short-description"
													v-html="clubData.club_data.item_detail['item-' + items.lineitems[0].item_id].product_page.short_description"
												/>
											</div>
										</div>
									</div>
								</form>
							</div>

							<div v-else>
								<form>
									<div class="form-group product-card__options">
										<label class="control-label">Select your start date</label> {{ line.detail.club_order_start_month }}
										<div class="arrow">
											<select
												v-model="line.detail.club_order_start_month"
												class="fixed-start-month form-control"
												@change="updateFixedList()"
											>
												<option
													v-for="(month, key) in monthOptions"
													:key="key"
													:value="month.id"
												>
													{{ month.label }}
												</option>
											</select>
										</div>
									</div>
								</form>

								<form class="form-horizontal">
									<div
										v-for="(items, key) in line.detail.club_orders"
										:key="key"
										:class="`form-group month-${key}`"
									>
										<div class="row">
											<input
												type="hidden"
												class="month-item-id"
												:value="items.lineitems[0].item_id"
											>
											<div class="col-sm-3">
												<img
													class="month-image month-image"
													:src="clubImage(clubData.club_data.item_detail, items.lineitems[0].item_id)"
												>
											</div>
											<div class="col-sm-9">
												<label class="control-label">{{ ordinalLabels[key] }} shipment:&nbsp;</label>
												<span
													class="product-name product-name"
													v-html="clubData.club_data.item_detail['item-' + items.lineitems[0].item_id].product_page.name"
												/>
												<p
													class="product-short-description product-short-description"
													v-html="clubData.club_data.item_detail['item-' + items.lineitems[0].item_id].product_page.short_description"
												/>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
						<div class="pull-right">
							<button
								class="button update-club-item"
								@click="closeModal()"
							>
								Save Changes
							</button>
						</div>
					</div>
				</div>
				<div
					v-else
					class=""
				>
					<i class="fa fa-spinner fa-spin fa-fw" />
				</div>
			</div>
			<button
				id="cboxClose"
				type="button close"
				@click="closeModal()"
			>
				close
			</button>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import dayjs from 'dayjs'
import { emitter } from '../../main'

export default {
	name: 'ClubChooserModal',
	props: {
		line: {
			type: Object,
			required: true
		},
		recipientId: {
			type: String,
			required: false
		},
		item: {
			type: String,
			required: true
		},
	},
	data() {
		return {
			clubData: {},
			clubType: '',
			monthLabels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			ordinalLabels: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth'],
			monthOptions: [],
			currentFixedMonths: 0,
			currentClubFixedOrder: []
		}
	},
	mounted() {
		this.getProductData()
		this.setupMonthOptions()
	},
	methods: {
		getProductData() {
			axios.get('/api/checkout/line-item-club-edit/' + this.item)
				.then(response => {
					if(response.data.status === 'success') {
						this.clubData = response.data
						this.clubType = response.data.club_data.mode
						this.currentClubFixedOrder = this.line.detail.club_orders
						this.currentFixedMonths = this.clubData.club_data.months
					}
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
		},
		clubImage(clubItem, item) {
			// get the correct image for the club item
			return '/dyn-images/pdp_thumb/' + clubItem['item-' + item].product_page.primary_image
		},
		setupMonthOptions() {
			let year = new Date().getFullYear()
			let month = new Date().getMonth()
			for (let i = 0; i < 24; i++) {
				let monthId = dayjs(new Date(year, month, 1)).format('YYYY-MM-DD')
				this.monthOptions.push({
					id: monthId,
					label: this.monthLabels[month] + ' ' + year,
				})
				month = (month + 1) % 12
				year += (month == 0) ? 1 : 0
			}
		},
		updateChoiceList() {
			emitter.emit('update-club-line', {
				line: this.line,
				newLineData: {
					club_orders: this.currentClubFixedOrder
				}
			})
		},
		updateFixedList() {
			let month = new Date(this.line.detail.club_order_start_month).getMonth()
			month = (month + 1) % 12
			let tempItems = []
			let tempMonth = month
			// get the current month selectected and grab the fixedMonths amount of items from the array - update currentClubFixedOrder
			for (let i = 0; i < this.currentFixedMonths; i++) {
				let itemId = this.clubData.club_data.allowed_items[tempMonth][0]
				tempItems.push({
					lineitems: [{item_id: itemId}]
				})
				tempMonth = (tempMonth + 1) % 12
			}
			this.currentClubFixedOrder = tempItems
			emitter.emit('update-club-line', {
				line: this.line,
				newLineData: {
					club_orders: tempItems,
					club_order_start_month: this.line.detail.club_order_start_month
				}
			})
		},
		closeModal() {
			emitter.emit('close-club-chooser-modal')
		}
	},
}
</script>

<style>
.arrow select {
    border: 2px solid var(--color-secondary) !important;
}
</style>