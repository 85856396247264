<template>
	<div>
		<div class="product-chooser">
			<a @click="openClubEdit()">
				Edit Club Options <i
					class="fa fa-pencil-square-o"
					aria-hidden="true"
				/>
			</a>
		</div>

		<transition>
			<clubChooserModal
				v-if="showClubChooserModal"
				:line="line"
				:recipient-id="recipientId"
				:item="item"
			/>
		</transition>
	</div>
</template>

<script>
import { emitter } from '../../main'
import clubChooserModal from '../modals/club-chooser-modal.vue'

export default {
	name: 'ClubChooser',
	components: { clubChooserModal },
	props: {
		page: {
			type: String,
			required: false,
			default: 'checkout'
		},
		line: {
			type: Object,
			required: true,
		},
		item: {
			type: String,
			required: true
		},
		recipientId: {
			type: String,
			required: false
		},
	},
	data() {
		return {
			showClubChooserModal: false,
		}
	},
	mounted() {
		emitter.on('close-club-chooser-modal', evt => {
			this.showClubChooserModal = false
		})
	},
	methods: {
		openClubEdit() {
			this.showClubChooserModal = true
		}
	},
}
</script>

<style>
.product-chooser a {
    cursor: pointer;
}
</style>