<template>
	<div class="m-t-3 cart-header" :class="containerClass">
		<a id="checkout-guide" href="/checkout/cart" class="cart-guide" @click="$emit('navigate', $event)">
			<span>Cart</span>
			<span class="step-arrow" />
		</a>
		<a href="/checkout/shipping" class="cart-guide cart-ship-arrow" :class="shippingClass" @click="$emit('navigate', $event)">
			<p>
				<span class="cart-num">
					<span v-if="!shippingClass.showcheck">1</span>
					<span
						v-if="shippingClass.showcheck"
						class="fa fa-check"
					/>
				</span>
				<span class="cart-page">Shipping</span>
			</p>
			<span class="step-arrow" />
		</a>
		<a v-if="needsAdditionalShipmentsStep" href="/checkout/additional-shipments/" class="cart-guide cart-recurring-orders-arrow" :class="additionalShipmentsClass" @click="$emit('navigate', $event)">
			<p>
				<span class="cart-num">
					<span v-if="!additionalShipmentsClass.showcheck">2</span>
					<span v-if="additionalShipmentsClass.showcheck" class="fa fa-check" />
				</span>
				<span class="hidden-sm hidden-xs hidden-md cart-page">Add'l. Shipments</span>
			</p>
			<span class="step-arrow" />
		</a>
		<a href="/checkout/billing" class="cart-guide cart-bill-arrow" :class="billingClass" @click="$emit('navigate', $event)">
			<span v-if="!needsAdditionalShipmentsStep" class="cart-num">2</span>
			<span v-if="needsAdditionalShipmentsStep" class="cart-num">3</span>
			<span class="cart-page">Billing</span>
			<span class="step-arrow" />
		</a>
	</div>
	<div class="clearfix" />
</template>

<script>
export default {
	props: {
		page: {
			type: String,
			default: '',
		},
		needsAdditionalShipmentsStep: {
			type: Boolean,
		},
	},

	emits: ['navigate'],

	data() {
		return {

		}
	},
	computed: {
		containerClass() {
			return {
				'show-recurring-orders': this.needsAdditionalShipmentsStep,
				'show-ship': this.needsAdditionalShipmentsStep,
				'hide-recurring-orders': !this.needsAdditionalShipmentsStep
			}
		},
		shippingClass() {
			return {
				'cart-active': this.page == 'shipping',
				'cart-inactive': this.page != 'shipping',
				'showcheck': this.page != 'shipping'
				// 'cart-complete': this.page != 'shipping'
			}
		},
		additionalShipmentsClass() {
			return {
				'cart-inactive': this.page != 'additional-shipments',
				'cart-active': this.page == 'additional-shipments',
				'cart-complete': this.page == 'billing',
				'showcheck': this.page == 'billing'
			}
		},
		billingClass() {
			return {
				'cart-inactive': this.page != 'billing',
				'cart-active': this.page == 'billing'
			}
		}
	},
	created(){

	},
	mounted() {
		// console.log(this.needsAdditionalShipmentsStep)
	},
	methods: {

	}
};
</script>

<style>
	.cart-recurring-orders-arrow {
		display: none;
	}
	.show-recurring-orders .cart-recurring-orders-arrow {
		display: table-cell;
	}

	.cart-ship-arrow {
		/* display: none; */
	}
	.show-ship .cart-ship-arrow {
		display: table-cell;
	}

	.show-recurring-orders.show-ship .cart-guide {
		width: 25%;
	}

	.hide-recurring-orders.hide-ship .cart-guide {
		width: 42.7%;
	}

	.cart-num {
		display: inline-block;
		width: 26px;
		height: 26px;
		margin-right: 5px;
		border: 2px solid #231F20;
		border-radius: 50%;
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
	}

	.showcheck .cart-num {
		color: #00610B !important;
		border: 2px solid #00610B !important;
	}
	.showcheck .cart-page {
		color: #00610B !important;
	}

	.cart-guide {
		background: #AD0B21;
		color: white;
		cursor: pointer;
		float: left;
		font-family: var(--font-alt1);
		font-size: 20px;
		height: 45px;
		line-height: 44px;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		width: 33.33%;
	}

	.cart-guide .step-arrow {
		border-left: 16px solid #AD0B21;
		border-top: 23px solid transparent;
		border-bottom: 22px solid transparent;
		content: '';
		display: block;
		height: 0;
		position: absolute;
		right: -16px;
		top: 0;
		width: 0;
		z-index: 50;
	}

	.cart-guide .step-arrow:before {
		content: '';
		display: block;
		position: absolute;
		top: -27px;
		right: 4px;
		width: 4px;
		height: 30px;
		background-color: #fff;
		z-index: 50;
		transform: rotate(-35deg);
	}

	.cart-guide .step-arrow:after {
		content: '';
		display: block;
		position: absolute;
		top: -4px;
		right: 4px;
		width: 4px;
		height: 30px;
		z-index: 50;
		background-color: #fff;
		transform: rotate(35deg);
	}

	.cart-active {
		background: #4A4A4A;
		color: #FFFFFF;
	}
	.cart-bill-arrow .step-arrow {
		display: none;
	}

	.cart-active .step-arrow {
		border-left-color:#4A4A4A;
	}

	.cart-active .cart-num {
		border-color: #FEF9ED;
		color: #FEF9ED;
	}

	.cart-inactive,
	.cart-completed {
		background-color: #FAF9F7;
	}

	.cart-inactive .step-arrow,
	.cart-completed .step-arrow {
		border-left-color: #FAF9F7;
	}

	.cart-inactive .cart-num {
		font-size: 18px;
		border-color: #231F20;
		color: #231F20;
	}
	.cart-inactive .cart-page {
		border-color: #231F20;
		color: #231F20;
	}

	.cart-completed .cart-num {
		border-color: #00610B;
		color: #00610B;
	}

	.cart-completed .cart-page {
		color: #00610B;
	}

	a.cart-guide,
	a.cart-guide:hover {
		color: white;
		text-decoration: none;
	}
</style>