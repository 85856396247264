import { emitter } from '../main'
import { useCartStore } from '../stores/CartStore'
import removeLineConfirm from '../components/modals/remove-line-confirm.vue'
import SkuChooser from '../components/global/sku-chooser.vue'
import AutoDelivery from '../components/global/auto-delivery.vue'
import ClubChooser from '../components/global/club-chooser.vue'
import checkoutHelpers from './checkout-global-functions.js'

const axios = require('axios')

export default {
	props: {
		line: {
			type: Object,
			required: true
		},
		cart: {
			type: Object,
			required: true
		},
		recipientId: {
			type: String,
			required: true
		},
		recipient: {
			type: Object,
			required: true
		},
		page: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			cartStore: useCartStore(),
			showRemoveModal: false,
			outOfStock: false,
			insufficientStock: false,
			insufficientStockCount: 0,
		}
	},
	components: {
		removeLineConfirm,
		SkuChooser,
		AutoDelivery,
		ClubChooser,
	},
	mixins: [ checkoutHelpers ],
	methods: {
		async updateLineItemQty(item, qty) {
			if (qty <= 0) {
				this.showRemoveModal = true
			} else {
				this.showLoadingOverlay()
				// check insufficient stock count if qty is less than insufficient stock count remove warning msg
				if (qty <= this.insufficientStockCount) {
					this.insufficientStock = false
				}
				if (this.insufficientStockCount !== 0 && qty > this.insufficientStockCount) {
					this.insufficientStock = true
				}
                await useCartStore().updateItemQty(item, qty, this.recipientId)
				this.hideLoadingOverlay()
			}
		},
		checkQty(qty) {
			// stop negative numbers from being input
			if(qty < 0) {
				this.line.qty = 0
			}
		},
		calculateSubTotal(qty, price) {
			return (qty * price) <= 0 ? 0 : (qty * price)
		},
		removeLine(item) {
			console.log(this.cartStore.canRemoveItemFromAutoDelivery(this.line.clin_id))
			if(this.cartStore.lineInAutoDeliveryShipment(this.line.clin_id) && !this.cartStore.canRemoveItemFromAutoDelivery(this.line.clin_id)) {
				alert("By removing this item, your shipment will no longer qualify for Auto Delivery. To remove this item, please first remove Auto Delivery by selecting 'One Time' from the 'Shipment Frequency' select box.")
			} else {
				this.showRemoveModal = true
				// removal will be triggered from modal
			}
		},
	},
	created() {
		this.onChangeDebounced = this.$utilities.debounce((line, e) => {
			this.updateLineItemQty(line, e.target.value)
			// update analytics tracking
			if(line.qty < e.target.value) {
				// increase qty
				this.fireIncreaseLineQtyEvent(line, e.target.value)
			}
			if(line.qty > e.target.value) {
				// decrease qty
				this.fireDecreaseLineQtyEvent(line, e.target.value)
			}
		}, 700);
	},
	mounted() {
		this.cartStore = useCartStore()
		emitter.on('close-remove-line-modal', evt => {
			// they have canceled the removal, so if they set qty to 0 or less, reset it to 1
			if (evt.item === this.line) {
                if (this.line.qty <= 0 && evt.removedLine === false) {
                    this.line.qty = 1
                    this.updateLineItemQty(this.line, this.line.qty)
				}
				this.showRemoveModal = false
			}

			if(evt.removedLine) {
				// They have removed the line
				this.showLoadingOverlay()
			}
		})
		emitter.on('insufficient-stock', item => {
			this.insufficientStockCount = 0
			if (item.itemId === this.line.item_id) {
				this.insufficientStock = true
				this.insufficientStockCount = item.units
			}
		})
		emitter.on('sufficient-stock', item => {
			if (item.itemId === this.line.item_id) {
				this.insufficientStock = false
			}
		})
		emitter.on('item-out-of-stock', item => {
			this.insufficientStockCount = 0
			// an item is out of stock, check to see if it's me
			if (this.cartStore?.getOutOfStockItems?.length > 0) {
				this.cartStore.getOutOfStockItems.map((item) => {   
					if(item === this.line.item_id) {
						this.outOfStock = true
						this.insufficientStockCount = 0
					}
				})
			}
			if (item.item_id === this.line.item_id) {
				this.outOfStock = true
			}
		})
		emitter.on('item-back-in-stock', item => {
			if(item.itemId === this.line.item_id) {
				this.outOfStock = false
			}
		})
	},
}