<template>
	<div :id="'line-item-${line.item_id}'">
		<div class="col-xs-3 mc-img-contain">
			<a :href="`/product/${line.product_url_id}`">
				<img :alt="line.product_name" :src="`/dyn-images/cart/${line.product_image}`" class="mc-img">
			</a>
			<div class="text-center m-t-10">
				<a href="javascript:;" class="sub-delete-btn" @click="removeLine(line)">
					<i class="fa fa-times-circle" style="font-size:21px; cursor: pointer" aria-hidden="true" />
					<p
						class="sub-delete-text product-change color-tertiary"
						style="margin-top: -12px"
					>Remove</p>
				</a>
			</div>
		</div>

		<!--  /////  Mobile Line Details : xs, sm  ///// -->
		<div class="col-xs-9 mc-items">
			<div class="row">
				<span class="font-alt1 color-theme-dark h-uppercase bold" v-html="line.product_name"></span>
				<span v-if="!line.is_club" class="font-alt1 font-md">
					<SkuChooser v-if="line.num_of_siblings > 1" :id="'sku-mobile-chooser-' + line.clin_id" :line="line" :page="page" :recipient-id="recipientId" />
				</span>
				<span v-if="line.is_club" class="text-nowrap">
					<ClubChooser page="cart" :line="line" :item="line.item_id" :recipient-id="recipientId" />
				</span>

				<div class="color-theme-dark font-alt1">Item: #{{ line.item_id }}</div>

				<p class="color-theme-dark font-alt1">
					<span class="mc-label transform-uppercase">Price:</span>&nbsp;
					<span v-if="line.display_totals.strike_price" class="strike-color-grey strike h-font-size-xsmall">
						{{ currency(line.display_totals.strike_price) }}&nbsp;
					</span>
					<span class="bold"> {{ currency(line.display_totals.display_price) }}</span>
				</p>

				<AutoDelivery element="line-item" :line-id="line.clin_id" :recipient-id="recipientId"/>

				<p v-if="line.cart_type != 'G' && !line.is_club" class="mc-amount mc-qty-cnt">
					<span class="color-theme-dark font-alt1">
						QTY:
					</span> &nbsp; 
					<input :value="line.qty" type="number" class="cart-quantity" :formatter="checkQty(line.qty)" @change="onChangeDebounced(line, $event)">
				</p>
				<p v-if="insufficientStock && !outOfStock" class="qty-message" style="margin-top: -10px">
					Sorry, the quantity you selected exceeds our on-hand availability.
				</p>

				<p class="color-theme-dark font-alt1">
					<span class="mc-label transform-uppercase">Subtotal:</span> &nbsp;
					<span v-if="line.display_totals.strike_price > 0" class="strike-color-grey h-font-size-xsmall strike m-r-5">
						{{ currency(calculateSubTotal(line.qty, line.display_totals.strike_price)) }}&nbsp;
					</span>
					<span class="bold">
						{{ currency(calculateSubTotal(line.qty, line.display_totals.display_price)) }}
					</span>
					<br class="clear">
				</p>
				<p v-if="line.deliveryByXmas == false" class="color-error">
					This item may not arrive before Christmas.
					<a class="color-error xmas-link" href="https://www.kansascitysteaks.com/guaranteed-delivery">
						Shop Guaranteed Items.
					</a>
				</p>
				<p v-if="line.deliveryByXmas == true" class="color-success">
					This item is Guaranteed to arrive before Christmas
				</p>
				<p v-if="outOfStock" class="color-error h-font-size-small" style="line-height: 1">
					This item is not currently available. Please remove it before continuing.
				</p>
			</div>
		</div>

		<div v-for="sub in line.sublines" :key="sub.item_id" class="row" style="font-size: 12px; padding: 15px">
			<div class="col-xs-12">
				<table style="width: 100%">
					<tr>
						<td style="width: 55%">
							<strong class="text-danger">
								SELECTED ADD-ON:
							</strong>
						</td>
						<td style="text-align: right">
							<span v-if="sub.cart_type != 'G' && !sub.is_club">
								<a href="" class="qty-changer" style="padding-right: 15px">
									<i class="fa fa-minus-circle" aria-hidden="true"></i>
								</a>
								{{ sub.qty }}
								<a href="" class="qty-changer" style="padding-left: 15px">
									<i class="fa fa-plus-circle" aria-hidden="true"></i>
								</a>
							</span>
						</td>
					</tr>
					<tr>
						<td style="width: 55%">
							<b>
								<span>{{ sub.product_name }}</span>
							</b>
						</td>
						<td style="text-align: right; vertical-align: bottom">
							PRICE {{ currency(sub.price) }}
						</td>
					</tr>
					<tr>
						<td style="width: 55%">
							<a href="javascript:;" class="sub-delete-btn" @click="removeLine(line)">
								<i class="fa fa-times-circle" style="font-size:21px; cursor: pointer" aria-hidden="true"></i> Remove
							</a>
						</td>
						<td style="text-align: right">
							SUBTOTAL {{ currency(sub.price * sub.qty) }}
						</td>
					</tr>
				</table>
			</div>
		</div>
		<!-- remove modal -->
		<removeLineConfirm
			v-if="showRemoveModal"
			:item="line"
			:recipient-id="recipientId"
		/>
	</div>
</template>

<script>
import currencyFormatterMixin from '../../../mixins/currency-formatter-mixin.js'
import lineItemMixin from '../../../mixins/line-item-mixin.js'

export default {
	name: 'LineItemMobile',
	components: {},
	mixins: [
		lineItemMixin,
		currencyFormatterMixin,
	],
	props: {},
	data() {},
	computed: {},
	mounted() {},
	methods: {},
}
</script>