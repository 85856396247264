export default {
    methods: {
        currency(value) {
            value = 0 + value
            return Number(value).toLocaleString("en", {
                style: "currency",
                currency: "USD",
            })
        },
    }
}