<template>
	<div>
		<div class="row">
			<button
				class="cart-specials-toggle"
				@click="toggleCartSpecials"
			>
				Save {{ currency(combo.savings_amount) }}&mdash;In-Cart Specials
				<span
					class="fa fa-plus cart-specials-toggle__icon"
					:class="{'fa-minus': showCartSpecials}"
				/>
			</button>
		</div>

		<div class="row">
			<div class="col-xs-12">
				<div
					class="cart-specials"
					:class="{'is-active': showCartSpecials}"
				>
					<h2 class="cart-specials__heading">
						In-Cart Specials
					</h2>
					<div class="cart-specials__container">
						<div class="cart-specials__add-single">
							<div class="cart-specials__items">
								<div
									v-for="sku in skus"
									:key="sku.sku_id"
									class="cart-specials__item"
								>
									<div class="cart-specials__item-description">
										<img
											:src="image(sku.product_image)"
											class="cart-specials__item-image"
											:alt="sku.product_name"
										>

										<div class="cart-specials__item-name">
											{{ sku.sku_name }}
										</div>
									</div>

									<div class="cart-specials__item-details">
										<div class="cart-specials__item-name cart-specials__item-name--mobile">
											{{ sku.sku_name }}
										</div>

										<div class="cart-specials__item-price-container">
											<s class="cart-specials__item-price-strike">
												{{ currency(sku.fallback_price) }}
											</s>
											<span class="cart-specials__item-price">
												{{ currency(sku.price) }}
											</span>
											<span class="cart-specials__item-price-save">
												Save {{ currency(sku.fallback_price - sku.price) }}
											</span>
										</div>

										<button
											class="cart-specials__item-button button"
											:class="{'in-progress': sku.inProgress, 'is-added': sku.specialAdded}"
											:disabled="sku.buttonClicked"
											@click="addSpecialToCart(sku, false)"
										>
											<span class="cart-specials__item-button-add">Add</span>

											<span class="cart-specials__item-button-progress">
												<img
													src="/img/spinner.png"
													alt="loading"
													class="progressSpinner"
												>
											</span>

											<span class="cart-specials__item-button-added">
												<i class="fa fa-check cart-specials__item-icon" /> Added
											</span>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="cart-specials__add-group">
							<div class="cart-specials__add-group-inner">
								<h3 class="cart-specials__title">
									Add All Three
								</h3>

								<span class="cart-specials__save-amount">
									Save {{ currency(combo.savings_amount) }}
								</span>

								<div class="cart-specials__amounts-group">
									<ul class="cart-specials__amounts">
										<li class="cart-specials__amount">
											<s class="cart-specials__strike font-md">
												{{ currency(combo.org_price) }}
											</s>
										</li>

										<li class="cart-specials__amount cart-specials__amount--focus">
											Only {{ currency(combo.price) }}
										</li>
									</ul>

									<div class="cart-specials__amount--italic">
										Save {{ currency(combo.savings_amount) }}
									</div>
								</div>

								<button
									:class="{'in-progress': combo.inProgress, 'is-added': combo.specialAdded}"
									:disabled="combo.buttonClicked"
									class="button"
									style="width: 100%;"
									@click="addSpecialToCart(combo, true)"
								>
									<span class="cart-specials__item-button-add">Add All Three</span>

									<span class="cart-specials__item-button-progress">
										<img
											src="/img/spinner.png"
											alt="loading"
											class="progressSpinner"
										>
									</span>

									<span class="cart-specials__item-button-added">
										<i class="fa fa-check cart-specials__item-icon" /> Added
									</span>
								</button>
							</div>
						</div>
					</div>
					<button
						:class="{'in-progress': combo.inProgress, 'is-added': combo.specialAdded}"
						:disabled="combo.buttonClicked"
						class="cart-specials__button--mobile button mobile-only"
						style="width: 100%;"
						@click="addSpecialToCart(combo, true)"
					>
						<span class="cart-specials__item-button-add">Add All Three</span>

						<span class="cart-specials__item-button-progress">
							<img
								src="/img/spinner.png"
								alt="loading"
								class="progressSpinner"
							>
						</span>

						<span class="cart-specials__item-button-added">
							<i class="fa fa-check cart-specials__item-icon" /> Added
						</span>
					</button>
				</div>
			</div>
		</div>

		<Modal v-if="showMultipleRecipientsModal" :modal-width="370" modal-type="multiple-recipients-modal" @close-modal="closeModal">
			<template #body>
				<div class="cart-special-added-modal" style="padding: 15px;">
					<div class="modal-body">
						<div class="cart-special-added-modal-header">
							<strong>WHO SHOULD WE SHIP THIS TO?</strong>
						</div>
						<p>The following recipients in your cart qualify for special add-on pricing!</p>

						<div v-for="recipient in qualifyingRecipList" :key="recipient.crec_id">
							<div class="form-check">
								<input :id="recipient.crec_id" v-model="checked" class="form-check-input dynamic" type="checkbox" :value="recipient.addr_id">
								<label class="form-check-label" :for="recipient.crec_id">
									&nbsp;&nbsp;{{ showProperLabel(recipient) }}
								</label>
							</div>
						</div>

						<div
							v-if="qualifyingRecipList.length > 1"
							class="form-check"
						>
							<input
								id="all-recips"
								v-model="checked"
								class="form-check-input"
								type="checkbox"
								value="all-recips"
							>
							<label
								class="form-check-label"
								for="all-recips"
							>
								&nbsp;&nbsp;SHIP TO ALL
							</label>
						</div>

						<br>
						<div class="text-center">
							<button
								id="add-to-cart"
								class="button btn-red-cart-model text-center"
								@click="addToCart()"
							>
								Add To Cart
							</button>
						</div>
					</div>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from '../../modals/modal.vue'
import { mapState } from 'pinia'
import { emitter } from '../../../main'
import { useCartStore } from '../../../stores/CartStore'
import currencyFormatterMixin from '../../../mixins/currency-formatter-mixin'
import checkoutHelpers from '../../../mixins/checkout-global-functions'

export default {
	name: 'InCartSpecials',

	components: {
		Modal
	},

	mixins: [
		currencyFormatterMixin,checkoutHelpers
	],

	props: {
		combo: {
			type: Object,
			required: true,
		},
		skus: {
			type: Array,
			required: true,
		},
		singleSpecialModalPresented: {
			type: Boolean,
		}
	},

	data() {
		return {
			showCartSpecials: false,
			showMultipleRecipientsModal: false,
			qualifyingRecipList: [],
			checked: [],
			item: {},
		}
	},

	computed: {
		...mapState(useCartStore, {
			recipCount: 'recipCount',
			fullCart: 'fullCart',
		})
	},

	mounted() {
		this.cartStore = useCartStore()

		this.cartStore.trackCrossSellImpression(this.$constants.CROSS_SELL_IMPRESSION_IN_CART_TYPE)

		// Keep track of the recipients that qualify for in-cart specials
		this.buildQualifyingList()
		emitter.on('cart-updated', (evt) => {
			this.buildQualifyingList()
		})

		emitter.on('ship-to-addr-id-changed', (evt) => {
			this.buildQualifyingList()
		})
	},

	methods: {
		toggleCartSpecials() {
			this.showCartSpecials = !this.showCartSpecials
		},
		image(value) {
			return '/dyn-images/pdp_thumb/' + value
		},
		buildQualifyingList() {
			this.qualifyingRecipList = []
			// get the list of recipients qualifying for in-cart specials
			this.fullCart.recipients.map(recip => {
				let tempRecipTotal = 0
				recip.items.map(item => {
					tempRecipTotal += (item.price * item.qty)
				})
				if (tempRecipTotal > 59.95) {
					// we have enough for add on, use the first item
					this.qualifyingRecipList.push(recip)
				}
			})
		},
		addSpecialToCart(special, combo = false) {
			emitter.emit('set-single-special-modal-presented', false)
			if (this.recipCount > 1) {
				this.showMultipleRecipientsModal = true
				this.item.combo = combo
				this.item.special = special
			} else {
				this.showLoadingOverlay()
				this.cartStore = useCartStore()
				//-------------------------------
				// disabling these two statements as per Erik not wanting the button to have a spinner when adding an item
				// special.inProgress = true
				// special.buttonClicked = true
				//-------------------------------
				// add selected item to the cart
				// we only have one recipient at the moment, grab the first recipients addr_id
				const recipAddrId = this.fullCart.recipients[0].addr_id
				this.cartStore.addSpecialItem(special, recipAddrId, combo)
				emitter.on('single-special-added', evt => {
					special.specialAdded = false
					special.inProgress = false
					special.buttonClicked = false
				})
			}
		},
		addToCart() {
			this.showLoadingOverlay()
			let added = true
			let alert = false
			this.showMultipleRecipientsModal = false
			if (this.checked.length === 0) {
				// nothing checked just close
				return
			}
			// this.item.special.inProgress = true
			// this.item.special.buttonClicked = true
			const filtered = this.checked.filter(check => {
				return check === 'all-recips'
			})
			if (filtered.length > 0) {
				// adding item to all recipients - ignore the other checkmarks
				this.fullCart.recipients.map(recipient => {
					added = this.cartStore.addSpecialItem(this.item.special, recipient.addr_id, this.item.combo)
					if(!added) { alert = true }
				})
			} else {
				this.checked.map(check => {
					added = this.cartStore.addSpecialItem(this.item.special, check, this.item.combo)
					if(!added) { alert = true }
				})
			}
			if(alert) {
				alert("This item is only available with the purchase of perishable item(s) valued at $59.95 or more.  Please add another item to this shipment to reach the $59.95 minimum or select this item at its list price.");
			}

			this.checked = []
		},
		closeModal() {
			this.showMultipleRecipientsModal = false
		},
		showProperLabel(recip) {
			if(recip.is_myself === '1') {
				// this handles the 'Myself' label when they are logged in
				return 'Myself'
			}
			return typeof(recip.fname) !== 'undefined' ? (recip.fname ?? '') + ' ' + (recip.lname ?? '') : 'Myself'
		}
	}
};
</script>

<style scoped>
:deep(.kcs-modal) .button {
	width: 100%;
}
</style>