<template>
	<div :id="`line-item-${line.item_id}`">
		<div
			v-if="line.is_addon && !line.showAsParentItem"
			class="col-md-1"
		>
			&nbsp;
		</div>
		<!--  /////  Desktop Line Details : md, lg-m-t-12  ///// -->
		<div class="col-md-1">
			<a :href="`/product/${line.product_url_id}`">
				<img
					:src="`/dyn-images/cart/${line.product_image}`"
					class="img-responsive"
					:alt="line.product_name"
				>
			</a>
		</div>

		<div
			class="cart-line-detaild"
			:class="`${(line.is_addon && !line.showAsParentItem == 1) ? 'col-md-3' : 'col-md-4'}`"
		>
			<b>
				<a
					:href="`/product/${line.product_url_id}`"
					class="dark-link product-title"
				>
					<span v-html="line.product_name" />
				</a>
			</b>
			<br>

			<span v-if="!line.is_club">
				<SkuChooser v-if="line.num_of_siblings > 1" :id="'sku-chooser-' + line.clin_id" :line="line" :page="page" :recipient-id="recipientId" />
			</span>

			<span
				v-if="line.is_club"
				class="text-nowrap"
			>
				<ClubChooser
					page="cart"
					:line="line"
					:item="line.item_id"
					:recipient-id="recipientId"
				/>
			</span>

			<div class="color-theme-dark">
				Item: #{{ line.item_id }}
			</div>

			<AutoDelivery element="line-item" :line-id="line.clin_id" :recipient-id="recipientId" />

			<p v-if="line.deliveryByXmas === false" class="color-error" >
				This item may not arrive before Christmas.
				<a class="color-error xmas-link" href="https://www.kansascitysteaks.com/guaranteed-delivery" >
					Shop Guaranteed Items.
				</a>
			</p>
			<p v-if="line.deliveryByXmas === true" class="color-success" >
				This item is Guaranteed to arrive before Christmas
			</p>
			<p v-if="outOfStock" class="color-error h-font-size-small" style="line-height: 1">
				This item is not currently available. Please remove it before continuing.
			</p>
			<span v-if="!line.size_ounces && !line.unit_count" />
		</div>

		<div class="col-md-2 mc-attributes2 text-center color-theme-dark">
			<span v-if="line.display_totals.strike_price" class="strike-color-grey strike h-font-size-small m-r-5">
				{{ currency(line.display_totals.strike_price) }}
			</span>
			<span class="bold">
				{{ currency(line.display_totals.display_price) }}
			</span>
		</div>
		<div class="col-md-2 text-center">
			<div class="row">
				<div class="col-xs-12 mc-qty-cnt">
					<div v-if="line.cart_type !== 'G' && !line.is_club">
						QTY: &nbsp; 
						<input :value="line.qty" type="number" class="cart-quantity" :formatter="checkQty(line.qty)" @change="onChangeDebounced(line, $event)">
					</div>
					<div v-show="insufficientStock && !outOfStock" class="qty-message" style="width:160px;">
						We are very sorry, however the quantity you selected exceeds our on-hand availability.
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-1 text-center remove_parent">
			<a
				href="javascript:;"
				class="sub-delete-btn"
				@click="removeLine(line)"
			>
				<i
					class="fa fa-times-circle"
					aria-hidden="true"
				/>
				<p
					class="sub-delete-text product-change"
					style="margin-top: -12px"
				>
					Remove
				</p>
			</a>
		</div>
		<div class="col-md-2 mc-attributes2 text-right color-theme-dark">
			<span
				v-if="line.display_totals.strike_subtotal > 0"
				class="strike-color-grey strike h-font-size-small m-r-5"
			>
				{{ currency(calculateSubTotal(line.qty, line.display_totals.strike_price)) }}
			</span>
			<span class="bold">
				{{ currency(calculateSubTotal(line.qty, line.display_totals.display_price)) }}
			</span>
		</div>

		<div
			v-for="sub in line.sublines"
			:key="sub.item_id"
			class="row"
		>
			<div class="col-md-1 mc-img-contain" />

			<div class="col-md-3">
				<img
					class="pull-left"
					:src="`/dyn-images/cart/${sub.product_image}`"
					:alt="sub.product_name"
					style="width: 30%; padding: 0 10px 0 0;"
				>
				<strong class="text-danger">SELECTED ADD-ON:</strong>
				<br>
				<b><span>{{ sub.product_name }}</span></b>
				<br>
				Item: #{{ sub.item_id }}
			</div>
			<div class="col-md-2 mc-attributes2">
				{{ currency(sub.display_totals.display_price) }}
			</div>
			<div class="col-md-2">
				<div v-if="sub.cart_type != 'G' && !sub.is_club">
					QTY: &nbsp;
					<input :value="line.qty" type="number" class="cart-quantity" :formatter="checkQty(line.qty)" @change="onChangeDebounced(line, $event)">
				</div>
				<!-- <div v-if="outOfStock || insufficientStock" class="qty-message" style="width:160px;">
					We are very sorry, however the quantity you selected exceeds our on-hand availability. We currently have {{ insufficientStockCount }} left.
				</div> -->
			</div>
			<div class="col-md-2 mc-attributes2">
				{{ currency((sub.price * sub.qty)) }}
			</div>
			<div
				class="col-md-1 mc-attributes2"
				style="margin-left: -15px"
			>
				<a
					href="javascript:;"
					class="sub-delete-btn"
					click="removeLine(line)"
				>
					<i
						class="fa fa-times-circle"
						style="font-size:21px; cursor: pointer"
						aria-hidden="true"
					/>
				</a>
				<a
					href="javascript:;"
					class="sub-delete-btn"
					click="removeLine(line)"
				>
					<div class="delete-text">Remove</div>
				</a>
			</div>
		</div>
		<!-- remove modal -->
		<removeLineConfirm
			v-if="showRemoveModal"
			:item="line"
			:recipient-id="recipientId"
		/>
	</div>
</template>

<script>
import lineItemMixin from '../../../mixins/line-item-mixin.js'
import currencyFormatterMixin from '../../../mixins/currency-formatter-mixin.js'

export default {
	name: 'LineItem',
	components: {},
	mixins: [
		lineItemMixin,
		currencyFormatterMixin
	],
	props: {},
	data() {},
	computed: {},
	mounted() {},
	methods: {},
}
</script>