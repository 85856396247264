<template>
	<div id="vue-password-input">
		<div class="form-group" :class="{ 'has-error': shouldShowErrors }">
			<slot name="label">
				<label>{{ label }}</label>
			</slot>
	
			<input v-model="computedPassword" :type="showPassword ? 'text' : 'password'" class="form-control" v-bind="inputProps" @change="$emit('change', $event)" :placeholder="placeholderText">

			<slot name="error" v-bind="{ errorMessage }">
				<div v-if="shouldShowErrors" style="display:inline-block" class="sg-input-advice">{{ errorMessage }}</div>
			</slot>
		</div>

		<div v-if="showRequirements" class="password-requirements">
			<div>Password Requirements</div>
			<div class="password-requirements-list">
				<ul>
					<li :class="{ 'is-valid': hasMinLength }">At least 8 characters</li>
					<li :class="{ 'is-valid': hasUppercase && hasLowercase }">At least 1 uppercase and 1 lowercase letter</li>
					<li :class="{ 'is-valid': hasNumber }">At least 1 number</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import checkoutHelpersMixin from '../../mixins/checkout-global-functions.js'

export default {
	mixins: [checkoutHelpersMixin],

	props: {
		label: {
			type: String,
			default: 'Password',
		},

		showRequirements: {
			type: Boolean,
			default: false,
		},

		showPassword: {
			type: Boolean,
			default: false,
		},

		showErrors: {
			type: Boolean,
			default: true,
		},

		modelValue: {
			type: String,
			required: true,
			default: '',
		},

		inputProps: {
			type: Object,
			default: () => ({})
		},

		placeholderText: {
			type: String,
			required: false,
		}
	},

	emits: ['update:model-value', 'valid', 'invalid', 'change'],

	data() {
		return {
			password: '',
			touched: false,
		}
	},

	computed: {
		errorMessage() {
			if (this.touched && this.password.length) {
				if (!this.hasMinLength) 	return 'Your password must be at least 8 characters long.'
				if (!this.hasNumber) 		return 'Your password must have at least 1 number character.'
				if (!this.hasLowercase) 	return 'Your password must have at least 1 lowercase character.'
				if (!this.hasUppercase) 	return 'Your password must have at least 1 uppercase character.'
				if (!this.passwordIsValid) 	return 'Password is invalid'
			}

			return ''
		},

		passwordIsValid() {
			return this.validatePassword(this.computedPassword)
		},

		hasMinLength() {
			return this.computedPassword.length >= 8
		},

		hasUppercase() {
			return /[A-Z]/.test(this.computedPassword)
		},

		hasLowercase() {
			return /[a-z]/.test(this.computedPassword)
		},

		hasNumber() {
			return /[0-9]/.test(this.computedPassword)
		},

		shouldShowErrors() {
			return this.showErrors && this.errorMessage
		},

		computedPassword: {
			get() {
				return this.password
			},
			set(value) {
				this.password = value
				this.touched = true

				this.$emit('update:model-value', value)
			}
		},

	},

	watch: {
		passwordIsValid(value) {
			if (value) {
				this.$emit('valid', this.computedPassword)
			} else {
				this.$emit('invalid', this.computedPassword)
			}
		}
	},
}
</script>

<style scoped>
.has-error .form-control {
    border-color: #AD0B21 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
}

.has-error .form-control:focus {
    border-color: #843534 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
}

.has-error .input-group-addon {
    color: #AD0B21 !important;
    border-color: #AD0B21 !important;
    background-color: #f2dede !important;
}

.has-error .sg-input-advice {
    color: #AD0B21 !important;
}

.password-requirements-list ul {
    li {
        list-style-type: initial;
		margin-bottom: 4px;
		font-size: 14px;
		margin-right: 10px;
    }

    li:before {
        content: none;
    }

    li.is-valid {
        list-style-type: none;
    }

    li.is-valid:before {
        font-family: 'FontAwesome';
	content: '\f00c';
	margin-left: -15px;
	color: #4F9400;
    }
}
</style>