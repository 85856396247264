<template>
	<div>
		<div class="arrow">
			<select v-if="addresses.length" v-model="selectedUUID" class="standard pad-left" aria-label="select shipping destination" @change="selectorChange()">
				<option v-for="(option, key) in recipientSelectOptions" :key="key" :value="option.uuid">{{ option.selector_label }}</option>
				<option value="someone_else">Someone Else</option>
				<option v-if="!isLoggedIn" value="login">Use my Address Book - Log In</option>
			</select>
		</div>

		<Modal v-if="showSomeoneElseModal" class="ship-to-modal" :modal-width="370" modal-type="ship-to-modal" @close-modal="toggleCloseModal">
			<template #body>
				<div style="padding: 16px">
					<div class="title">
						SHIP TO
					</div>
					<div
						id="new-name"
						class="new_name"
					>
						<span>NAME</span>
						<input ref="newRecipNameInput" v-model="newName" aria-labelledby="new-name" type="text" placeholder="Enter Name" style="color: black" maxlength="30">
					</div>
					<span v-if="shipError" class="error" style="font-size:16px">First and Last Names Required</span>
					<p :class="{ error: modalError }">{{ modalMessage }}</p>
					<button class="button ship-to-button" @click="newNameSubmit()">{{ buttonLabel }}</button>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from '../modals/modal.vue'
import { mapState } from 'pinia'
import { emitter } from '../../main.js'
import { useUserStore } from '../../stores/UserStore'
import { useCartStore } from '../../stores/CartStore.js'

export default {
	components: {
		Modal
	},

	props: {
		crecId: {
			type: String,
			required: true
		},
		addrId: {
			type: String,
			default: '',
		},
		useShipToMyselfLabel: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			selectedUUID: '',
			buttonLabel: 'Continue',
			showSomeoneElseModal: false,
			modalMessage: 'You can add address and greeting information during checkout.',
			modalError: false,
			newName: '',
			isLoggedIn: false,
			shipError: false,
		}
	},
	computed: {
		...mapState(useCartStore, {
			addresses: 'getSavedAddresses'
		}),

		recipientSelectOptions() {
			return this.addresses.map((address) => {
				if (parseInt(address.is_myself) === 1) {
					address.selector_label = `${this.useShipToMyselfLabel ? 'Ship to ' : ''}Myself`
				}

				return address
			})
		}
	},
	watch: {
		addrId() {
			this.setSelectedUUID()
		}
	},
	mounted() {
		if(this.page != 'checkout') {
			// this.buttonLabel = 'Add To Cart';
		}

		this.cartStore = useCartStore()

		// Is the user logged in?
		this.userStore = useUserStore()
		this.isLoggedIn = this.userStore.getIsLoggedIn

		this.setSelectedUUID()

		emitter.on('ship-to-addr-id-changed', (evt) => {
			if(evt.is_new && evt.crec_id == this.crecId) {
				this.selectedUUID = evt.uuid;
				this.showSomeoneElseModal = false;
				this.newName = ''
			}
		})

		emitter.on('ship-to-someone-else-error', (evt) => {
			// handle error
			this.modalMessage = "An error occurred.  Please try again."
			this.modalError = true
		})
	},
	methods: {
		selectorChange() {
			if(this.selectedUUID == 'someone_else') {
				this.showSomeoneElseModal = true
				setTimeout(() => {
					$('.ship_to_modal:visible').find('input').focus()
				}, 100)
			} else if (this.selectedUUID === 'login') {
				this.triggerLogin()
			} else {
				if(this.selectedUUID) {
					emitter.emit('ship-to-addr-id-changed', {uuid: this.selectedUUID, crec_id: this.crecId, is_new: false})
				}
			}
		},
		toggleCloseModal() {
			// Close modal without entering new name
			this.showSomeoneElseModal = false
			this.setSelectedUUID()
		},
		newNameSubmit() {
			this.newName = this.newName.trim()
			if (this.newName.trim() == '') {
				// alert("Please enter a recipient name.");
				this.shipError = true
			} else {
				let first = ''
				let last = ''
				let parts = []

				if(this.newName.indexOf(' ') == -1) {
					// There were no spaces in the name
					first = this.newName;
					last = '';
					this.shipError = true
					return
				} else {
					// There are spaces in the user input
					// split the string into array of parts
					parts = this.newName.split(" ");
					// IF there are 3 or more PARTS
					if (parts.length >= 3) {
						var match = null;
						var suffixes = [
							'OD',
							'O.D.',
							'JR',
							'JR.',
							'MD',
							'M.D.',
							'PE',
							'P.E.',
							'SR',
							'SR.',
							'II',
							'III',
							'IV',
							'LLC',
							'INC',
							'CMF',
							'PHD',
							'P.H.D.',
							'MS',
							'CPA',
							'BS',
							'MBA',
							'ESQ',
							'ESQ.',
							'Family',
							'& Family',
							'PH.',
							'D'
						];

						// See if the last part matches any of the suffixes
						for (var i = 0; i < suffixes.length; i++) {
							var numberOfSpaces = suffixes[i].split(' ').length - 1;
							var lastNameStartIndex = (parts.length - (2 + numberOfSpaces));
							var nameStringToTest = parts.slice(lastNameStartIndex + 1).join(' ');
							if (nameStringToTest.toLowerCase() == suffixes[i].toLowerCase()) {
								match = {
									string: suffixes[i],
									numberofSpaces: numberOfSpaces,
									lastNameStartIndex: lastNameStartIndex,
								};
								break;
							}
						}

						// The last part DOES match one of the suffixes
						if (match) {
							last = parts.slice(match.lastNameStartIndex).join(' ');
							first = parts.slice(0, match.lastNameStartIndex).join(' ');
						}
						else { // The last part does not match any of the suffixes
							// In this case the last name is ONLY the last part
							// and the first name is all other parts put back together
							last = parts.pop();
							first = parts.join(' ');
						}

						// Now ensure that neither first nor last exceeds the 15 character limit
						// Start with just the first name
						while(first.length > 15 && first.indexOf(' ') != -1) {
							// The first name is too long and there is a space
							// Push the last word onto the last name and then check the last name
							var fname_parts = first.split(" ");
							var move_part = fname_parts.pop();

							// Now constitue the two names
							first = fname_parts.join(' ');
							last = move_part + " " + last;

						}

					} else {
						// There are fewer than 3 Parts
						first = parts[0];
						last = parts[1];
					}
				}

				// Reset the parts
				parts = [];

				// Assign the resulting split first and last names
				// AND limit both parts to 15 char max length
				first = first.substring(0,15);
				last = last.substring(0,15);

				this.cartStore.createNewAddressRecord(first, last, this.crecId)
			}
		},
		triggerLogin() {
			const loginButton = document.getElementById('my-account-login-modal')
				
			if (loginButton) {
				loginButton.click()
			} else {
				window.location.href = '/checkout/login'
			}
		},
		setSelectedUUID() {
			const address = this.addresses?.find((address) => address.addr_id === this.addrId)

			if (address) {
				this.selectedUUID = address.uuid
			} else if (!this.addrId) {
				const guestMyselfAddress = this.addresses?.find((address) => !address.addr_id)

				if (guestMyselfAddress) {
					this.selectedUUID = guestMyselfAddress.uuid
				}
			}
		}
	}
};
</script>

<style>
.ship-to-modal {
	.title {
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 500;
		margin: 5px 0 10px;
		padding-bottom: 15px;
		border-bottom: 1px solid var(--color-text-default);
	}

	.new_name {
		span,
		label {
			font-size: 15px;
			font-weight: 700;
			margin-bottom: 5px;
		}
	}

	p {
		margin: 5px 0;
	}

	.button {
		width: 100%;
		margin-top: 10px;
	}
}
</style>
