import md5 from 'md5'

function generateAddressFingerprint(address) {
    let fingerprintString = '';
    let fingerprint = '';

    if(address && address.fname && address.fname.length) {
        fingerprintString = address.fname.toLowerCase()
        fingerprintString += ( address.lname && address.lname.length ) ? address.lname.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.address_1 && address.address_1.length ) ? address.address_1.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.address_2 && address.address_2.length ) ? address.address_2.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.company && address.company.length ) ? address.company.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.city && address.city.length ) ? address.city.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.state && address.state.length ) ? address.state.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.postal_code && address.postal_code.length ) ? address.postal_code.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.email && address.email.length ) ? address.email.toLowerCase().replace(' ', '') : ''
        fingerprintString += ( address.phone && address.phone.length ) ? address.phone.toLowerCase().replace(' ', '') : ''

        if(fingerprintString.length) {
            fingerprint = md5(fingerprintString)
        }
    }

    return fingerprint
}

function debounce(fn, wait){
    let timer;
    return function(...args){
        if(timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(()=>{
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}

export default {
    install(app) {
        app.config.globalProperties.$utilities = {
            generateAddressFingerprint,
            debounce
        }
    }
}