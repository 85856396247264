<template>
	<div
		:class="{
			'overlay-loader': true,
			visible,
		}"
	>
		<div class="inner">
			<div class="overlay" />
			<div class="loader">
				<div class="inner">
					<div class="sk-three-bounce">
						<div class="sk-child sk-bounce1" />
						<div class="sk-child sk-bounce2" />
						<div class="sk-child sk-bounce3" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			default: false,
		}
	}
}
</script>

<style scoped>
.overlay-loader {
    display: none;
    position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
    z-index: 100000;
    width: 100%;
    height: 100%;

    &.visible {
        display: block;
    }
}
</style>