<template>
	<Modal v-bind="$props" modal-type="ad-info" :modal-width="460" @close-modal="closeModal">
		<template #header>
			<div class="auto-delivery-info-image" style="width: 100%" />
		</template>

		<template #body>
			<div style="padding: 15px" class="auto-delivery-info-modal">
				<div class="auto-delivery-info-header" style="margin-top: 0px; margin-bottom: 0px">
					AUTO DELIVERY:
				</div>
				<div class="auto-delivery-info-header" style="margin: 5px 0px 20px">
					The Smart Choice for Food Lovers
				</div>
				<p>Enjoy the convenience of automatic delivery without the hassle of reordering.</p>
				<div class="auto-delivery-info-header">
					Why Choose Auto Delivery
				</div>
	
				<div class="row">
					<div class="col-xs-12 col-md-6" style="padding: 0px 10px 0px 15px">
						<ul>
							<li><span>Free shipping</span></li>
							<li><span>Option to receive shipments every 4, 8, 12, or 16 weeks</span></li>
						</ul>
					</div>
					<div class="col-xs-12 col-md-6" style="padding:  0px 10px 0px 15px">
						<ul>
							<li><span>Manage and even pause shipments</span></li>
							<li><span>Cancel at any time</span></li>
						</ul>
					</div>
				</div>

				<slot name="extra" v-bind="{ closeModal }">
					<div style="padding-top: 15px">
						<button
							id="cancel"
							class="button full"
							@click="closeModal"
						>
							Close
						</button>
					</div>
				</slot>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from './modal.vue'

export default {
	components: {
		Modal,
	},
    
	emits: ['close-modal'],

	methods: {
		closeModal(event) {
			this.$emit('close-modal', event)
		}
	}
}
</script>

<style scoped>
.row {
    margin-left: -15px;
    margin-right: -15px;
}
</style>