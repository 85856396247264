<template>
	<div
		class="row m-b-15"
		:class="{'bg-color-alt': hasRecipients}"
	>
		<div
			v-if="items?.length > 0"
			class="recently-viewed col-xs-12 m-t-10"
		>
			<div class="text-center">
				<h3 class="font-alt1 h-uppercase m-b-20 h-size-h4">
					Recently Viewed
				</h3>
			</div>
			<div class="product_tile_container">
				<div
					v-for="(item, index) in items"
					:key="index"
					class="col-xs-6 col-md-3 product_tile"
					:class="{'end': (index % 2)}"
				>
					<a :href="`/product/${item.url}`">
						<img
							:src="`/dyn-images/double_double_280_tile/${item.image}`"
							class="img-responsive"
						>
					</a>
					<div class="product-info">
						<a :href="`/product/${item.url}`">
							<p
								class="font-alt2 h-uppercase h-weight-bold m-t-10"
								html="item.name"
							/>
						</a>
						<p>Starting at <strong>${{ item.starting_at }}</strong></p>
						<div class="ratings">
							<i
								class="fa fa-star color-star"
								aria-hidden="true"
							/>
							<i
								class="fa fa-star color-star"
								aria-hidden="true"
							/>
							<i
								class="fa fa-star color-star"
								aria-hidden="true"
							/>
							<i
								class="fa fa-star color-star"
								aria-hidden="true"
							/>
							<i
								class="fa fa-star color-greyish"
								aria-hidden="true"
							/>
							<a href="">(6)</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../../main'
import { useCartStore } from '../../../stores/CartStore'

export default {
	name: 'RecentlyViewedItems',
	props: {
		items: {
			type: Array,
			required: true,
		}
	},
	data() {
		return {
			hasRecipients: false
		}
	},
	mounted() {
		this.cartStore = useCartStore()
		this.checkRecipients()
	},
	methods: {
		checkRecipients() {
			this.cart = this.cartStore.fullCart
			this.recipients = this.cart.recipients
			if(this.recipients?.length > 0) {
				this.hasRecipients = true
			}
		}
	},
}
</script>