<template>
	<div>
		<CheckoutSteps page="additional-shipments" :needs-additional-shipments-step="clubItemRecipients.length > 0"/>

		<div class="master-shipping-panel">
			<div class="col-md-11 mc-title m-b-20">
				<h3 class="font-alt1 h-uppercase h-weight-light h-size-h2">
					Additional Shipments
				</h3>
			</div>
			<div class="col-xs-12 col-md-8">
				<form v-for="line in lineitems" class="sg-form form-bg">
					<div class="row">
						<div class="col-xs-4">
							<img
								style="width: 100%"
								:src="'/dyn-images/cart/'+line.recurring_orders[0].lineitems[0].product_image"
								:alt="cleanHtml(line.recurring_orders[0].lineitems[0].sku_name)"
							>
						</div>
						<div class="col-xs-8">
							<h4 class="font-alt1">
								Keep Sending:<br>
								<span>{{ cleanHtml(line.recurring_orders[0].lineitems[0].sku_name) }}</span>
							</h4>

							<div>Shipping to:</div>
							<div>{{ line.address.fname + " " +line.address.lname }}</div>
							<div v-if="line.address.company">
								{{ line.address.address_1 }}
							</div>
							<div>{{ line.address.address_1 }}</div>
							<div>{{ line.address.city + ", " + line.address.state + " " + line.address.postal_code }}</div>
						</div>
					</div>
					<div class="clear"></div>

					<h4 class="m-center m-t-30" style="padding-bottom: 0;">Shipments Detail</h4>
					<div v-for="(recurring_order, index1) in line.recurring_orders" :key="index1" class="m-t-30">
						<h4 class="h-text-center">Shipment {{ index1 + 1 }}</h4>
						<div class="row">
							<div class="col-md-4" ng-class="{'has-error': lineValError(line,'ship_date') }">
								<div v-if="index1 == 0" class="m-center">
									<label class="font-alt1 h-uppercase m-l-0">Delivered By:</label>
									<br>
									{{ formatDate(line.shipping.delivery_date, "MMM D, YYYY", 1) }}
								</div>
								<div v-if="index1 > 0" class="m-center">
									<label class="font-alt1 m-l-0">Delivered By:</label>
									<br>
									{{ formatDate(recurring_order.delivery_date, "MMM D, YYYY", 1) }}
									<br>
									<ShippingCalendar :recipient="getRecipientForLineItem(line)" mode="additional-shipments" :recurring-order="recurring_order" :clin-id="line.clin_id" />
								</div>
							</div>
							<div class="col-md-8">
								<div class="mc-repeat-order">
									<label class="font-alt1 h-uppercase">Order Items</label>
								</div>

								<div v-for="(recurringLine, index2) in recurring_order.lineitems" :key="index2">
									<img class="pull-left" :src="'/dyn-images/cart/' + recurringLine.product_image" style="width: 20%; padding: 0 10px;">
									<b class="font-alt2 color-theme-dark font-md">{{ cleanHtml(recurringLine.sku_name) }}</b>
								</div>
							</div>
						</div>
					</div>

					<div v-if="line.subscription.subscription_type != 'club_plan'">
						<h4 class="h-text-center m-t-30">
							Future Shipments
						</h4>
						<p class="color-gray font-alt1 h-font-italic h-size-h5 h-text-center">
							<span v-if="line.detail.perpetual_subscription.interval_months == 1">Additional Shipments Every Month</span>
							<span v-if="line.detail.perpetual_subscription.interval_months > 1">Additional Shipments Every {{ line.detail.perpetual_subscription.interval_months * 4 }} Weeks</span>
						</p>
					</div>
				</form>
			</div>

			<div id="order-sum-outer">
				<div class="col-md-4 order-sum-contain">
					<CartSummary page="shipping" />
				</div>
			</div>
			<br class="clear">
			<CheckoutNavigation page="additional-shipments" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import formatDateMixin from '../../../mixins/date-formatter-mixin.js'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
const axios = require('axios')
import { useCartStore } from '../../../stores/CartStore'
import ShippingCalendar from '../../global/global-shipping-calendar.vue'
import CartSummary from '../cart-summary.vue'
import CheckoutNavigation from '../checkout-navigation.vue'
import CheckoutSteps from '../checkout-steps.vue'

export default {
	components: {
		ShippingCalendar,
		CartSummary,
		CheckoutNavigation,
		CheckoutSteps
	},
	mixins: [
		formatDateMixin,
		checkoutHelpers
	],
	props: [],
	data() {
		return {
			lineitems: [],
			intervalMonths: [
				{ value: 1, label: 'Every Month' },
				{ value: 2, label: 'Every 2 Months' },
				{ value: 3, label: 'Every 3 Months' },
				{ value: 4, label: 'Every 4 Months' }
			]
		}
	},
	computed: {
		...mapState(useCartStore, {
			cart: 'fullCart',
			clubItemRecipients: 'clubItemRecipients',
		})
	},
	created(){},
	mounted() {
		// Show reduced header while in checkout
		this.showShortHeader()

		axios.get('/api/checkout/recurring-order')
			.then(response => {
				this.regenLineitems(response.data.recurring_order_lineitems)
			})

		emitter.on('additional-shipment-date-change', (evt) => {
			let updateLine = null
			for(var i = 0; i < this.lineitems.length; i++) {
				if(this.lineitems[i].clin_id == evt.clinId) {
					updateLine = this.lineitems[i]
				}
			}

			// Insert the new date selected
			for(var i = 0; i < updateLine.recurring_orders.length; i++) {
				if(updateLine.recurring_orders[i].renewal_seq == evt.recurring_order.renewal_seq)  {
					updateLine.recurring_orders[i].delivery_date = evt.clicked_day
				}
			}

			if(updateLine) {
				axios.put('/api/checkout/recurring-order/'+ evt.clinId, { line: updateLine })
					.then(response => {
						this.regenLineitems(response.data.recurring_order_lineitems)
					})
			}
		})
	},
	methods: {
		cleanHtml(str) {
			if(!str || str.length == 0) { return str }
			var stripedHtml = str.replace(/<[^>]+>/g, "");
			var fixed_str = stripedHtml.replace("&nbsp;", " ");
			fixed_str = fixed_str.replace(/&.*;/, "");
			return fixed_str;
		},
		forceFriday(date) {
			var mydate = new Date(date);
			var dow = mydate.getDay();
			var fridayDow = dow + ( 5 - dow );
			var diff = fridayDow - dow;
			mydate.setDate(mydate.getDate() + diff);
			return mydate;
		},
		regenLineitems(lines) {
			this.lineitems = [];
			for (var i = 0 ; i < lines.length ; i++ ) {
				var lineitem = lines[i];
	
				recips : for (var ii = 0 ; ii < this.cart.recipients.length ; ii++ ) {
					var recipient = this.cart.recipients[ii];
	
					lineitem.shipping = recipient.shipping;
	
					lineitem.address = {};
					let cols = 'fname lname company email address_1 address_2 city state postal_code country phone'.split(/ /);
					for(var j = 0; j < cols.length; j++) {
						lineitem.address[cols[j]] = typeof recipient[cols[j]] == 'undefined' ? null : recipient[cols[j]];
					}
	
					for ( var iii = 0 ; iii < recipient.items.length ; iii++ ) {
						if ( recipient.items[iii].clin_id != lineitem.clin_id ) { continue; }
						var cartLineitem = recipient.items[iii];
	
						for (var key in cartLineitem) {
							if (key == 'detail') { continue; }
							lineitem[key] = cartLineitem[key];
						}
						break recips;
					}
				}
	
				for (var ii = 0 ; ii < lineitem.recurring_orders.length ; ii++ ) {
					var recurringOrder = lineitem.recurring_orders[ii];
					recurringOrder.freeShipFlag = true;
	
					///  Set min/max dates for date picker
					if ( typeof recurringOrder.original_delivery_date != 'undefined' ) {
						recurringOrder.min_delivery_date = new Date(new Date(recurringOrder.original_delivery_date).getTime() - (86400 * 10 * 1000));
						recurringOrder.max_delivery_date = new Date(new Date(recurringOrder.original_delivery_date).getTime() + (86400 * 10 * 1000));
					}
				}
	
				this.lineitems.push(lineitem);
			}
		},

		getRecipientForLineItem(lineItem) {
			return this.cart.recipients.find(({ items }) => items.find((item) => item.clin_id === lineItem.clin_id))
		}
	}
};
</script>
