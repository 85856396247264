<template>
	<div class="input-code">
		<div v-if="type === 'discount_code'">
			<!-- promo code form -->
			<div class="row input-control">
				<div class="col-xs-8">
					<a
						class="pull-left link"
						style=""
						@click="toggleInput"
					>Apply Promo Code</a>
				</div>
				<div class="col-xs-2" />
				<div class="col-xs-2">
					<i
						v-if="showInput"
						class="fa fa-times-thin fa-2x pull-right"
						style="cursor: pointer;"
						@click="toggleInput"
					/>
				</div>
			</div>
			<div
				v-if="showInput"
				class="form-group animationIf m-t-20"
			>
				<div class="">
					<div
						v-if="title != 'blank'"
						class="promo-label"
					>
						<label class="h-uppercase font-alt1">{{ title }}</label>
					</div>
					<div class="code-input-container" :class="{'code-input-right': alignment == 'right', 'm-l-0': title == 'blank'}">
						<input :id="code_input_id" v-model="code" type="text" class="promo-code-input" :tabindex="tabIndex" style="max-width: 70%;" @keypress="validChars" @keyup.enter="applyCode">
						<button class="button button-primary shrink pull-right" style="cursor:pointer;" :tabindex="tabIndex" @click="applyCode">
							Apply
						</button>
					</div>
				</div>
				<div class="font-sm code-message m-t-10">
					<div v-if="giftCardSuccessMessage">
						<div
							class="h-uppercase color-success font-alt1"
							style="font-size: 20px"
						>
							{{ giftCardSuccessMessage }}
						</div>
						<div
							class="font-alt1"
							style="font-size: 16px"
						>
							<span>{{ giftCardSuccessHeader }}</span>
						</div>
					</div>
					<div v-if="successMessage">
						<div
							style="font-size: 12px; font-weight: bold; text-transform: uppercase;"
							class="color-success font-alt1"
						>
							Promo Code Accepted
						</div>
						<div
							class="h-uppercase color-success font-alt1"
							style="font-size: 20px"
						>
							{{ successMessage }}
						</div>
						<div
							style="font-size: 12px; font-weight: bold; text-transform: uppercase;"
							class="color-success font-alt1"
						>
							Excludes Gift Cards And Certificates
						</div>
						<div style="font-size: 12px; font-weight: bold; text-transform: uppercase;" class="color-success font-alt1">
							Cannot be combined with other offers
						</div>
						<div class="font-alt1" style="font-size: 16px">
							<span v-html="successHeader" />
						</div>
					</div>
					<div
						v-if="loading"
						class="messages"
					>
						<i
							class="fa fa-spinner fa-spin fa-fw"
							aria-hidden="true"
						/>
					</div>
					<div
						v-if="invalidMessage && !loading"
						class="messages"
					>
						<div
							style="font-weight: 900;"
							class="h-uppercase color-primary h-size-h5"
						>
							SORRY
						</div>
						<div class="font-md">
							{{ invalidMessage }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { emitter } from '../../main'
import { usePromoCodeStore } from '../../stores/PromoCodeStore'
import checkoutHelpers from '../../mixins/checkout-global-functions'

export default {
	name: 'PromoCode',
	components: {},
	mixins: [
		checkoutHelpers,
	],
	props: {
		type: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			required: false,
			default: 'blank'
		},
		alignment: {
			type: String,
			required: false,
			default: 'left'
		},
		tabIndex: {
			type: Number,
			required: false,
			default: 1
		},
		cart: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			code: '',
			oldCode: '',
			appliedCode: {},
			showInput: false,
			code_input_id: Math.random().toString(36).slice(2),
			invalidMessage: '',
			successMessage: '',
			successHeader: '',
			giftCardSuccessMessage: '',
			giftCardSuccessHeader: '',
			giftCardAmount: '',
			loading: false,
			buttonLoading: false,
		}
	},
	computed: {
		...mapState(usePromoCodeStore, {
			currentCode: 'getCode',
		})
	},
	watch: {
		currentCode(value) {
			this.showPromoCode()
		}
	},
	mounted() {
		this.promoCode = usePromoCodeStore()

		if(this.getPage() == 'billing' && !this.code) {
			this.showInput = false
		}
		emitter.on('promo-code-valid', (evt) => {
			this.showPromoCode()
			// don't hide the LoadingOverlay here
			// there is some cart calculations going on here
			// it will be hidden when calculations are done
		})
		emitter.on('promo-code-expired', (evt) => {
			this.invalidMessage = this.promoCode.getInvalidMessage
			this.hideLoadingOverlay()
		})
		emitter.on('removedOldPromoCode', (evt) => {
			if(evt.toUpperCase() === this.code.toUpperCase()) {
				// same code, just show success message
				this.successHeader = this.promoCode.getSuccessMessage
			} else {
				this.successHeader = 'Promo code <strong>' + evt + '</strong> has been removed'
			}
		})

		emitter.on('promo-code-invalid', (evt) => {
			let page = this.getPage()
			this.promoCode.checkGiftCard(this.code, page)
				.then((response) => {
					this.loading = false
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
				.finally(() => {
					this.hideLoadingOverlay()
				})
		})
		emitter.on('gc-code-success', (evt) => {
			this.giftCardAmount = evt
			this.giftCardSuccessMessage = 'Your Gift Card for $' + evt + ' has been applied!'
			this.invalidMessage = ''
		})
		emitter.on('gift-card-invalid', (evt) => {
			this.invalidMessage = this.promoCode.giftCardInvalidMessage
			this.hideLoadingOverlay()
		})

		setTimeout(() => {
			this.showPromoCode()
		}, 900);
	},
	methods: {
		toggleInput() {
			this.showInput = !this.showInput
		},
		async applyCode() {
			this.code = this.code.trim()
			if(this.code !== null && this.code !== '') {
				this.invalidMessage = ''
				this.loading = true
				await this.promoCode.checkPromoCode(this.code)
					.then((response) => {
						this.loading = false
					})
					.catch((error) => {
						this.$sentry.captureException(error)
					})
					.finally(() => {
						// cart page might still need to do some calculating,
						//  so don't hide the over lay here unless its the billing page
						if(this.page === 'billing') {
							this.hideLoadingOverlay()
						}
					})
			} else {
				// this.invalidMessage = '' // uncomment this line to remove the Sorry message when the field is blank and clicked apply
				this.hideLoadingOverlay()
			}
		},
		validChars(evt) {
			const charCode = evt.which ? evt.which : evt.keyCode
			// don't allow these characters in the promo code field: ' or " or ; or :
			if (charCode == 34 || charCode == 39 || charCode == 59 || charCode == 58) {
				evt.preventDefault()
			}
		},
		showPromoCode() {
			// this.oldCode = this.code
			this.code = this.promoCode.getCode
			if(this.code && this.code.length) {
				this.appliedCode = this.promoCode.getAppliedCode
				this.successMessage = this.appliedCode.name
				this.invalidMessage = ''
			}
		},
	},
}
</script>

<style>
	.link {
		cursor: pointer;
		text-decoration: underline;
	}
</style>