<template>
	<div
		v-if="showModal"
		id="address-validation-form"
		class="outer_qas_modal"
	>
		<div class="qas_modal">
			<div class="pull-right">
				<button
					id="cboxClose"
					type="button"
					@click="allowUserEdit()"
				>
					close
				</button>
			</div>
			<div class="title">
				Address Validation
			</div>
	
			<div
				class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
				style="margin-left: -15px;"
			>
				<p class="color-primary">
					{{ cleanHtml(promptStrings.header) }}
				</p>
			</div>
	
			<div class="p-l-20 sm-p-l-0">
				<div
					v-if="showPicklist"
					class="col-lg-12 col-md-12 col-sm-12 col-xs-12 qas-row-container"
				>
					<div class="row qas-row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p class="qas-row-label">
								Suggested Corrections:
							</p>
						</div>
					</div>
	
					<div class="row qas-row">
						<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 form-group">
							<div class="arrow">
								<select
									v-model="picklistSelectedKey"
									class="form-control"
								>
									<option value="">
										-- SELECT --
									</option>
									<option
										v-for="(item, key) in picklist"
										:value="key"
									>
										{{ item.addresstext }}
									</option>
								</select>
							</div>
						</div>
	
						<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right text-left-sm">
							<button
								class="button full"
								tabindex="2"
								@click="savePicklistSelection()"
							>
								Use Selected
							</button>
						</div>
					</div>
				</div>
	
				<div
					v-if="showSuggested"
					class="col-lg-12 col-md-12 col-sm-12 col-xs-12 qas-row-container"
					:class="{'qas-row-border': showPicklist}"
				>
					<div class="row qas-row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p class="qas-row-label">
								Suggested Address:
							</p>
						</div>
					</div>
	
					<div class="row qas-row">
						<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div>
								<div>{{ qasData.suggested_address.address_1 }}</div>
								<div v-if="qasData.suggested_address.address_2">
									{{ qasData.suggested_address.address_2 }}
								</div>
								<div>{{ qasData.suggested_address.city }}, {{ qasData.suggested_address.state }} {{ qasData.suggested_address.postal_code }}</div>
							</div>
						</div>
	
						<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-md-4 text-right text-left-sm">
							<button
								class="button full"
								tabindex="2"
								@click="saveSuggestedAddress()"
							>
								Use Suggested
							</button>
						</div>
					</div>
				</div>
	
				<div
					class="col-lg-12 col-md-12 col-sm-12 col-xs-12 qas-row-container"
					:class="{'qas-row-border': (showPicklist || showSuggested)}"
				>
					<div class="row qas-row">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p class="qas-row-label">
								You Entered:
							</p>
						</div>
					</div>
	
					<div class="row qas-row">
						<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div>
								<div>{{ recip.address_1 }}</div>
								<div ng-if="recip.address_2 && recip.address_2 != ''">
									{{ recip.address_2 }}
								</div>
								<div>{{ recip.city }}, {{ recip.state }} {{ recip.postal_code }}</div>
							</div>
							<div style="font-size: 12px">
								<b>(Your address may be undeliverable and incur a re-delivery fee)</b>
							</div>
						</div>
	
						<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-right text-left-sm">
							<button
								class="button full"
								tabindex="2"
								@click="continueWithMyAddress()"
							>
								Use This Address
							</button>
							<button
								class="button full"
								tabindex="2"
								@click="allowUserEdit()"
							>
								Edit This Address
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../main.js'
const axios = require('axios')
import { useCartStore } from '../../stores/CartStore'
export default {
	props: {
		recip: {
			type: Object,
			required: true,
		},
		qasData: {
			type: Object,
			required: true,
		}
	},
	emits: ['complete'],
	data() {
		return {
			QAS_PROMPTS: {
				"InteractionRequired": {
					"header": "<b>We think that your address may be incorrect or incomplete.</b>",
				},
				"PremisesPartial": {
					"header": "<b>The address you entered requires a valid Apt/Suite or Unit number</b>",
					"manualEditLabel": "Enter Apartment / Suite / Unit Number:",
				},
				"StreetPartial": {
					"header": "<b>Your house or building number was not found.</b>",
					"manualEditLabel": "Edit House / Building Number:"
				},
				"AptAppend": {
					"header": "<b>Sorry, we do not recognize your house or building number.</b>",
					"manualEditLabel": "Edit House / Building Number:"
				},
				"Multiple": {
					"header": "<b>We found more than one match for your address.</b>",
				},
				"None": {
					"header": "<b>Sorry, we could not find a match for your address.</b>"
				}
			},
			showModal: false,
			correctAddress: null,
			userEdit: null,
			promptStrings: '',
			picklist: null,
			picklistLength: 0,
			picklistSelectedKey: null,
			showPicklist: false,
			showSuggested: false
		}
	},
	created() {},
	mounted() {
		/// Track this case as a custom event
		window.dataLayer.push({ event: 'customGAEvent', customGAEventLabel: 'QAS Validation Triggered'});

		this.promptStrings = this.qasData.verifylevel ? this.QAS_PROMPTS[this.qasData.verifylevel] : this.QAS_PROMPTS['None'];
		this.picklist = this.qasData.picklist;
		this.picklistLength = typeof(this.qasData.picklist) != 'undefined' ? Object.keys(this.qasData.picklist).length : 0;
		this.showPicklist = this.picklistLength != 'undefined' && this.picklistLength > 1 && ( this.qasData.verifylevel == 'StreetPartial' || this.qasData.verifylevel == 'Multiple' );
		this.showSuggested = this.qasData.verifylevel == 'InteractionRequired' && this.qasData.suggested_address;
		this.showModal = true;
	},
	methods: {
		cleanHtml(str) {
			var stripedHtml = str.replace(/<[^>]+>/g, "");
			var fixed_str = stripedHtml.replace("&nbsp;", " ");
			fixed_str = fixed_str.replace(/&.*;/, "");
			return fixed_str;
		},
		savePicklistSelection() {
			if (typeof this.picklistSelectedKey === 'number') {
				this.showModal = false;
				this.$emit('complete', {crecId: this.recip.crec_id, action: 'save-selected', picklistKey: this.picklistSelectedKey})
			}
		},
		saveSuggestedAddress() {
			this.showModal = false;
			this.$emit('complete', {crecId: this.recip.crec_id, action: 'save-suggested'})
		},
		continueWithMyAddress() {
			this.showModal = false;
			this.$emit('complete', {crecId: this.recip.crec_id, action: 'use-my-address'})
		},
		allowUserEdit() {
			this.showModal = false;
			this.$emit('complete', {crecId: this.recip.crec_id, action: 'edit'});
		}
	}
}
</script>

<style>
	.qas_modal {
		z-index: 999;
	}
</style>