<template>
	<div>
		<div
			ref="modal-background"
			class="kcs-modal-backdrop"
		/>
		<div class="kcs-modal-container">
			<div
				:id="modalType"
				ref="modal"
				class="kcs-modal"
			>
				<button
					type="button"
					class="kcs-btn-close"
					@click="$emit('close-modal', modalType)"
				>
					<i
						class="fa fa-times-circle"
						aria-hidden="true"
					/>
				</button>
				<div
					v-if="$slots.header"
					class="kcs-modal-header"
				>
					<slot name="header">
						default header
					</slot>
				</div>

				<slot
					name="body"
					class="kcs-modal-body"
				/>

				<div v-if="$slots.footer">
					<slot name="footer">
						<button
							type="button"
							class="button shrink full text-center"
							@click="$emit('close-modal', modalType)"
						>
							Close
						</button>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../main';

export default {
	name: "Modal",
	props: {
		modalWidth: {
			type: [String, Number, Function],
			required: false,
			default: 220,
		},
		modalType: {
			type: String,
			required: true
		}
	},

	emits: ['close-modal'],

	data() {
		return {
			sized: false
		}
	},

	computed: {
		width() {
			switch (typeof this.modalWidth) {
				case 'string': {
					return this.modalWidth
				}

				case 'number': {
					return this.modalWidth + 'px'
				}

				case 'function': {
					return this.modalWidth(this)
				}

				default: {
					return this.modalWidth
				}
			}
		}
	},
};
</script>

<style scoped>
.kcs-modal-backdrop {
	position: fixed;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: grid;
	place-items: center;
	z-index: 9998;
}

.kcs-modal-container {
	display: grid;
	place-items: center;
	inset: 0;
	position: fixed;
	z-index: 9999;
}

.kcs-modal {
	background: #FFFFFF;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10000;
	padding: 0;
	max-width: 90%;
	color: var(--color-text-default);
	text-align: initial;
	width: v-bind(width);
}

.kcs-modal-header,
.kcs-modal-footer {
	padding: 15px;
	display: flex;
}

.kcs-modal-header {
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	padding: 0 0 10px;
	text-transform: uppercase;
}

.kcs-modal-footer {
	border-top: 1px solid #eeeeee;
	flex-direction: column;
	justify-content: flex-end;
}

.kcs-modal-body {
	position: relative;
    padding: 30px 20px;
}

.kcs-btn-close {
	background: transparent;
	border: 0;
	font-size: 30px;
	overflow: hidden;
	position: absolute;
	right: 17px;
	top: 7px;
	width: 34px;
    z-index: 1;
	color: var(--color-secondary);
}
</style>