<template>
	<div>
		<div
			ref="skuChooser"
			class="product-chooser"
		>
			<div v-if="page === 'checkout' || page === 'cart'">
				<span @click="openSKUSelector()" v-html="line.sku_name" />&nbsp;
				<a href="javascript:;" @click="openSKUSelector()">
					<span class="color-tertiary font-sm no-wrap">
						<i class="fa fa-pencil-square-o" aria-hidden="true" /> Change
					</span>
				</a>
			</div>

			<!-- TODO: get this working on PCPs & PDPs -->
			<!-- <div v-if="page == 'pcp'">
                <a kcs-add-to-cart v-if="!product.all_out_of_stock_new && product.sku_item_ids[0] != 'CERT' && product.sku_item_ids[0] != 'ECRT' && product.sku_item_ids[0] != 'GIFT'"
                    style="cursor: pointer;"
                    v-show="!localBtnState.loader"
                    show-added-modal-always="true"
                    item-id="product.selectedSku.item_id"
                    prod-id="product.selectedSku.prod_id"
                    qty="data.count"
                    price="product.selectedSku.price"
                    modal-scope="modalScope"
                    keep-sending="keepSending"
                    @click="chooseSkuOrAddToCart()"
                    sku-name="product.selectedSku.sku_name"
                    in-stock="product.selectedSku.in_stock"
                    href="javascript:;"
                    class="button btn-add-to-cart"
                    addr-id="product.addressId"
                    search-session="searchSession">
                    Add to Cart
                </a>
                <button aria-label="" type="button" class="loading button" style="width: 100%; padding: 12px;" v-show="localBtnState.loader">
                    <img src="/img/spinner.png" class="progressSpinner" alt="loading"/>
                </button>
            </div>

            <div v-if="page == 'pdp'">
                <div class="sku-select-container">
                    <div class="arrow">
                        <select aria-label="choose size and count"
                                id="pdp-sku-select"
                                class="pdp-sku-select" 
                                @click="openSKUSelector()" 
                                @keydown="openSKUSelectorTab($event)" 
                                @onmousedown="(function(e){ e.preventDefault(); })(event, this)"
                                style="cursor: pointer">
                            <option v-if="line.item_id">
                                {{ line.sku_name }}
                            </option>
                            <option v-if="!line.item_id">
                                Choose Size &amp; Count
                            </option>
                        </select>
                    </div>
                </div>
            </div> -->
		</div>

		<div v-show="showSkuChooserModal">
			<div
				ref="modal-background"
				class="kcs-modal-backdrop"
			/>
			<div class="kcs-modal-container">
				<div
					ref="modal"
					class="kcs-modal"
					:style="style"
				>
					<button
						type="button"
						class="kcs-btn-close"
						@click="closeModal"
					>
						<i
							class="fa fa-times-circle"
							aria-hidden="true"
						/>
					</button>
					<div class="kcs-modal-header">
						<div name="header">
							Select Size and Count
						</div>
					</div>

					<div
						name="body"
						class="kcs-modal-body"
					>
						<div
							v-if="productData?.product?.skus?.length > 0"
							class="sku-select-container"
						>
							<div
								v-if="line.sku_chooser_style === 'simple_select'"
								class="pdp-sub-headlines"
							>
								<!-- Skip add-on products in first loop - save them for the bottom -->
								<div
									v-for="sku in productData?.product?.skus"
									:key="line.clin_id"
								>
									<div v-if="sku.is_addon === false">
										<div
											class="sku_container_radio"
											:class="{'active': (sku.item_id === line.item_id), 'not_selectable': sku.in_stock === false }"
										>
											<div
												class="sku_container_inner_radio"
												tabindex="1"
												:itemid="sku.sku_id"
											>
												<div class="radio m-t-20">
													<input
														class="hidden"
														type="radio"
														name="selectedSku"
														:checked="(sku.item_id === line.item_id)"
														:value="sku.sku_id"
														:disabled="sku.in_stock === false"
													>
													<label
														for="radio0"
														class="css-label"
														:class="{'active': (sku.item_id === line.item_id) }"
														@click="updateLine(sku, line, recipientId)"
													/>

													<div
														class="sku_description"
														:class="{'active': (sku.item_id === line.item_id) }"
													>
														<strong
															class="font-alt1"
															:class="{'h-uppercase': sku.attributes.is_combo}"
															v-html="sku.sku_name"
														/>&nbsp;&nbsp;&nbsp;#{{ sku.item_id }}&nbsp;&nbsp;&nbsp;
														<div
															v-show="sku.mini_badge"
															class="mini-badge"
														>
															{{ sku.mini_badge }}
														</div>

														<div
															v-if="sku.attributes.is_combo"
															v-html="sku.sku_lead_in"
														/>

														<div>
															<span v-if="isSalePriceSame(sku)">
																<span
																	v-if="sku.in_stock"
																	:class="{'tlt': isSale(sku), 'h-weight-bold': !isSale(sku)}"
																>
																	${{ sku.strike_price }}
																</span>
																<span
																	v-if="!sku.in_stock"
																	class="h-weight-bold"
																>
																	${{ sku.regular_price }}
																</span>
															</span>

															<span
																v-if="!isSalePriceSame(sku)"
																:class="{'tlt': isSale(sku), 'h-weight-bold': !isSale(sku)}"
															>
																${{ sku.strike_price }}
															</span>

															<span v-if="sku.in_stock">
																&nbsp;<span
																	v-if="isSale(sku)"
																	class="h-weight-bold text-danger"
																>
																	<strong>${{ sku.regular_price }}</strong>
																</span>
															</span>

															<div v-if="sku.attributes.is_combo">
																&nbsp;<span
																	v-if="sku.attributes.combo_savings"
																	class="text-danger"
																>
																	Save ${{ sku.attributes.combo_savings }}
																</span>
															</div>
														</div>

														<div v-if="sku.attributes.is_combo">
															<div
																v-if="sku.attributes.combo_savings"
																class="text-danger"
															>
																<span class="tlt">
																	${{ sku.attributes.purchased_separately_price }}
																</span>
																<span class="h-lowercase">
																	if purchased separately
																</span>
															</div>
														</div>

														<div v-if="sku.in_stock === false">
															Out of Stock
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<!-- Second loop for addons -->
									<div v-if="sku.is_addon === true">
										<div
											class="sku_container_radio"
											:class="{'active': (sku.item_id === line.item_id), 'not_selectable': sku.in_stock === false }"
										>
											<div
												class="sku_container_inner_radio"
												:itemid="sku.sku_id"
											>
												<div class="radio m-t-20">
													<input
														class="hidden"
														type="radio"
														name="selectedSku"
														:checked="(sku.item_id === line.item_id)"
														:value="sku.sku_id"
														:disabled="sku.in_stock === false"
													>
													<label
														for="radio0"
														class="css-label"
														:class="{'active': (sku.item_id === line.item_id) }"
														@click="updateLine(sku, line, recipientId)"
													/>

													<div
														class="sku_description"
														:class="{'active': (sku.item_id === line.item_id) }"
													>
														<strong
															class="font-alt1"
															:class="{'h-uppercase': sku.attributes.is_combo}"
															v-html="sku.sku_name"
														/>
														&nbsp;&nbsp;&nbsp;#{{ sku.item_id }}&nbsp;&nbsp;&nbsp;
														<div
															v-show="sku.mini_badge"
															class="mini-badge"
														>
															{{ sku.mini_badge }}
														</div>

														<div
															style="position:absolute; display:inline-block; margin-left:12px; margin-top:1px;"
															onmouseover="$(this).find('.tooltrix').show();"
															onmouseout="$(this).find('.tooltrix').hide();"
															onclick="$(this).find('.tooltrix').show(); event.preventDefault(); event.stopPropagation();"
														>
															&nbsp;<span
																	class="fa fa-question-circle color-tertiary"
																	data-toggle="tooltip"
																	style="font-size:20px;"
																/>
															<div
																class="tooltrix"
																style="display:none;"
															>
																Extra savings, no additional shipping when you order and ship this item with any other
																perishable item of $59.95+
															</div>
														</div>
													</div>

													<div
														v-if="sku.attributes.is_combo"
														v-html="sku.sku_lead_in"
													/>

													<div>
														<span v-if="isSalePriceSame(sku)">
															<span
																v-if="sku.in_stock"
																:class="{'tlt': isSale(sku), 'h-weight-bold': !isSale(sku)}"
															>
																${{ sku.strike_price }}p
															</span>

															<span
																v-if="!sku.in_stock"
																class="h-weight-bold"
															>
																${{ sku.regular_price }}o
															</span>
														</span>

														<span
															v-if="!isSalePriceSame(sku)"
															:class="{'tlt': isSale(sku), 'h-weight-bold': !isSale(sku)}"
														>
															${{ sku.strike_price }}
														</span>

														<span v-if="sku.in_stock">
															&nbsp;<span
																v-if="isSale(sku)"
																class="h-weight-bold text-danger"
															>
																<strong>${{ sku.regular_price }}</strong>
															</span>
														</span>

														<div v-if="sku.attributes.is_combo">
															&nbsp;<span
																v-if="sku.attributes.combo_savings"
																class="text-danger"
															>
																Save ${{ sku.attributes.combo_savings }}
															</span>
														</div>
													</div>

													<div v-if="sku.attributes.is_combo">
														<div
															v-if="sku.attributes.combo_savings"
															class="text-danger"
														>
															<span class="tlt">
																${{ sku.attributes.purchased_separately_price }}
															</span>
															<span class="h-lowercase">
																if purchased separately
															</span>
														</div>
													</div>

													<div v-if="sku.in_stock === false">
														Out of Stock
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div v-if="line.sku_chooser_style === 'size_and_count'">
								<div
									v-for="(group, key) in productData?.product?.skuGrouping?.skus?.skus"
									:key="key"
								>
									<div class="sku_primary_attr text-center">
										<div class="pdp-sub-headlines">
											<h3 class="chooser-size-bar">
												{{ key }} {{ key !== 'Add-ons' ? 'Ounces' : '' }}
											</h3>
											<div v-if="key === 'Add-ons'">
												<div class="addon_savings">
													Extra savings, no additional shipping when you order and ship this item with any other perishable item of $59.95+
												</div>
											</div>
										</div>
										<div
											class="sku_selector_skus"
											:class="{'addon-sku-row': (key === 'Add-ons')}"
										>
											<div class="sku_selector_row">
												<div
													v-for="item in group"
													:key="item.item_id"
												>
													<div
														class="sku_container"
														:class="{'active': (item.item_id === line.item_id)}"
													>
														<div
															class="sku_container_inner"
															tabindex="1"
															:itemid="item.item_id"
															:class="{'not_selectable': !item.in_stock}"
														>
															<div class="sku_form">
																<input
																	type="radio"
																	name="selectedSku"
																	:checked="(item.item_id === line.item_id)"
																	:value="item.sku_id"
																	:disabled="!item.in_stock"
																>
																<label
																	for="radio0"
																	class="css-label"
																	:class="{'active': (item.item_id === line.item_id)}"
																	@click="updateLine(item, line, recipientId)"
																/>
															</div>
															<div
																class="sku_details"
																:class="{'sku-out-of-stock': !item.in_stock}"
															>
																<div v-if="key === 'Add-ons'">
																	<div class="font-alt1 h-weight-bold h-uppercase">
																		{{ item.size }} {{ item.size_units }}
																	</div>
																</div>
																<div class="font-alt1 h-weight-bold h-uppercase">
																	{{ item.unit_count }} Count
																</div>
																<div>#{{ item.item_id }}</div>

																<div
																	v-if="isSale(item)"
																	class="tlt"
																>
																	{{ currency(item.strike_price) }}
																</div>
																<div
																	v-else
																	class="h-weight-bold"
																>
																	{{ currency(item.strike_price) }}
																</div>

																<div
																	v-if="item.in_stock && isSale(item)"
																	class="text-danger h-weight-bold"
																>
																	{{ currency(item.regular_price) }}
																</div>

																<div
																	v-if="!item.in_stock"
																	style="color: #b24242"
																>
																	Out of Stock
																</div>

																<div
																	v-show="item.mini_badge"
																	class="mini-badge"
																>
																	{{ formatText(item.mini_badge) }}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							v-else
							class=""
						>
							<i class="fa fa-spinner fa-spin fa-fw" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../main'
import currencyFormatterMixin from '../../mixins/currency-formatter-mixin'
import { useCartStore } from '../../stores/CartStore'

const axios = require('axios')

export default {
	name: 'SkuChooser',
	mixins: [
		currencyFormatterMixin
	],
	props: {
		page: {
			type: String,
			required: false,
			default: 'checkout'
		},
		line: {
			type: Object,
			required: true,
		},
		recipientId: {
			type: String,
			required: false
		},
	},
	data() {
		return {
			productData: {},
			showSkuChooserModal: false,
			style: {},
			windowHeight: window.innerHeight,
			windowWidth: window.innerWidth,
			windowScrollY: window.scrollY,
			calcTop: window.innerHeight / 2,
			sized: false
		}
	},
	mounted() {
		window.addEventListener('resize', this.handleResize)
		window.addEventListener('scroll', this.handleResize)
		this.style = {}
		emitter.on('close-sku-chooser-modal', evt => {
			this.showSkuChooserModal = false
		}),
		this.getProductData()
	},
	updated() {
		if(this.$refs.modal.clientHeight > 0 && !this.sized) {
			this.sized = true
			const defaultModalWith = 800
			// get current window width and calculate how big our window needs to be and center it
			const currentWindowWidth = window.innerWidth
			const currentWindowHeight = window.innerHeight
			let width = defaultModalWith
			let left = 0
			let transform = 'translateX(-50%)'
			if(currentWindowWidth > (defaultModalWith + 20)) {
				// windows is big enough - calculate placement only
				left = (currentWindowWidth / 2) - (this.$refs.skuChooser.getBoundingClientRect().left - 15)
			} else {
				// window is small - recalculate modal size and placement
				width = (currentWindowWidth - (currentWindowWidth * .10))
				left = (currentWindowWidth / 2) - (this.$refs.skuChooser.getBoundingClientRect().left - 15)
			}

			this.calcTop = (this.windowHeight - this.$refs.modal.clientHeight) / 2 + (this.$refs.modal.clientHeight / 2) + this.windowScrollY
			this.style = {
				'width': width + 'px',
				'left': left + 'px',
				'position': 'relative',
				'transform': transform
			}
		}
	},
	methods: {
		openSKUSelector() {
			this.showSkuChooserModal = true
		},
		chooseSkuOrAddToCart() {
			console.log('choose sku or add to cart')
		},
		closeModal() {
			this.showSkuChooserModal = false
			this.sized = false
		},
		async getProductData() {
			// the below might belong somewhere else better
			// however this will only be called if they click change on the item line
			await axios.get('/api/checkout/product/' + this.line.product_prod_id)
				.then((response) => {
					this.productData = response.data
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
		},
		isSale(sku) {
			if(parseFloat(sku.regular_price) < parseFloat(sku.strike_price)) {
				this.saleStrikeThru = true
				return true
			}
			return false
		},
		isSalePriceSame(sku) {
			return (parseFloat(sku.strike_price) === parseFloat(sku.attributes?.purchased_separately_price))
		},
		formatText(text) {
			if(text !== null && typeof(text) != 'undefined') {
				return text.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			} else {
				return text
			}
		},
		handleResize() {
			this.windowHeight = window.innerHeight
			this.windowWidth = window.innerWidth
			this.windowScrollY = window.scrollY
			this.sized = false
		},
		async updateLine(sku, line, recipientId) {
			if(!sku.in_stock) {
				// if sku is not in stock - do nothing keep the modal open
				return
			}
			let parentItemClinId = ''
			this.cartStore = useCartStore()
			// check to see if sku has a parent item - if no parent item, give error, don't continue
			if(sku.is_addon) {
				// check each item in this recipients list to see if any item is NOT an add-on
				parentItemClinId = this.cartStore.hasPossibleParentItem(recipientId, line)
				if(!parentItemClinId) {
					alert("This item is only available with the purchase of perishable item(s) valued at $59.95 or more.  Please add another item to this shipment to reach the $59.95 minimum or select this item at its list price.");
					return
				}
			}

			// if the sku and line are the same, don't do anything
			if(sku.sku_id === line.sku_id && sku.item_id === line.item_id) {
				// same item, just close the modal
				this.closeModal()
				return
			}

			this.cartStore.skuChangeItem(sku, line, recipientId, parentItemClinId)
			this.closeModal()
		},
	},
}
</script>

<style>
.sku-out-of-stock {
	color: #BBBBBB !important;
}
.kcs-modal h3.chooser-size-bar {
    margin-bottom: 10px;
    margin-top: 10px;font-size: 17px;
    line-height: 31px;
    margin: 5px;
}
.kcs-modal .pdp-sub-headlines {
    border-top: 1px solid #BBB2A4;
    padding-bottom: 0;
    padding-top: 0;
}
.kcs_sku_selector .mini-badge {
	display: inline-block;
	padding: 2px 5px;
	color: #fff;
	background-color: var(--color-primary);
	text-align: center;
	vertical-align: middle;
}

.kcs_sku_selector .mini-badge:empty {
	display: none;
}
.no-wrap {
	white-space: nowrap;
}
</style>