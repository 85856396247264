import { defineStore } from 'pinia'
import axios from 'axios'
import { emitter } from '../main.js'
import { useCartStore } from './CartStore.js'

export const usePromoCodeStore = defineStore('PromoCodeStore', {
	state: () => {
		return {
			successMessage: 'Your Promo Code has been accepted',
			invalidMessage: 'The code you provided is either expired or invalid.',
			giftCardSuccessMessage: '',
			giftCardInvalidMessage: 'The code you provided is either expired or invalid.',
			code: '',
			appliedCode: {}
		}
	},
	actions: {
		// TODO: initialize the promoCode to not have any data stored when a new cart is created
		async checkPromoCode(newCode) {
			if(newCode === null) {
				return
			}
			let validCode = false
			await axios.get('/api/promo-code/get-active-codes/' + newCode)
				.then((response) => {
					if(response.data.discount_code) {
						// success so lets update the server
						return axios.put('/api/checkout/discount/', { discount_code: newCode })
							.then((dResponse) => {
								if (dResponse.data?.status === 'success') {
									if (this.code !== '' && this.code !== null) {
										emitter.emit('removedOldPromoCode', this.code)
									}

									validCode = true
									this.appliedCode = response.data
									this.appliedCode.code = newCode
									this.code = newCode
									emitter.emit('promo-code-valid', newCode)
									emitter.emit('promo-code-success', newCode)
								} else {
									if(dResponse.data?.status == 'invalid') {
										emitter.emit('promo-code-invalid', newCode)
									} else if(dResponse.data?.status == 'expired') {
										emitter.emit('promo-code-expired', newCode)
									}
								}
							})
							.catch((dError) => {
								emitter.emit('promo-code-apply-error', newCode)
							})
					} else {
						// no valid promo code - check for gift card at this point (below)
						validCode = false
						if(response.data.status == 'invalid') {
							emitter.emit('promo-code-invalid', newCode)
						} else if(response.data.status == 'expired') {
							emitter.emit('promo-code-expired', newCode)
						}
					}
				})
				.catch((error) => {
					emitter.emit('promo-code-invalid', newCode)
				})
		},
		async checkGiftCard(code, currentPage = 'billing') {
			await axios.put('/api/checkout/giftcard/', { gc_code: code, page: currentPage })
				.then(response => {
					if (response.data.status == 'success') {
						// validCode = true
						emitter.emit('gc-code-success', response.data.balance.toString())
						return
					} else {
						emitter.emit('gift-card-invalid', this.code)
					}
				}).catch((error) => {
					emitter.emit('gift-card-invalid', this.code)
				})
		},
		async validateExistingPromoCode(code) {
			await axios.get('/api/promo-code/get-active-codes/' + code)
				.then((response) => {
					if(this.code && !response.data.discount_code) {
						this.clearCode()
						emitter.emit('removedOldPromoCode', code)
					}

					if(response.data.discount_code && response.data.discount_code != this.code) {
						this.appliedCode = response.data
						this.appliedCode.code = code
						this.code = code
					}
				})
		},
		clearCode() {
			this.code = null
			this.appliedCode = {}
		},
	},
	getters: {
		getCode(state) {
			return state.code
		},
		getAppliedCode(state) {
			return state.appliedCode
		},
		getSuccessMessage(state) {
			return state.successMessage
		},
		getInvalidMessage(state) {
			return state.invalidMessage
		},
	},
	persist: true
})