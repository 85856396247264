import { defineStore } from 'pinia'
import axios from 'axios'
import { emitter } from '../main.js'

export const useUserStore = defineStore('UserStore', {
	state: () => {
		return {
			customer: {},
			addresses: [],
			customerName: '',
			isLoggedIn: null,
			guestEmail: '',
			guestPassword: '',
			autoDeliveryRegistration: false,
		}
	},

	actions: {
		async loadCustomer() {
			// Fetch the addresses from the server
			return await axios.get('/api/customer')
				.then(response => {
					// handle success
					if (response.data?.customer) {
						const { customer } = response.data

						this.customer = customer
						this.isLoggedIn = true
						this.customerName = `${customer.fname} ${customer.lname}`
	
						this.$sentry.setUser({
							id: customer.cust_id,
							email: customer.email,
							name: this.customerName
						})

					}

					return this.customer
				})
				.catch(error => {
					this.clearCustomer()

					if (error.response?.status !== 403) {
						this.$sentry.captureException(error)
					}
				})
		},
		async loadCustomerAddresses() {
			// Fetch the addresses from the server
			axios.get('/api/customer/address')
				.then(response => {
					// handle success
					this.addresses = response.data.addresses
					emitter.emit('customer-addresses-loaded')
					return this.addresses
				})
				.catch(error => {
					this.$sentry.captureException(error)
				})
		},
		async autoDeliverySpecialRegister(guestData) {
			guestData.token = window.csrfToken
			return await axios.post('/api/checkout/auto-delivery-login-or-register/', guestData)
				.then((response) => {
					this.autoDeliveryRegistration = true
					return response.data
				})
				.catch(error => {
					return error.response.data
				})
		},
		clearCustomer() {
			this.$reset()

			this.$sentry.setUser({})
		}
	},
	getters: {
		getCustomer: (state) => {
			return state.customer
		},
		getCustomerName: (state) => {
			return state.customerName;
		},
		getIsLoggedIn: (state) => {
			return window.isLoggedIn
		},
		getGuestEmail: (state) => {
			return state.guestEmail
		},
		getGuestPassword: (state) => {
			return state.guestPassword
		},
		getAddresses: (state) => {
			if(!state.isLoggedIn || (typeof(state.addresses) != 'undefined' && state.addresses.length)) {
				return state.addresses;
			} else {
				// Fetch the addresses from the server
				axios.get('/api/customer/address')
					.then(response => {
						// handle success
						state.addresses = response.data.addresses
						return state.addresses
					
					})
					.catch(error => {
						this.$sentry.captureException(error)
					})
			}
		},
		getAddressById(state) {
			return (addr_id) => state.addresses.find((addr) => addr.addr_id === addr_id)
		}
	},
	persist: true
})