<template>
	<div>
		<div :id="'message-container-'+crecId" class="shipping-not-complete">{{ shippingFormMessage }}</div>
		<form class="sg-form cart-form" name="shippingAddressForm" novalidate @submit.prevent="onSubmit">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="col-md-6 col-md-6 col-lg-offset-3 col-md-offset-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
					<span>Fields marked with <span class="required-asterisk color-primary">*</span> are required.</span>
				</div>
			</div>
			<div id="top-of-form" class="col-xs-12" style="padding: 0">
				<!--////  Address Form  ///// -->

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.fname.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'fname-'+recipient.crec_id">
								<span class="required-asterisk color-primary">*</span>
								FIRST NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'fname-'+recipient.crec_id" v-model="editableRecipient.fname" v-maxlength="15" required type="text" class="form-control" autocomplete="off" :disabled="inputDisabled">
							<span v-if="validationErrors.fname.error" class="color-primary inline-block ng-binding">{{ validationErrors.fname.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.lname.error}">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'lname-'+recipient.crec_id">
								<span class="required-asterisk color-primary">*</span>
								LAST NAME
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'lname-'+recipient.crec_id" v-model="editableRecipient.lname" v-maxlength="15" required type="text" class="form-control" autocomplete="off" :disabled="inputDisabled">
							<span v-if="validationErrors.lname.error" class="color-primary inline-block ng-binding">{{ validationErrors.lname.message }}</span>
						</div>
					</div>
				</div>

				<div class="flexbox">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="{'has-error': validationErrors.email.error }">
						<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
							<label class="align-self-c" :for="'email-'+recipient.crec_id">
								<span class="required-asterisk color-primary">*</span>
								EMAIL ADDRESS
							</label>
						</div>
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0">
							<input :id="'email-'+recipient.crec_id" v-model="editableRecipient.email" v-maxlength="50" required type="text" class="form-control" autocomplete="off" :disabled="inputDisabled">
							<div v-if="validationErrors.email.error" class="p-lr-0">
								<span class="color-primary inline-block">{{ validationErrors.email.message }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="">
					<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div class="flexbox">
							<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
								<label class=""><span class="required-asterisk color-primary">*</span>Delivery</label>
							</div>
							<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 p-t-10">
								<span>
									<i class="fa fa-envelope" style="font-size: 18px" aria-hidden="true" /><span class="bold" style="margin-left:10px">{{ generateDeliveryMessage(editableRecipient, false, true) }}</span>
								</span>
								
								<slot name="shipping-calendar" />

								<div class="color-primary">{{ validationErrors.ship_date.message }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="col-lg-3 col-md-3 col-md-12 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 text-right text-left-sm">
						<label for="">
							<span class="h-uppercase">Is this a gift?</span>
						</label>
					</div>
					<div class="col-lg-6 col-md-6 col-md-12 col-sm-12 col-xs-12 p-lr-5 sm-p-lr-0 input_checkbox_group">
						<div class="input_wrapper m-t-10 m-l-0">
							<input :id="'is-a-gift-'+recipient.crec_id" v-model="editableRecipient.is_a_gift" type="checkbox" :checked="editableRecipient.is_a_gift">
							<label class="input_checkbox m-r-5" :for="'gift-message-'+recipient.crec_id" style="margin-top: 0 !important;" @click="editableRecipient.is_a_gift = !editableRecipient.is_a_gift" />
							<p class="h-mixed-case">Add an optional gift message.</p>
						</div>
						<div v-if="editableRecipient.is_a_gift" class="col-lg-12 col-md-12 col-md-12 col-sm-12 col-xs-12 p-lr-0 md-p-r-0">
							<div :class="{'has-error': validationErrors.gift_message.error }">
								<label class="m-l-0" :for="'gift-message-'+recipient.crec_id">Gift Message</label>
								<textarea :id="'gift-message-'+recipient.crec_id" v-model="editableRecipient.gift_message" v-maxlength="240" rows="6" cols="40" class="m-l-0 gift-message-textarea" @input="formatMessage('gift_message')" />
								<span class="color-primary">{{ validationErrors.gift_message.message }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="flexbox">
						<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-lg-offset-3 col-md-offset-3">
							<button type="submit" class="button full pull-right" @click="onDone">
								Done
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="clearfix" />
		</form>
	</div>
</template>

<script>
import formatDateMixin from '../../../mixins/date-formatter-mixin.js'
import checkoutHelpers from '../../../mixins/checkout-global-functions.js'
import { emitter } from '../../../main.js'
import { useCartStore } from '../../../stores/CartStore'
import { useUserStore } from '../../../stores/UserStore'
import { watch } from 'vue'

export default {
	mixins: [formatDateMixin, checkoutHelpers],
	props: {
		crecId: {
			type: String,
			required: true,
		},
		recipient: {
			type: Object,
			required: true,
		},
		validationErrors: {
			type: Object,
			default: () => ({})
		}
	},

	emits: ['done'],

	setup(props) {
		const editableRecipient = props.recipient

		return {
			editableRecipient
		}
	},

	data() {
		return {
			inputDisabled: false,
			shippingFormMessage: '',
			messageRules: {
				maxLines: 6,
				maxLineLength: 40
			}
		};
	},

	mounted() {
		// User Stuff
		this.userStore = useUserStore();

		// Cart Stuff
		this.cartStore = useCartStore();

		// Listen for recipient addr_id change coming from ShipToSelector
		emitter.on('ship-to-addr-id-changed', (evt) => {
			// Tell the cartStore to change the shipment address
			this.cartStore.recipientChangeSavedAddress(evt.crec_id, evt.uuid, evt.is_new)
		})

		// Listen for events triggered by recipientChangeShipDate
		emitter.on('cart-recipient-shipping-updated', (evt) => {
			this.validationErrors.ship_date.error = false
			this.validationErrors.ship_date.message = ''
		})

		watch(
			() => this.editableRecipient.gift_message,
			(giftMessage) => {
				if (this.validateMessage(giftMessage)) {
					this.validationErrors.gift_message.error = false
					this.validationErrors.gift_message.message = ''
				} else {
					this.formatMessage('gift_message')
				}
			}
		)
	},
	methods: {
		validateMessage(input = '') {
			const lines = input.split('\n')

			if (lines > this.messageRules.maxLines) return false

			for (let i = 0; i < lines.length; i++) {
				if (lines[i].length > this.messageRules.maxLineLength) return false
			}

			return true
		},
		
		formatMessage(which) {
			// This function enforces the KCS system rules for gift message formatting
			// Max 6 lines of 40 characters each
			// The which variable allows us to apply the same formatting rules to either
			// - the standard in-box gift message
			// - or the announce-it email gift message
			let maxLineLen = this.messageRules.maxLineLength;
			let maxLines = this.messageRules.maxLines;

			let messageLines = this.editableRecipient[which].split("\n");
			for (var i = 0; i < messageLines.length; i++) {
				if (messageLines[i].length <= maxLineLen){
					continue;
				}

				let j = 0;
				let space = maxLineLen;
				while (j++ <= maxLineLen) {
					if (messageLines[i].charAt(j) === " ") space = j;
				}

				if(i < (maxLines - 1)) {
					// if there are more messageLines available
					messageLines[i + 1] = messageLines[i].substring(space + 1) + (messageLines[i + 1] ? " " + messageLines[i + 1] : "");
					messageLines[i] = messageLines[i].substring(0, space);
				} else {
					// If we are on the last line - just remove the characters over the max line length (maxLineLen)
					messageLines[i] = messageLines[i].slice(0, maxLineLen - messageLines[i].length);
				}
			}

			this.editableRecipient[which] = messageLines.slice(0, maxLines).join("\n");
		},

		onDone() {
			this.$emit('done')
		}
	}
}
</script>

<style>
	.tooltip .tooltip-inner{
		max-width: 600px;
	}
</style>
