<template>
	<div class="row m-t-25">
		<div class="col-md-12 col-sm-12 col-xs-12 payment-options">
			<label for="" class="gc-label" tabindex="1" @click="showGCBox()">
				<i v-if="!showGCBoxFlag" class="fa fa-plus gc-opener color-gold" />
				<i v-if="showGCBoxFlag" class="fa fa-minus gc-opener color-gold" />
				<span class="payment-options-text">
					GIFT CARD OR CERTIFICATE
				</span>
			</label>
			<i id="gc-info-balloon" v-tooltip:bottom="gcToolTipText" class="fa fa-question-circle gc-info" @keydown="showToolTip()" />
		</div>

		<div v-if="showGCBoxFlag" class="col-md-12 col-sm-12 col-xs-12">
			<div class="gc-hidden-box flexbox">
				<div class="input-code">
					<div class="form-group">
						<div class="flexbox-group">
							<div v-if="label != 'blank'" class="promo-label">
								<label class="h-uppercase font-alt1">{{ label }}</label>
							</div>
							<div class="code-input-container" :class="{'code-input-right': alignment == 'right'}">
								<input v-model="code" type="text" class="">
							</div>
							<div class="">
								<button class="button button-primary shrink" style="cursor:pointer;" @click="codeApply()">
									Apply
								</button>
							</div>
						</div>
						<div class="font-sm code-message">
							<div v-if="apply_success_message">
								<div style="font-size: 20px; font-weight: bold; text-transform: uppercase;" class="color-success font-alt1">
									Congratulations
								</div>
								<div class="h-uppercase color-success font-alt1" style="font-size: 20px">
									{{ apply_success_message }}
								</div>
								<div class="font-alt1" style="font-size: 16px">
									<span ng-bind-html="gc_apply_success_header" />
								</div>
							</div>
							<div v-if="invalid_message" class="messages">
								<div style="font-weight: 900;" class="h-uppercase color-primary h-size-h5">
									SORRY
								</div>
								<div class="font-md">
									{{ invalid_message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import checkoutHelpers from '../../mixins/checkout-global-functions.js'
import { emitter } from '../../main.js'
const axios = require('axios')
export default {
	components: {},
	mixins: [checkoutHelpers],
	props: [],
	data() {
		return {
			code: '',
			showGCBoxFlag: false,
			apply_success_message: '',
			apply_success_header: '',
			invalid_message: '',
			alignment: 'right',
			label: 'blank',
			gcToolTipText: "For Gift Cards, enter the entire 12-character code on the back of your card without spaces. For Paper Certificates, enter the 6-digit order number followed by the 6-digit certificate number without spaces.",
		};
	},
	created(){},
	mounted() {
		emitter.on('gift-card-removed', (evt) => {
			this.apply_success_message = ''
			this.showGCBoxFlag = false
		})
	},
	methods: {
		showGCBox() {
			this.showGCBoxFlag = !this.showGCBoxFlag
		},
		showToolTip(event) {
			if (event.which == 32) {
				event.preventDefault();
				if (this.recip.announceItStuffIsOpen == false) {
					$("#gc-info-balloon").trigger("mouseover");
					this.recip.announceItStuffIsOpen = true;
				} else {
					$("#gc-info-balloon").trigger("mouseout");
					this.recip.announceItStuffIsOpen = false;
				}
			}
		},
		codeApply() {
			this.showLoadingOverlay()
			axios.put('/api/checkout/giftcard/', { gc_code: this.code, page: 'billing'})
				.then(response => {
					if ( typeof response.data != 'undefined'
						&& typeof response.data.status != 'undefined'
						&& response.data.status == 'success'
					) {
						this.apply_success_header = '';
						this.apply_success_message = "Your Gift Card for $"+ response.data.balance.toString() +' has been applied!'; 
						this.code = "";
						this.invalid_message = "";
							
						emitter.emit('gift-card-successfully-applied')
					} else {
						this.invalid_message = 'The code you provided is either expired or invalid.'
					}
				})
				.catch(error => {
					// handle error
					this.invalid_message = 'The code you provided is either expired or invalid.'
				})
				.finally(() => {
					// Always
					this.hideLoadingOverlay()
				});
		}
	}
}
</script>

<style>
	.tooltip .tooltip-inner{
		max-width: 600px;
	}
</style>