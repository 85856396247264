<template>
	<div class="m-t-20">
		<div v-show="cart.recalc_detail.free_gift_fixed[recipient.recipient_guid] && showFreeItem" class="row free-gift-items clear" v-for="free_item in cart.recalc_detail.free_gift_fixed[recipient.recipient_guid]" :key="free_item.sku_id">
			<div class="col-md-1" />

			<div class="col-md-1">
				<img
					class="img-responsive"
					:src="free_item.product_image"
					:alt="free_item.sku_name"
				>
			</div>
			<div class="col-md-2 font-TabletGothicNarrow">
				<strong>FREE ITEM:</strong> <br>
				<p>{{ free_item.sku_name }}</p>
			</div>
			<div class="col-md-2 text-center font-TabletGothicNarrow">
				<span class="strike ">{{ currency(free_item.display_totals.strike_price) }}</span>
				<span class="bold">&nbsp; FREE &nbsp;</span>
			</div>

			<div class="col-md-6">
				<p class="pull-right bold">
					&nbsp; FREE &nbsp;
				</p>
				<p class="pull-right strike ">
					{{ currency(free_item.display_totals.strike_price) }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import currencyFormatterMixin from '../../../mixins/currency-formatter-mixin'
import freeItemMixin from '../../../mixins/free-item-mixin'

export default {
	name: 'FreeItem',
	mixins: [
		currencyFormatterMixin,
		freeItemMixin,
	],
	props: {},
	data() {},
	mounted() {},
	methods: {},
}
</script>