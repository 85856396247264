<template>
	<div>
		<Splide id="splide-main" ref="splideMain" :options="splide.mainOptions" :has-track="false" @splide:moved="onMainMoved">
			<SplideTrack>
				<SplideSlide v-for="(image, index) in images" :key="`${image.url}-${index}`">
					<span v-if="image.image_overlay_text" class="image-overlay-text">
						{{ image.image_overlay_text }}
					</span>
					<img :src="`${pdpData.cdn_url}/dyn-images/pdp_hero/${image.url}`" :alt="getImageAlt(image)">
				</SplideSlide>
			</SplideTrack>
	
			<div class="splide__arrows">
				<button class="splide__arrow splide__arrow--prev" :disabled="splideArrowState.main.prev === false">
					<svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46" fill="none">
						<g id="Arrow Frame">
							<path id="Right Arrow" d="M19 35.6406L31 23.7124L19 11.7842" stroke="#605255" stroke-opacity="0.7" stroke-width="5" stroke-linecap="round" />
							<path id="Right Arrow_2" d="M19 35.6406L31 23.7124L19 11.7842" stroke="white" stroke-width="3" stroke-linecap="round" />
						</g>
					</svg>
				</button>
				<button class="splide__arrow splide__arrow--next" :disabled="splideArrowState.main.next === false">
					<svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46" fill="none">
						<g id="Arrow Frame">
							<path id="Right Arrow" d="M19 35.6406L31 23.7124L19 11.7842" stroke="#605255" stroke-opacity="0.7" stroke-width="5" stroke-linecap="round" />
							<path id="Right Arrow_2" d="M19 35.6406L31 23.7124L19 11.7842" stroke="white" stroke-width="3" stroke-linecap="round" />
						</g>
					</svg>
				</button>
			</div>
		</Splide>
	
		<div v-show="shouldShowThumbnails">
			<Splide
				id="splide-thumbnail"
				ref="splideThumbnail"
				:options="splide.thumbnailOptions"
				:has-track="false"
				@slide:scrolled="onThumbnailMoved"
				@splide:moved="onThumbnailMoved"
			>
				<span :class="{ 'is-active': splideArrowState.thumbnail.prev === true }" class="splide-track-gradient splide-track-gradient--prev" />
				<SplideTrack>
					<SplideSlide v-for="(image, index) in images" :key="`${image.url}-${index}`">
						<img :src="`${pdpData.cdn_url}/dyn-images/pdp_hero/${image.url}`" :alt="getImageAlt(image)">
					</SplideSlide>
				</SplideTrack>
				<span :class="{ 'is-active': splideArrowState.thumbnail.next === true }" class="splide-track-gradient splide-track-gradient--next" />
		
				<div class="thumbnail-arrows">
					<button class="thumbnail-arrow thumbnail-arrow--prev" :disabled="splideArrowState.thumbnail.prev === false" @click.stop="onThumbnailPagePrev">
						<svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46" fill="none">
							<g id="Arrow Frame">
								<path id="Right Arrow" d="M19 35.6406L31 23.7124L19 11.7842" stroke="#605255" stroke-opacity="0.7" stroke-width="5" stroke-linecap="round" />
								<path id="Right Arrow_2" d="M19 35.6406L31 23.7124L19 11.7842" stroke="white" stroke-width="3" stroke-linecap="round" />
							</g>
						</svg>
					</button>
		
					<button class="thumbnail-arrow thumbnail-arrow--next" :disabled="splideArrowState.thumbnail.next === false" @click.stop="onThumbnailPageNext">
						<svg xmlns="http://www.w3.org/2000/svg" width="45" height="46" viewBox="0 0 45 46" fill="none">
							<g id="Arrow Frame">
								<path id="Right Arrow" d="M19 35.6406L31 23.7124L19 11.7842" stroke="#605255" stroke-opacity="0.7" stroke-width="5" stroke-linecap="round" />
								<path id="Right Arrow_2" d="M19 35.6406L31 23.7124L19 11.7842" stroke="white" stroke-width="3" stroke-linecap="round" />
							</g>
						</svg>
					</button>
				</div>
			</Splide>
		</div>
	</div>
</template>

<script>
import '@splidejs/vue-splide/css'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'

const EASING = 'ease-in-out'
const MEDIA_QUERY = 'min'

const MAIN_SPLIDE_OPTIONS = {
	arrows: false,
	pagination: false,
	heightRatio: 1,
	mediaQuery: MEDIA_QUERY,
	easing: EASING,

	breakpoints: {
		768: {
			arrows: true,
		},
	}
}

const THUMBNAIL_SPLIDE_OPTIONS = {
	arrows: false,
	fixedWidth: 50,
	fixedHeight: 50,
	gap: 1,
	isNavigation: true,
	pagination: false,
	mediaQuery: MEDIA_QUERY,
	easing: EASING,
	focus: 'center',
	updateOnMove: true,

	breakpoints: {
		768: {
			fixedWidth: 100,
			fixedHeight: 100,
		},
		1024: {
			fixedWidth: 75,
			fixedHeight: 75,
		},
		1200: {
			fixedWidth: 100,
			fixedHeight: 100,
		}
	}
}

export default {
	name: 'ImageGallery',

	components: {
		Splide,
		SplideTrack,
		SplideSlide,
	},

	data() {
		return {
			selectedItemId: null,

			splideArrowState: {
				main: {
					prev: false,
					next: true,
				},
				thumbnail: {
					prev: false,
					next: true,
				}
			},

			splide: {
				mainOptions: MAIN_SPLIDE_OPTIONS,
				thumbnailOptions: THUMBNAIL_SPLIDE_OPTIONS,
			}
		}
	},

	computed: {
		images() {
			const images = this.imagesByItemId[this.selectedItemId] || []
			
			if (!images.length) {
				return [
					{
						alt_type: 'image',
						url: this.pdpData.primary_image,
						alt_text: this.pdpData.name,
					}
				]
			}

			return images.filter((image) => image.alt_type.toLowerCase() === 'image')
		},

		imagesByItemId() {
			if (!this.pdpData || !this.pdpData?.skus?.length) return {}

			return this.pdpData.skus.reduce((byItemId, sku) => {
				return {
					...byItemId,
					[sku.item_id]: sku.attributes?.alt_images || []
				}
			}, {})
		},

		pdpData() {
			return window.pdpData || null
		},

		shouldShowThumbnails() {
			return this.images.length > 1
		}
	},

	mounted() {
		window.addEventListener('vue:pdp:select-sku', this.handleSkuChange)

		this.$refs.splideMain.splide.sync(this.$refs.splideThumbnail.splide)
		this.updateSplideFocus(this.$refs.splideThumbnail.splide)
	},

	beforeUnmount() {
		window.removeEventListener('vue:pdp:select-sku', this.handleSkuChange)
	},

	methods: {
		handleSkuChange(event) {
			if (!event.detail?.item_id) return
            
			this.selectedItemId = event.detail.item_id

			this.updateSplideFocus(this.$refs.splideThumbnail.splide)
			this.updateThumbnailArrowState()
		},

		onMainMoved(event) {
			if (event.index === 0) {
				this.splideArrowState.main.prev = false
			} else if (event.index === event.length - 1) {
				this.splideArrowState.main.next = false
			} else {
				this.splideArrowState.main.next = true
				this.splideArrowState.main.prev = true
			}
		},

		onThumbnailMoved(event) {
			this.updateThumbnailArrowState(event.index)
		},

		async onThumbnailPagePrev() {
			await this.$nextTick()
			
			const { Scroll, Move } = this.$refs.splideThumbnail.splide.Components

			const slide = this.getThumbnailPrevPageSlide()

			const position = Move.toPosition(slide.index)

			if (Move.exceededLimit(true, position)) {
				Scroll.scroll(Move.getLimit(true))
			} else {
				Scroll.scroll(position)
			}
			
			this.updateThumbnailArrowState(slide.index)
		},

		async onThumbnailPageNext() {
			await this.$nextTick()

			const { Scroll, Move } = this.$refs.splideThumbnail.splide.Components
			
			const slide = this.getThumbnailNextPageSlide()

			const position = Move.toPosition(slide.index)

			if (Move.exceededLimit(true, position)) {
				Scroll.scroll(Move.getLimit(true))
			} else {
				Scroll.scroll(position)
			}
			
			this.updateThumbnailArrowState(slide.index)
		},

		updateThumbnailArrowState(index) {
			const { Move, Layout } = this.$refs.splideThumbnail.splide.Components

			this.$nextTick(() => {
				if (!Layout.isOverflow()) {
					this.splideArrowState.thumbnail.next = false
					this.splideArrowState.thumbnail.prev = false
					return
				}
	
				const position = Move.toPosition(index)
				const min = Move.getLimit()
				const max = Move.getLimit(true)
	
				this.splideArrowState.thumbnail.next = position > max
				this.splideArrowState.thumbnail.prev = position < min
			})

		},

		async updateSplideFocus(Splide) {
			await this.$nextTick()

			const { Layout, Controller } = Splide.Components

			Splide.options = {
				focus: Layout.isOverflow() ? 'center' : 0
			}

			Controller.go(0)
		},

		getThumbnailPrevPageSlide() {
			const { splide } = this.$refs.splideThumbnail
			const { Slides, Layout } = splide.Components

			const slides = Slides.get()

			if (splide.options.focus === 'center') {
				const visibleSlides = slides.filter(({ slide }) => slide.classList.contains('is-visible'))
	
				const slide = Slides.getAt(Math.floor(visibleSlides[0].index - visibleSlides.length))
	
				if (slide) {
					return slide
				}
	
				return slides[Math.floor(0 + visibleSlides.length / 2)]
			} else {
				const firstVisibleSlide = Slides.get().find(({ slide }) => slide.classList.contains('is-visible'))

				let prevNonVisibleIndex = Math.floor((firstVisibleSlide.index - Layout.listSize() / Layout.slideSize()) % slides.length)

				const slide = Slides.getAt(prevNonVisibleIndex)

				if (slide) {
					return slide
				}
				
				return slides[0]
			}
		},

		getThumbnailNextPageSlide() {
			const { splide } = this.$refs.splideThumbnail
			const { Slides, Layout } = splide.Components

			const slides = Slides.get()

			if (splide.options.focus === 'center') {
				const visibleSlides = slides.filter(({ slide }) => slide.classList.contains('is-visible'))
	
				const slide = Slides.getAt(Math.floor(visibleSlides.slice().pop().index + visibleSlides.length - 1))
	
				if (slide) {
					return slide
				}
	
				return slides[Math.floor(slides.length - (visibleSlides.length / 2))]
			} else {
				const slide = slides.find(({ slide }) => slide.classList.contains('is-visible'))

				const nextNonVisibleIndex = Math.floor((slide.index + Layout.listSize() / Layout.slideSize()) % slides.length)

				slide = slides[nextNonVisibleIndex]

				if (slide) {
					return slide
				}

				return slides.pop()
			}
		},

		getImageAlt(image) {
			if (image.image_alt) {
				return image.image_alt
			} else if (image.image_overlay_text) {
				return image.image_overlay_text.replace(/(\r\n|\n|\r)/gm, ' ')
			}

			return ''
		}
	}
}
</script>

<style scoped>
#splide-thumbnail {
	margin-top: 1px;
}

.splide:hover .splide__arrow,
.splide:hover .thumbnail-arrow {
	background-color: #210F2080;
	border-color: #ffffff;
}

.splide__arrow, .thumbnail-arrow {
	align-items: center;
	border-radius: 50%;
	display: flex;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 45px;
	width: 45px;
	opacity: 1;
	overflow: hidden;
	background: none;
	border: 1px solid transparent;
	padding: 0;
	z-index: 2; /* Need to stack above .splide-track-gradient */
	transition: all .2s ease-in-out;
}

.splide__arrow svg, .thumbnail-arrow svg {
	fill: none;
	height: 45px;
	width: 45px;
}

.splide__arrow:hover, .thumbnail-arrow:hover {
	background-color: var(--color-primary) !important;
}

.thumbnail-arrows {
	display: none;

	@media (min-width: 768px) {
		display: block;
	}
}

.thumbnail-arrow--prev {
	left: 1em;
}

.thumbnail-arrow--prev svg {
	transform: scale(-1);
}

.thumbnail-arrow--next {
	right: 1em;
}

.splide__arrow[disabled],
.thumbnail-arrow[disabled] {
	display: none;
}

.splide-track-gradient {
	opacity: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	aspect-ratio: 1/1;
	z-index: 1;
	pointer-events: none;
	transition: ease-in-out 100ms;
}

.splide-track-gradient--prev {
	left: 0;
	background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.splide-track-gradient--next {
	right: 0;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.splide-track-gradient.is-active {
	opacity: 1;
}

.splide__slide {
	display: flex;
}

.splide__slide img {
	height: 100%;
	object-fit: cover;
}

.splide__track--nav > .splide__list > .splide__slide {
	border: none;
	outline: 4px solid transparent;
	outline-offset: -4px;
}

.splide__track--nav > .splide__list > .splide__slide:hover,
.splide__track--nav > .splide__list > .splide__slide:focus {
	outline-color: var(--color-primary);
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
	outline-color: var(--color-secondary);
}

.image-overlay-text {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
	color: #ffffff;
	letter-spacing: 0.8px;
	line-height: normal;
	font-size: 15px;
	font-size: 3.5vw;
	padding: 8px 12px;
	margin: 0 auto;
	text-align: center;
	white-space: pre-wrap;

	@media (min-width: 768px) {
		font-size: 30px;
		letter-spacing: 0;
	}
	
	@media (min-width: 1024px) {
		font-size: 17px;
		letter-spacing: 0.8px;
	}
	
	@media (min-width: 1200px) {
		letter-spacing: 1.1px;
		font-size: 20px;
	}
}
</style>