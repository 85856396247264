<template>
	<div class="outer_ship_modal">
		<div
			class="ship_to_modal"
			style="max-width: 480px; padding: 15px"
		>
			<div class="kcs_sku_selector col-xs-12">
				<div class="kcs_sku_selector_flyout">
					<div class="fly_out_header">
						REMOVAL CONFIRMATION
					</div>
					<div class="sku-select-container pdp-sub-headlines">
						<p class="text-center m-t-15">
							Are you sure you'd like to remove this item?
						</p>
						<br>
						<div class="row">
							<div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 p-l-0">
								<button
									id="close-modal"
									class="button text-center cart-remove-item"
									@click="close"
								>
									KEEP&nbsp;ITEM
								</button>
							</div>
							<div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 p-l-0">&nbsp;</div>
							<div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 p-r-0">
								<button
									id="remove-line"
									class="button text-center float-right cart-remove-item"
									@click="removeLine(item)"
								>
									REMOVE
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<button
				type="button"
				class="kcs-btn-close"
				@click="close"
			>
				<i
					class="fa fa-times-circle"
					aria-hidden="true"
				/>
			</button>
		</div>
	</div>
</template>

<script>
import { emitter } from '../../main'
import { useCartStore } from '../../stores/CartStore'

export default {
	name: 'RemoveItemModal',
	props: {
		item: {
			type: Object,
			required: true,
		},
		recipientId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			cartStore: useCartStore(),
		}
	},
	methods: {
		close() {
			emitter.emit('close-remove-line-modal', {
				item: this.item,
				removedLine: false,
			})
		},
		removeLine(item) {
      if (this.cartStore.isAutoDelivery) {
        if(this.cartStore.canRemoveItemFromAutoDelivery(item.clin_id)) {
          this.cartStore.lineAutoDelivery(item.clin_id, '0')
          this.cartStore.removeLineItem(item, this.recipientId)
          emitter.emit('close-remove-line-modal', {
            item: this.item,
            removedLine: true,
          })
        } else {
          alert("By removing this item, your shipment will no longer qualify for Auto Delivery. To remove Auto Delivery enirely from this shipment, please select 'One Time' from the 'Shipment Frequency' select box.")
          this.close()
        }
      } else {
        this.cartStore.removeLineItem(item, this.recipientId)
        emitter.emit('close-remove-line-modal', {
          item: this.item,
          removedLine: true,
        })
      }
		},
	},
}
</script>

<style>
@media (max-width: 485px) {
    .button.cart-remove-item {
        min-width: 130%;
    }
}
</style>