<template>
	<div
		id="mini-cart-flyout"
		class="bg-color-off-white color-brand-charcoal"
	>
		<img
			src="/img/mini_cart_close.png"
			alt="close cart"
			class="mini-cart-close"
		>
		<h3 class="font-alt2">
			Your Cart
		</h3>
		<div
			v-if="last2Items.length > 0"
			class="mini-cart-contents"
		>
			<ul>
				<li
					v-for="(item, key) in last2Items"
					:key="item.clin_id"
				>
					<a :href="`/product/${item.url_id}`">
						<img
							:src="`/dyn-images/100X100/${item.primary_image}`"
							alt=""
						>

						<div class="line-container">
							<div
								class="product-title"
								v-html="item.name"
							/>

							<div
								class="sku-title"
								v-html="item.sku_name"
							/>
						</div>
					</a>
				</li>
			</ul>
			<div class="btn-container first">
				<a role="link" href="/checkout/cart/" class="button button-primary full">
					SECURE CHECKOUT &nbsp;<img src="/img/right-arrow.png" alt="right arrow">
				</a>
			</div>
			<div class="btn-container">
				<a
					role="link"
					href="/checkout/cart/"
					class="button button-secondary full"
				>
					VIEW CART &nbsp;<img
						src="/img/right-arrow.png"
						alt="right arrow"
					>
				</a>
			</div>
		</div>
		<div
			v-else
			class="mini-cart-empty"
		>
			<p>Your shopping cart is empty.</p>
			<p>
				We recommend checking out our <a
					role="link"
					href="/steaks/filet-mignon"
				>best selling steaks</a>.
			</p>
		</div>
	</div>
</template>
<script>
import axios from 'axios'
export default {
	props: {},
	data() {
		return {
			componentKey: 0,
			last2Items: [],
			miniCartOpen: false,
		};
	},
	
	created() {
		// listen for items being added to the cart outside of vue
		window.addEventListener('vueMiniCartUpdate', this.updateCartItems)
	},
	mounted() {
		this.updateCartItems()
	},
	methods: {
		updateCartItems() {
			// Not using the cartStore because it is sometimes out of date
			let _this = this
			axios.get("/api/index/get-mini-cart-items")
				.then((response) => {
					// slicing the array here so we get an accurate account from the back end AND just getting last two items
					this.last2Items = response.data.items.slice(-2)
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
		}
	}
};
</script>