<template>
	<div class="row cm-ship-line cart-shipping-info" :class="{'open-line': isEditing}">
		<!-- BEGIN COLLAPSED VIEW-->
		<div v-show="!isEditing" class="shipping-page col-xs-12 md-p-lr-0">
			<div class="row ship-row">
				<div class="flex-left">
					<div class="selected-recipient h-uppercase">
						SHIP TO
						<span v-if="(!editableRecipient.fname && !editableRecipient.lname) || editableRecipient.is_myself == '1'">Myself</span>
						<span v-if="(editableRecipient.fname || editableRecipient.lname) && editableRecipient.is_myself == '0'">
							{{ editableRecipient.fname }} {{ editableRecipient.lname }}
						</span>
					</div>
				</div>
			</div>
			<div class="clear">
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-l-0 text-right text-left-sm">
					<label class="h-uppercase h-size-h6">Address</label>
				</div>
				<div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-l-0">
					<div v-if="editableRecipient.fname || editableRecipient.lname">
						{{ editableRecipient.fname }} {{ editableRecipient.lname }}
					</div>
					<div v-if="editableRecipient.cart_type != 'ECRT' && editableRecipient.address_1">
						{{ editableRecipient.address_1 }}
					</div>
					<div v-if="editableRecipient.cart_type != 'ECRT' && editableRecipient.address_2">
						{{ editableRecipient.address_2 }}
					</div>
					<div v-if="editableRecipient.cart_type == 'ECRT' && editableRecipient.email">
						{{ editableRecipient.email }}
					</div>
					<div v-if="editableRecipient.cart_type != 'ECRT' && (editableRecipient.city || editableRecipient.state || editableRecipient.postal_code)">
						<span v-if="editableRecipient.city">
							{{ editableRecipient.city }},
						</span> 
						{{ editableRecipient.state }} {{ editableRecipient.postal_code }}
					</div>
					<a
						class="color-tertiary"
						style="cursor: pointer; font-size: 12px"
						@click="$emit('edit')"
					>
						<span style="font-size: 12px" class="fa fa-edit" />Change
					</a>
				</div>
			</div>
			<br class="clear">
			<div class="m-t-10 ">
				<div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-l-0 text-right text-left-sm">
					<label class="h-uppercase h-size-h6">Delivery</label>
				</div>
				<div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 p-l-0">
					<div v-if="editableRecipient.shipping.ship_id && editableRecipient.shipping.ship_id == '5'">
						<span>In-store Pickup:</span>
					</div>
					<div v-if="editableRecipient.shipping.ship_id && editableRecipient.shipping.ship_id == 24">
						<span>
							<i class="fa fa-envelope" style="font-size: 18px" aria-hidden="true" /><span class="bold" style="margin-left:10px">
								{{ generateDeliveryMessage( editableRecipient, false, true ) }}
							</span>
						</span>
						<ShippingCalendar
							:recipient="editableRecipient"
							mode="e-delivery"
						/>
					</div>
					<span v-else-if="editableRecipient.shipping.ship_id != '5' && editableRecipient.shipping.ship_id != '24'">
						<span v-if="!showGuaranteedDeliveryMessage">
							Arrives <span class="bold">{{ generateDeliveryMessage( editableRecipient ) }}</span>
						</span>
					</span>

					<div
						v-if="editableRecipient.xmasMessage"
						style="color: #D4482C; padding-top: 10px; padding-bottom: 5px; font-size: 12px"
					>
						<span class="pull-left" aria-hidden="true">
							<img src="/images/christmas-hat.png" style="width:25px; padding-right: 5px">
						</span>
						<span class="">Your shipment qualifies for a free upgrade to premium delivery to arrive by Christmas</span>
					</div>

					<span v-if="editableRecipient.shippingMode && editableRecipient.shippingMode != 'nonPerishable'">
						<ShippingCalendar
							:recipient="editableRecipient"
						/>
					</span>
				</div>
			</div>
			<br class="clear">
			<div class="m-t-10 ">
				<div class="col-lg-2 col-md-2 col-sm col-xs p-l-0 text-right text-left-sm" style="display: inline-block">
					<label class="h-uppercase h-size-h6">GIFT MESSAGE</label>
				</div>
				<div class="col-lg-8 col-md-8 col-sm col-xs p-l-0" style="display: inline-block">
					<a v-if="editableRecipient.gift_message || editableRecipient.announce_it_message" class="modal-link color-tertiary anchor-primary" style="font-size: 12px; cursor: pointer;" @click="$emit('edit')">
						<i class="fa fa-edit" style="font-size: 12px;" />Change
					</a>

					<a v-if="!editableRecipient.gift_message && !editableRecipient.announce_it_message" class="modal-link color-primary anchor-primary" style="font-size: 12px; cursor: pointer;" @click="$emit('edit')">
						<i class="fa fa-edit" style="font-size: 12px;" />Add a Gift Message
					</a>
				</div>

				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 md-p-l-0 xs-p-l-30">
					<div v-if="editableRecipient.gift_message || editableRecipient.announce_it_message" :class="{'m-b-10': editableRecipient.announce_it_message}" class="">
						<div class="bold">In-box Gift Message</div>
						{{ editableRecipient.gift_message }}
					</div>
					<div v-if="editableRecipient.announce_it_message">
						<div class="bold">Gift Announcement Email</div>
						{{ editableRecipient.announce_it_message }}
						<div v-if="!editableRecipient.announce_it_time" class="clearfix p-t-10">
							<i class="fa fa-envelope" /> Send Immediately
						</div>
						<div v-if="editableRecipient.announce_it_time" class="clearfix p-t-10">
							<i class="fa fa-envelope" /> Send {{ generateDeliveryMessage(editableRecipient, true) }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- END COLLAPSED VIEW -->

		<!-- BEGIN EXPANDED SHIPPING FORM -->
		<div v-show="isEditing" class="sg-form recipient-open-for-edit">
			<div class="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 shipto">
				<div class="col-xs-3 col-sm-3 col-md-2 col-lg-2 text-right text-left-sm p-lr-5 sm-p-lr-0">
					<label class="align-self-c">
						<span class="h-uppercase">SHIP TO</span>
					</label>
				</div>
				<div class="col-xs-9 col-sm-9 col-md-6 col-lg-6 p-lr-5 sm-p-lr-0">
					<ShipToSelector 
						:crec-id="editableRecipient.crec_id"
						:addr-id="editableRecipient.addr_id"
					/>
				</div>
			</div>

			<div class="form-bg ng-show-toggle-slidedown-only" :class="{'slide-down' : editableRecipient.doSlideOpen, 'display-none' : editableRecipient.inProgressScrollingTo}">
				<div v-if="showShippingNotComplete" class="shipping-not-complete">
					Before continuing you must confirm shipping details. Click the "Done" button to confirm each pending shipment.
				</div>

				<div v-if="editableRecipient.cart_type != 'ECRT'">
					<ShippingEditForm
						:validation-errors="validationErrors"
						:is-editing="isEditing"
						:crec-id="recipient.crec_id"
						:recipient="editableRecipient"
						@done="onDone"
					>
						<template #shipping-calendar>
							<ShippingCalendar
								ref="shippingCalendar"
								:recipient="editableRecipient"
								:is-recip-edit-form="true"
							/>
						</template>
					</ShippingEditForm>
				</div>
				<div v-if="isEcrt">
					<ShippingEditFormEcrt
						:validation-errors="validationErrors"
						:crec-id="recipient.crec_id"
						:recipient="editableRecipient"
						@done="onDone"
					>
						<template #shipping-calendar>
							<ShippingCalendar
								ref="ecrtShippingCalendar"
								:recipient="editableRecipient"
								:is-recip-edit-form="true"
								mode="e-delivery"
							/>
						</template>
					</ShippingEditFormEcrt>
				</div>
			</div>
		</div>
		<!-- END EXPANDED SHIPPING FORM-->
		<ItemsInShipment :items="editableRecipient.items" :recipient-guid="editableRecipient.recipient_guid" :recipient-id="editableRecipient.crec_id" :free-items="cart.recalc_detail.free_gift_fixed" />

		<div v-if="showQASModal && isEditing" class="row qas-results">
			<div class="col-xs-12">
				<QASPrompts
					:recip="editableRecipient"
					:qas-data="qasData"
					@complete="handleQasComplete"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import checkoutHelpers from '~/mixins/checkout-global-functions.js'
import ShippingEditForm from '~/components/checkout/shipping/ShippingEditForm.vue'
import ShippingEditFormEcrt from '~/components/checkout/shipping/ShippingEditFormEcrt.vue'
import ShipToSelector from '~/components/global/ship-to-selector.vue'
import ItemsInShipment from '~/components/checkout/shipping/ItemsInShipment.vue'
import ShippingCalendar from '~/components/global/global-shipping-calendar.vue'
import { useCartStore } from '~/stores/CartStore'
import { useUserStore } from '../../../stores/UserStore'
import { reactive, watch } from 'vue'
import QASPrompts from '../../global/qas-validation-prompts.vue'
import axios from 'axios'

const defaultValidationErrors = () => ({
	fname: {
		error: false,
		message: '',
	},
	lname: {
		error: false,
		message: '',
	},
	company: {
		error: false,
		message: '',
	},
	address_1: {
		error: false,
		message: '',
	},
	address_2: {
		error: false,
		message: '',
	},
	city: {
		error: false,
		message: '',
	},
	state: {
		error: false,
		message: '',
	},
	postal_code: {
		error: false,
		message: '',
	},
	email: {
		error: false,
		message: ''
	},
	phone: {
		error: false,
		message: ''
	},
	ship_date: {
		error: false,
		message: ''
	},
	gift_message: {
		error: false,
		message: ''
	},
	announce_it_message: {
		error: false,
		message: ''
	}
})

export default {
	components: {
		ShippingEditForm,
		ShippingEditFormEcrt,
		ShipToSelector,
		ShippingCalendar,
		ItemsInShipment,
		QASPrompts,
	},

	mixins: [checkoutHelpers],
    
	props: {
		recipient: {
			type: Object,
			required: true,
		},

		isEditing: {
			type: Boolean,
			default: false,
		}
	},

	emits: ['edit', 'done'],

	setup(props) {
		const userStore = useUserStore()
		const editableRecipient = reactive(Object.assign({}, props.recipient))

		if (!userStore.getIsLoggedIn && userStore.guestEmail && parseInt(props.recipient.is_myself) === 1 && !props.recipient.email) {
			editableRecipient.email = userStore.guestEmail
		}

		watch(
			() => props.recipient.shipping,
			(value) => {
				editableRecipient.shipping = {
					...editableRecipient.shipping,
					...value
				}
			}
		)

		// Prevents incoming recipient changes from overwriting local address changes
		watch(
			() => props.recipient,
			({
				address_1,
				address_2,
				city,
				state,
				company,
				country,
				postal_code,
				phone,
				email,
				fname,
				lname,
				...rest
			}) => {
				Object.assign(editableRecipient, rest)
			},
		)

		// If ship-to changes, copy all non-address related settings
		watch(
			() => props.recipient.addr_id,
			(newValue, oldValue) => {
				const {
					announce_it_message,
					gift_message,
					is_a_gift,
					announce_it_email,
					announce_it_time,
					announce_it_send_flag,
					timeZone,
					shipping,
					...otherRecipProps
				} = props.recipient

				if (newValue !== oldValue) {
					Object.assign(editableRecipient, otherRecipProps)
				}
			}
		)

		return {
			editableRecipient,
		}
	},

	data() {
		return {
			processing: false,
			showQASModal: false,
			showShippingNotComplete: false,
			validationErrors: defaultValidationErrors(),
			qasData: null,
			qasComparisonAddress: {}
		}
	},

	computed: {
		...mapState(useCartStore, {
			cart: 'fullCart',
		}),

		isValidated() {
			return Object.entries(this.validationErrors).map(([key, value]) => {
				if (typeof value === 'boolean') {
					return value
				} else if (typeof value === 'object') {
					return value.error
				}
			}).every((value) => !value)
		},

		isEcrt() {
			return this.editableRecipient.cart_type === 'ECRT'
		},
		showGuaranteedDeliveryMessage() {
			return !!this.editableRecipient.delivery_guarantee_data && this.editableRecipient.delivery_guarantee_data.message
		},
	},

	mounted() {
		// Listen for events triggered by recipientChangeShipDate
		this.$emitter.on('cart-recipient-shipping-updated', (evt) => {
			this.validationErrors.ship_date.error = false
			this.validationErrors.ship_date.message = ''
		})

		this.prepRecipForApiCalls()
		this.setQasComparisonAddress()

		// Validates recipient if errors are saved to cartStore from billing page
		if (useCartStore().validationErrors?.recipient) {
			if (Object.keys(useCartStore().validationErrors.recipient).includes(this.recipient.crec_id)) {
				this.validateRecipient()
			}
		}
	},

	methods: {
		prepRecipForApiCalls() {
			this.editableRecipient.clin_id = this.recipient.items[0].clin_id
			this.editableRecipient.sku_id = this.recipient.items[0].sku_id
			this.editableRecipient.addr_id = this.editableRecipient.addr_id === '' ? null : this.editableRecipient.addr_id
			this.editableRecipient.address = {
				addr_id: this.editableRecipient.addr_id === '' ? null : this.editableRecipient.addr_id,
				address_1: this.editableRecipient.address_1,
				address_2: this.editableRecipient.address_2,
				city: this.editableRecipient.city,
				company: this.editableRecipient.company,
				email: this.editableRecipient.email,
				fname: this.editableRecipient.fname,
				is_myself: this.editableRecipient.is_myself,
				lname: this.editableRecipient.lname,
				phone: this.editableRecipient.phone,
				postal_code: this.editableRecipient.postal_code,
				state: this.editableRecipient.state
			}
		},

		setQasComparisonAddress() {
			this.qasComparisonAddress = {
				addr_id: this.editableRecipient.addr_id === '' ? null : this.editableRecipient.addr_id,
				address_1: this.editableRecipient.address_1,
				address_2: this.editableRecipient.address_2,
				city: this.editableRecipient.city,
				company: this.editableRecipient.company,
				email: this.editableRecipient.email,
				fname: this.editableRecipient.fname,
				is_myself: this.editableRecipient.is_myself,
				lname: this.editableRecipient.lname,
				phone: this.editableRecipient.phone,
				postal_code: this.editableRecipient.postal_code,
				state: this.editableRecipient.state
			}
		},

		validateRecipient(options = {}) {
			const validationType = this.isEcrt ? 'ecrt' : 'shipping'

			let testResults = this.simpleAddressVaildate(this.editableRecipient, validationType)
			
			if(!testResults.formIsValid) {
				if (!this.editableRecipient.email && this.editableRecipient.announce_it_send_flag) {
					this.validationErrors.email = testResults.validationErrors.email
				}

				if (options.showErrors !== false) {
					this.validationErrors = testResults.validationErrors
				}

				if (options.scrollToError !== false) {
					this.$nextTick(() => {
						if($('.has-error:visible:first').length && $('.has-error:visible:first').offset()) {
							$('html, body').animate({
								scrollTop: $('.has-error:visible:first').offset().top - 75
							}, 500)
						}
					})
				}

				return false
			}

			return true
		},

		saveLineInformation(e) {
			if(e) {
				e.preventDefault()
			}

			// Hide errors if there are any
			this.clearValidationErrors();

			// Set cust_id if set in cart
			if (useCartStore().cart.cust_id) {
				this.editableRecipient.cust_id = useCartStore().cart.cust_id
			}

			if (!this.editableRecipient.is_a_gift || this.editableRecipient.is_a_gift === '0') {
				this.editableRecipient.announce_it_email = ''
				this.editableRecipient.announce_it_message = ''
				this.editableRecipient.announce_it_send_flag = false
				this.editableRecipient.announce_it_time = ''
				this.editableRecipient.gift_message = ''
			}

			let doQAS = false

			if (!this.isEcrt) {
				// Now figure out what has changed
				// First - compare finger print
				// Also - if res-com is still a "V" the address has not been validated
				doQAS = (
					(this.$utilities.generateAddressFingerprint(this.editableRecipient) !== this.$utilities.generateAddressFingerprint(this.qasComparisonAddress))
					||
					this.recipient.res_com == 'V'
				)
			}


			// The address has been changed since last fingerprinted.
			// Now send to the server for full validation
			return axios.post('/api/checkout/validate-recipient', {recip: this.editableRecipient, do_qas: doQAS, apply_gift_message_to_all: this.applyGiftMessageToAll})
				.then((response) => {

					// handle success
					// process the states data from the server
					// After address validation - we'll save shipping
					if(typeof(response.data.new_addr_id) != 'undefined' && response.data.new_addr_id) {
						this.editableRecipient.addr_id = response.data.new_addr_id
					}

					if(!Array.isArray(response.data.validation_errors) || response.data.validation_errors.length) {
						this.handleValidationErrors(response.data.validation_errors)

						return false
					} else {
						if(response.data.gift_message_copied_to_all) {
							useCartStore().applyGiftMessageToAll(this.editableRecipient)
						}

						if(response.data.recip_refresh) {
							// The recipient was changed by the server...we need to update
							useCartStore().updateRecipient(this.recipient.crec_id, response.data.recip_refresh)

							const { recip_refresh } = response.data

							this.editableRecipient.address_1 = recip_refresh.address_1
							this.editableRecipient.address_2 = recip_refresh.address_2
							this.editableRecipient.company = recip_refresh.company
							this.editableRecipient.city = recip_refresh.city
							this.editableRecipient.state = recip_refresh.state
							this.editableRecipient.country = recip_refresh.country
							this.editableRecipient.postal_code = recip_refresh.postal_code
						} else {
							useCartStore().updateRecipient(this.recipient.crec_id, this.editableRecipient)
						}

						if (!this.editableRecipient.addr_id) {
							useCartStore().updateSavedAddress(this.editableRecipient.addr_id, this.editableRecipient)
						}

						if (parseInt(this.editableRecipient.is_myself) === 1) {
							useUserStore().loadCustomer()
						}

						this.setQasComparisonAddress()

						return true
					}
				})
				.catch((error) => {
					// handle error
					if(error.response?.data?.error && error.response?.data?.error == 'cart_not_found') {
						emitter.emit('cart-not-found')
					}
				})
		},

		clearValidationErrors() {
			this.validationErrors = defaultValidationErrors()
		},

		handleQasComplete(event) {
			if(event.crecId == this.recipient.crec_id && !this.processing) {
				this.processing = true

				switch(event.action) {
				case 'edit':
					this.processing = false
					break
				case 'save-selected':
					this.validationChoiceUsePicklistSelection(event.picklistKey)
					this.setQasComparisonAddress()
					break
				case 'save-suggested':
					this.validationChoiceUseSuggested()
					this.setQasComparisonAddress()
					break
				case 'use-my-address':
					this.validationChoiceUseMyAddress()
					this.setQasComparisonAddress()
					break
				default:
					this.processing = false
				}
			}

			this.showQASModal = false
		},

		handleValidationErrors(errors) {
			Object.entries(errors).forEach(([key, [error]]) => {
				switch (key) {
				case 'ship_date': {
					this.validationErrors.ship_date.error = true

					if (this.isEcrt) {
						this.$refs.ecrtShippingCalendar.openModal()
					} else {
						this.$refs.shippingCalendar?.openModal('chooser')
					}
					break
				}
				case 'address_1': {
					// ADDRESS HAS VALIDATION ISSUES AND REQUIRES CUSTOMER ACTION
					if (typeof error[1] != 'undefined' && error[1] == 'ups_addr_suggest_warning') {
						this.qasData = error[3]
						this.showQASModal = true
					}
					break
				}
				case 'company':
				case 'gift_message':
				case 'announce_it_message':
				default: {
					if (this.validationErrors[key]) {
						this.validationErrors[key].error = true
						this.validationErrors[key].message = error[0]
					}
					break
				}
				}
			})
		},

		validationChoiceUseSuggested() {
			this.editableRecipient.address_1	= this.qasData.suggested_address.address_1
			this.editableRecipient.address_2	= this.qasData.suggested_address.address_2
			this.editableRecipient.city			= this.qasData.suggested_address.city
			this.editableRecipient.postal_code	= this.qasData.suggested_address.postal_code
			this.editableRecipient.state		= this.qasData.suggested_address.state
			// this.editableRecipient.res_com		= this.qasData.addr_type

			if(this.qasData.suggested_address.company != 'undefined') {
				this.editableRecipient.company = this.qasData.suggested_address.company;
			}

			this.saveValidationChoice('suggested')
		},

		validationChoiceUsePicklistSelection(picklistKey) {
			let selectedAddress = this.qasData.picklist[picklistKey].address_parts

			this.editableRecipient.address_1	= selectedAddress.address_1;
			this.editableRecipient.address_2	= selectedAddress.address_2;
			this.editableRecipient.city			= selectedAddress.city;
			this.editableRecipient.postal_code	= selectedAddress.postal_code;
			this.editableRecipient.state		= selectedAddress.state;
			// this.editableRecipient.res_com		= this.qasData.addr_type

			if(selectedAddress.company != 'undefined') {
				this.editableRecipient.company = selectedAddress.company;
			}
			this.saveValidationChoice('selected')
		},

		validationChoiceUseMyAddress() {
			this.editableRecipient.res_com = 'U'
			this.saveValidationChoice('mine')
		},

		saveValidationChoice(userChoiceType) {
			// Notify the server of Customer's validation choice

			axios.post('/api/checkout/record-qas-validation-selection', {recip: this.editableRecipient, user_choice_type: userChoiceType})
				.then(async (response) => {
					await useCartStore().updateRecipient(this.recipient.crec_id, this.editableRecipient)

					setTimeout(() => this.$emit('done', this.editableRecipient), 500)
					// emitter.emit('close-recipient-edit-form', {crec_id: this.crecId})
				})
				.catch((error) => {
					this.$sentry.captureException(error)
				})
				.finally(() => {
					this.processing = false;
				});
		},

		onDone() {
			if (this.validateRecipient()) {
				this.$emit('done', this.editableRecipient)
			}

		}
	}

}
</script>