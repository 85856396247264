<template>
	<div class="col-xs-12 md-p-lr-0 xs-p-b-20 m-t-20">
		<label class="h-uppercase">Items in Shipment</label>

		<AutoDelivery element="shipping-items" :recipient-id="recipientId"/>

		<div v-for="item in parentItems" class="row m-t-20">
			<div class=" xs-p-lr-0" :class="item.is_addon ? 'col-xs-11' : 'col-xs-12'">
				<div v-if="item.is_child_line" class="col-md-1 pull-left">&nbsp;</div>
				<div class="col-xs-3 xs-p-r-0 col-md-100">
					<img :src="'/dyn-images/cart/'+item.product_image" class="img-responsive" :alt="cleanHtml(item.product_name)">
				</div>
				<div class="col-xs-7 col-md-7 mc-items">
					<div>
						<b class="h-uppercase color-theme-dark font-alt2">{{ cleanHtml(item.product_name) }}</b>
					</div>
					<div>
						<span v-if="item.num_of_siblings > 1" class="color-theme-dark">{{ cleanHtml(item.sku_name) }}</span>
					</div>
					<div>
						<span v-if="item.deliveryByXmas == false" class="color-error">This item may not arrive before Christmas. <a class="color-error xmas-link" href="https://www.kansascitysteaks.com/guaranteed-delivery">Shop Guaranteed Items.</a></span>
						<span v-if="item.deliveryByXmas == true" class="color-success">This item is Guaranteed to arrive before Christmas</span>
					</div>
				</div>
			</div>
		</div>
		<div v-for="item in addonItems" class="row m-t-20">
			<div class="xs-p-lr-0 col-xs-11">
				<div class="col-md-1 pull-left">&nbsp;</div>
				<div class="col-xs-3 xs-p-r-0 col-md-100">
					<img :src="'/dyn-images/cart/'+item.product_image" class="img-responsive" :alt="cleanHtml(item.product_name)">
				</div>
				<div class="col-xs-7 col-md-7 mc-items">
					<div>
						<b class="h-uppercase color-theme-dark font-alt2">{{ cleanHtml(item.product_name) }}</b>
					</div>
				</div>
			</div>
		</div>
		<div v-for="(item, index) in currentFreeItems" :key="index" class="row m-t-20">
			<div class="xs-p-lr-0 col-xs-11">
				<div class="col-md-1 pull-left">&nbsp;</div>
				<div class="col-xs-3 xs-p-r-0 col-md-100">
					<img :src="item.product_image" class="img-responsive" :alt="cleanHtml(item.sku_name)">
				</div>
				<div class="col-xs-7 col-md-7 mc-items">
					<div>
						<b class="h-uppercase color-theme-dark font-alt2">{{ cleanHtml(item.sku_name) }}</b>
					</div>
					<div>
						<span style="font-weight:bold;">Free Item</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AutoDelivery from '../../global/auto-delivery.vue'	
export default {
	components: {AutoDelivery},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		recipientGuid: {
			type: String,
			required: true,
		},
		recipientId: {
			type: String,
			required: true,
		},
		freeItems: {
			type: Object,
			default: () => ({}),
		}
	},
	data() {
		return {};
	},
	computed: {
		parentItems() {
			return this.items.filter(i => i.is_addon === false)
		},
		addonItems() {
			return this.items.filter(i => i.is_addon === true)
		},
		currentFreeItems() {
			return this.freeItems[this.recipientGuid]
		}
	},
	created(){
		// console.log(this.recipientId)
	},
	mounted() {

	},
	methods: {
		cleanHtml(str) {
			var stripedHtml = str.replace(/<[^>]+>/g, "");
			var fixed_str = stripedHtml.replace("&nbsp;", " ");
			fixed_str = fixed_str.replace(/&.*;/, "");
			return fixed_str;
		}
	}
}
</script>

<style>
	
</style>
