import dayjs from "dayjs";
import utcPlugin from 'dayjs/plugin/utc'
import tzPlugin from 'dayjs/plugin/timezone'
import customParsePlugin from 'dayjs/plugin/customParseFormat'

dayjs.extend(utcPlugin)
dayjs.extend(tzPlugin)
dayjs.extend(customParsePlugin)

export {
    dayjs
}

export default {
    install(app, options) {
        app.config.globalProperties.$dayjs = dayjs
    }
}