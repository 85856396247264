<template>
	<div>
		<a
			class="ng-binding underline-text military-discount-link" 
			style="margin: 0px 5px 0px 0px;" 
			data-toggle="modal" 
			data-target="#communitySelectModal"
		>
			Discounts for Military,
			<br>
			First Responders, and Nurses
		</a>

		<!-- default modal -->
		<div
			id="communitySelectModal"
			class="modal fade"
			tabindex="-1"
			role="dialog"
			aria-labelledby="myModalLabel"
		>
			<div
				class="modal-dialog discount-modal"
				role="document"
				style="max-width: 600px;"
			>
				<div
					class="modal-content"
					style="text-align:center"
				>
					<div class="modal-header">
						<button
							type="button"
							class="close close-button"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<h2 class="m-t-40">
							Applying your offer is Fast and Easy
						</h2>
					</div>
					<div class="modal-body">
						<div class="">
							See your offer and begin the short verification process by selecting the group that best describes you below.
						</div>

						<div
							class="community-list m-t-20"
							style="display: flex; justify-content: center;"
						>
							<ul style="list-style-type: none; text-align: left;">
								<li>
									<label
										for="radio0"
										class="css-label"
										style="cursor: pointer"
									><input
										id="radio0"
										v-model="community"
										type="radio"
										value="military"
										style="cursor: pointer"
									> Military</label>
								</li>
								<li>
									<label
										for="radio1"
										class="css-label"
										style="cursor: pointer"
									><input
										id="radio1"
										v-model="community"
										type="radio"
										value="responder"
										style="cursor: pointer"
									> First Responder</label>
								</li>
								<li>
									<label
										for="radio2"
										class="css-label"
										style="cursor: pointer"
									><input
										id="radio2"
										v-model="community"
										type="radio"
										value="nurse"
										style="cursor: pointer"
									> Nurse</label>
								</li>
							</ul>
						</div>

						<div>
							<p class="b_cart-specials__item-price-save">
								&nbsp;<span v-show="communityError">
									Please make a selection above.
								</span>&nbsp;
							</p>
						</div>
                        
						<div class="m-t-20">
							<button
								class="button checkout-btn"
								@click="showDiscountModal(community)"
							>
								Continue
							</button>
						</div>
						<br>
					</div>
				</div>
			</div>
		</div>
		<!-- military modal -->
		<div
			id="militaryModal"
			class="modal fade"
			tabindex="-1"
			role="dialog"
			aria-labelledby="militaryModalLabel"
		>
			<div
				class="modal-dialog discount-modal"
				role="document"
			>
				<div class="modal-content discount-modal-content">
					<div class="modal-header banner-header">
						<button
							type="button"
							class="close close-button"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<img
							class="flag-banner"
							src="/img/military-discount-header-image.jpg"
							alt=""
						>
					</div>
					<div
						class="military-modal-body"
						style="text-align:center"
					>
						<h2 class="section-heading">
							MILITARY DISCOUNT
						</h2>
						<p>The Kansas City Steak Company is proud to offer <span class="bold">20% OFF</span> to military personnel.</p>
						<div style="display: flex; justify-content: center;">
							<ul style="list-style-type: none; text-align: left;">
								<li><span class="green-checkmark">✔</span> Active Military</li>
								<li><span class="green-checkmark">✔</span> Veterans</li>
								<li><span class="green-checkmark">✔</span> Retirees</li>
								<li><span class="green-checkmark">✔</span> Military Spouses</li>
								<li><span class="green-checkmark">✔</span> Dependents</li>
							</ul>
						</div>
						<p class="bold">
							Click the button below to apply your offer:
						</p>
						<div>
							<a
								href="javascript:void(0);"
								@click="discountForward('military');"
							><img
								class="id-me-button"
								src="/img/id.me-button.png"
								alt=""
							></a>
						</div>
						<p>We thank you for your service.</p>
					</div>
				</div>
			</div>
		</div>
		<!-- first responder modal -->
		<div
			id="firstResponderModal"
			class="modal fade"
			tabindex="-1"
			role="dialog"
			aria-labelledby="firstResponderModalLabel"
		>
			<div
				class="modal-dialog discount-modal"
				role="document"
			>
				<div class="modal-content discount-modal-content">
					<div class="modal-header banner-header">
						<button
							type="button"
							class="close close-button"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<img
							class="flag-banner"
							src="/img/military-discount-header-image.jpg"
							alt=""
						>
					</div>
					<div
						class="military-modal-body"
						style="text-align:center"
					>
						<h2 class="section-heading">
							FIRST RESPONDER DISCOUNT
						</h2>
						<p>The Kansas City Steak Company is proud to offer <span class="bold">20% OFF</span> to qualifying first responders.</p>
						<div style="display: flex; justify-content: center;">
							<ul style="list-style-type: none; text-align: left;">
								<li><span class="green-checkmark">✔</span> Certified EMT / Paramedic</li>
								<li><span class="green-checkmark">✔</span> State Certified Firefighter</li>
								<li><span class="green-checkmark">✔</span> Law Enforcement Officer</li>
								<li><span class="green-checkmark">✔</span> 911 Dispatcher</li>
							</ul>
						</div>
						<p class="bold">
							Click the button below to apply your offer:
						</p>
						<div>
							<a
								href="javascript:void(0);"
								@click="discountForward('responder');"
							><img
								class="id-me-button"
								src="/img/id.me-button.png"
								alt=""
							></a>
						</div>
						<p>We thank you for your contributions.</p>
					</div>
				</div>
			</div>
		</div>
		<!-- nurse modal -->
		<div
			id="nurseModal"
			class="modal fade"
			tabindex="-1"
			role="dialog"
			aria-labelledby="nurseModalLabel"
		>
			<div
				class="modal-dialog discount-modal"
				role="document"
			>
				<div class="modal-content discount-modal-content">
					<div class="modal-header banner-header">
						<button
							type="button"
							class="close close-button"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<img
							class="flag-banner"
							src="/img/nurse-discount-header-image.jpg"
							alt=""
						>
					</div>
					<div
						class="military-modal-body"
						style="text-align:center"
					>
						<h2 class="section-heading">
							NURSE DISCOUNT
						</h2>
						<p>The Kansas City Steak Company is proud to offer <span class="bold">20% OFF</span> to qualifying nurses.</p>
						<div style="display: flex; justify-content: center;">
							<ul style="list-style-type: none; text-align: left;">
								<li><span class="green-checkmark">✔</span> Advanced practice registered nurse (APRN)</li>
								<li><span class="green-checkmark">✔</span> Certified nursing assistant (CNA)</li>
								<li><span class="green-checkmark">✔</span> Licensed practical nurse (LPN)</li>
								<li><span class="green-checkmark">✔</span> Public health nurse</li>
								<li><span class="green-checkmark">✔</span> Registered nurse (RN)</li>
							</ul>
						</div>
						<p class="bold">
							Click the button below to apply your offer:
						</p>
						<div>
							<a
								href="javascript:void(0);"
								@click="discountForward('nurse');"
							><img
								class="id-me-button"
								src="/img/id.me-button.png"
								alt=""
							></a>
						</div>
						<p>We thank you for your contributions.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IdMe',
	props: {},
	data() {
		return {
			community: '',
			communityError: false,
			communitySelectModal: true,
			militaryModal: false,
			firstResponderModal: false,
			nurseModal: false
		}
	},
	mounted() {},
	methods: {
		showDiscountModal(selected) {
			if(!selected) {
				this.communityError = true
				return
			}
			this.communityError = false
			// figure out which modal to open next
			if(selected === 'military') {
				// TODO: change this from jquery to vue native
				$("#communitySelectModal").modal('hide');
				$("#militaryModal").modal('show');
			}
			if(selected === 'responder') {
				$("#communitySelectModal").modal('hide');
				$("#firstResponderModal").modal('show');
			}
			if(selected === 'nurse') {
				$("#communitySelectModal").modal('hide');
				$("#nurseModal").modal('show');
			}
		},
		discountForward(type) {
			let sizeString
			// TODO: change this from jquery to vue native
			if ($(window).width() >= 750) {
				sizeString = "toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=750,height=1050,left=150,top=50";
			} else {
				sizeString = "toolbar=0,status=0";
			}
			window.open("/idme/forward?community=" + type, "MilitaryDiscount", sizeString);
			$("#militaryModal").modal('hide');
			$("#firstResponderModal").modal('hide');
			$("#nurseModal").modal('hide');
		}
	},
}
</script>