import { useCartStore } from '../stores/CartStore'
import { emitter } from '../main'
import { watch } from 'vue'

export default {
	props: {
		cart: {
			type: Object,
			required: true
		},
		recipient: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			showFreeItem: true
		}
	},
	mounted() {
		emitter.on('cart-updated', (evt) => {
			this.checkRecipientValue()
		})
		emitter.on('cart-recipient-address-updated', (evt) => {
			this.checkRecipientValue()
		})
		emitter.on('updated-cart-count', (evt) => {
			this.checkRecipientValue()
		})
		emitter.on('gift-card-removed', (evt) => {
			this.checkRecipientValue()
		})

		watch(
			() => this.recipient?.items,
			() => this.checkRecipientValue()
		)
	},
	methods: {
		checkRecipientValue() {
			// value needed for free item
			let valueNeeded = this.cart.recalc_detail.discount_detail.engine_data?.attributes.limit_min_subtotal.value
			if(!valueNeeded) {
				return false
			}

			let totalValue = 0

			this.recipient?.items.map(item => {
				totalValue += (item.price * item.qty)
			})

			if (totalValue >= valueNeeded) {
				this.showFreeItem = true
			} else {
				this.showFreeItem = false
			}
		}
	},
}