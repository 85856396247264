<template>
	<div
		v-if="cart.recalc_detail.free_gift_fixed[recipient.recipient_guid]"
		class="free-gift-items clear"
		style="padding: 15px 15px 0 15px"
	>
		<div
			v-for="free_item in cart.recalc_detail.free_gift_fixed[recipient.recipient_guid]"
			:key="free_item.sku_id"
			class="row"
		>
			<div class="col-xs-3">
				<img :src="free_item.product_image" class="mc-img" :alt="free_item.sku_name">
			</div>
			<div class="col-xs-9 mc-items">
				<div class="row">
					<span class="name bold">{{ free_item.sku_name }}</span><br>

					<p>Item: #{{ free_item.item_id }}</p>
					<p>
						<span class="mc-label transform-uppercase pull-left">Price:</span>
						<span class="pull-left strike m-l-5">
							{{ currency(free_item.display_totals.strike_price) }}
						</span>
						<span class="pull-left bold m-l-5">FREE</span>
						<br class="clear">
					</p>
					<p
						v-if="free_item.showQtyMessage"
						class="qty-message"
						style="margin-top: -10px"
					>
						Sorry, we currently have {{ free_item.showQtyUnits }} left.
					</p>
					<p>
						<span class="mc-label transform-uppercase pull-left">Subtotal:</span>
						<span class="pull-left strike m-l-5">
							{{ currency(free_item.display_totals.strike_price) }}
						</span>
						<span class="pull-left bold m-l-5">FREE</span>
						<br class="clear">
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import currencyFormatterMixin from '../../../mixins/currency-formatter-mixin'
import freeItemMixin from '../../../mixins/free-item-mixin'

export default {
	name: 'FreeItemMobile',
	mixins: [
		currencyFormatterMixin,
		freeItemMixin,
	],
	props: {},
	data() {},
	mounted() {},
	methods: {},
}
</script>